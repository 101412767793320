import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
// style
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Typography,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox
} from '@material-ui/core';

import SpaceRow from '../commons/SpaceRow';

import './app.css';
import { AuthContext } from '../../App';

import { getEgsMBusinessTypeList } from '../../lib/api/egs_m_business_type';
import { getEgsMBusinessCategoryList } from '../../lib/api/egs_m_business_category';

//import { getTraderAdmJwnet, getTraderAdmUser } from '../../lib/api/trader_adm';
import { getEntryLinkOpe} from '../../lib/api/entry_link';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '200ch',  //'150ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 2,
    borderColor: "black",
    borderStyle: "solid",
    marginBottom: 8,
    width: "70vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "rgb(225, 242, 188)",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

const EgsMEntryUpdForm = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { handleChange, handleSubmit, value, currentUser, buttonType, zipChange 
//    ,companyZipChange, jwnetChange    
    ,setValue} = props;

  // console.log('setvalue2',value, 'currentUser',currentUser);

  const { loading, isSignedIn, setIsSignedIn } =
    useContext(AuthContext);

  const [open, setOpen] = React.useState(false);

  const [dataListBusinessCategory,setDataListBusinessCategory] = useState([]);  {/* 職種カテゴリ */}
  const [dataListBusinessType,setDataListBusinessType] = useState([]);  {/* 職種 */}

  const [dataListTrader, setDataListTrader] = useState([]);

  const checkValidation = () => {
    let available = true;
    if (
      !value.entryDate     //||
//    !value.destination1Id
    ) {
      available = false;
//      setErrorcompanyAddress(!value.companyAddress);

//      alert('赤枠の入力項目に誤りがあります')
      alert('お申込日が未入力です')
    }
    return available;
  }

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
      setOpen(!open);

      const dataListBusinessType = await getEgsMBusinessTypeList();
      setDataListBusinessType(dataListBusinessType.data);

      const dataListBusinessCategory = await getEgsMBusinessCategoryList();
      setDataListBusinessCategory(dataListBusinessCategory.data);

      setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const businessCategorydataList = dataListBusinessCategory.filter(dataListBusinessCategory => { return dataListBusinessCategory.categoryCd != '' });
  const businessTypedataList = dataListBusinessType.filter(dataListBusinessType => { return dataListBusinessType.categoryCd == value.businessCategory });
  
  let wk_param

  const jwnetChange1 = async (e) => {

    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });

//    value.getCount = 0

    value.egsMCompanyId = 0
    value.egsMTraderId = 0
    value.egsMChargeId = 0

    value.jwnetNo = ''
    value.companyCd = ''
    value.companyName = ''
    value.companyKana = ''
    value.daihyo = ''
    value.daihyoName1 = ''
    value.daihyoName2 = ''
    value.daihyoKana1 = ''
    value.daihyoKana2 = ''
    value.traderCd = ''
    value.traderName = ''
    value.traderKana = ''
    value.zip = ''
    value.pref = ''
    value.city = ''
    value.town = ''
    value.address = ''
    value.building = ''
    value.phone = ''
    value.fax = ''
    value.email1 = ''
    value.email2 = ''
    value.email3 = ''

//  変更前用
    value.companyNameBk = ''
    value.companyKanaBk = ''
    value.zipBk = ''
    value.prefBk = ''
    value.cityBk = ''
    value.townBk = ''
    value.addressBk = ''
    value.buildingBk = ''
    value.phoneBk = ''
    value.faxBk = ''
    value.daihyoBk = ''
    value.daihyoName1Bk = ''
    value.daihyoName2Bk = ''
    value.daihyoKana1Bk = ''
    value.daihyoKana2Bk = ''
    value.traderNameBk = ''
    value.traderKanaBk = ''
    value.email1Bk = ''
    value.email2Bk = ''
    value.email3Bk = ''


////     if ( ( e.target.name == 'jwnetNoIn' && e.target.value.length == 7 ) || e.target.name == 'traderNameIn') {
//        try {
          if ( e.target.name == 'jwnetNoIn'){
            value.traderNameIn = ''
            wk_param = currentUser.traderCd + '_' + currentUser.traderKbn + '_' + e.target.value
          }else if ( e.target.name == 'traderNameIn'){  
            value.jwnetNoIn = ''
            wk_param = currentUser.traderCd + '_' + currentUser.traderKbn + '__' + e.target.value
          }else{  
            wk_param = currentUser.traderCd + '_' + currentUser.traderKbn + '___' + e.target.value
          }
//         setOpen(!open);
//          const dataListTrader = await getTraderAdmJwnet(e.target.value);
          const dataListTrader = await getEntryLinkOpe(wk_param);
          setDataListTrader(dataListTrader.data);
          value.getCount        = 0 + dataListTrader.data.length

          if (dataListTrader.data.length > 1){
            value.getMessage = '複数該当あり'
          }else{  
            value.getMessage = ''
          }

          setValue({
            ...value,
            [e.target.name]: e.target.value,
          });

//          alert('getCount = ' + value.getCount + ' ' + dataListTrader.data.length)
          value.jwnetNo         = dataListTrader.data[0].jwnetNo
          value.traderName      = dataListTrader.data[0].traderName

          if ( value.getCount == 1 ){

            value.egsMCompanyId   = dataListTrader.data[0].egsMCompanyId
            value.egsMTraderId    = dataListTrader.data[0].egsMTraderId
            value.egsMChargeyId   = dataListTrader.data[0].egsMChargeyId

            value.companyCd       = dataListTrader.data[0].companyCd
            value.companyName     = dataListTrader.data[0].companyName
            value.companyKana     = dataListTrader.data[0].companyKana
            value.daihyo          = dataListTrader.data[0].daihyo
            value.daihyoName1     = dataListTrader.data[0].daihyoName1
            value.daihyoName2     = dataListTrader.data[0].daihyoName2
            value.daihyoKana1     = dataListTrader.data[0].daihyoKana1
            value.daihyoKana2     = dataListTrader.data[0].daihyoKana2
    //        value.companyZip      = dataListTrader.data[0].companyZip
    //        value.companyPref     = dataListTrader.data[0].companyPrefecture
    //        value.companyCity     = dataListTrader.data[0].companyAddress
    //        value.companyTown     = dataListTrader.data[0].companyAddress2
    //        value.companyAddress  = dataListTrader.data[0].companyAddress3
    //        value.companyBuilding = dataListTrader.data[0].companyAddress4
    //        value.companyPhone    = dataListTrader.data[0].phone
    //        value.companyFax      = dataListTrader.data[0].fax
    
            value.traderCd        = dataListTrader.data[0].traderCd
            value.traderKana      = dataListTrader.data[0].traderKana
    //        value.chargeName1     = dataListTrader.data[0].chargeName1
    //        value.chargeName2     = dataListTrader.data[0].chargeName2
    //        value.chargeKana1     = dataListTrader.data[0].chargeKana1
    //        value.chargeKana2     = dataListTrader.data[0].chargeKana2
            value.zip             = dataListTrader.data[0].zip
            value.pref            = dataListTrader.data[0].pref
            value.city            = dataListTrader.data[0].city
            value.town            = dataListTrader.data[0].town
            value.address         = dataListTrader.data[0].address
            value.building        = dataListTrader.data[0].building
            value.phone           = dataListTrader.data[0].phone
            value.fax             = dataListTrader.data[0].fax
            value.email1          = dataListTrader.data[0].email1
            value.email2          = dataListTrader.data[0].email2
            value.email3          = dataListTrader.data[0].email3
      
    //        value.officeDivName     = dataListTrader.data[0].officeDivName
    //        value.officeDivKana     = dataListTrader.data[0].officeDivKana
    //        value.officeChargeName1 = dataListTrader.data[0].officeChargeName1
    //        value.officeChargeName2 = dataListTrader.data[0].officeChargeName2
    //        value.officeChargeKana1 = dataListTrader.data[0].officeChargeKana1
    //        value.officeChargeKana2 = dataListTrader.data[0].officeChargeKana2
    
    //      変更前用
    //        value.companyCdBk       = dataListTrader.data[0].companyCd
            value.companyNameBk   = dataListTrader.data[0].companyName
            value.companyKanaBk   = dataListTrader.data[0].companyKana
            value.daihyoBk        = dataListTrader.data[0].daihyo
            value.daihyoName1Bk   = dataListTrader.data[0].daihyoName1
            value.daihyoName2Bk   = dataListTrader.data[0].daihyoName2
            value.daihyoKana1Bk   = dataListTrader.data[0].daihyoKana1
            value.daihyoKana2Bk   = dataListTrader.data[0].daihyoKana2
    
    //        value.traderCd        = dataListTrader.data[0].traderCd
            value.traderNameBk    = dataListTrader.data[0].traderName
            value.traderKanaBk    = dataListTrader.data[0].traderKana
            value.zipBk           = dataListTrader.data[0].zip
            value.prefBk          = dataListTrader.data[0].pref
            value.cityBk          = dataListTrader.data[0].city
            value.townBk          = dataListTrader.data[0].town
            value.addressBk       = dataListTrader.data[0].address
            value.buildingBk      = dataListTrader.data[0].building
            value.phoneBk         = dataListTrader.data[0].phone
            value.faxBk           = dataListTrader.data[0].fax
            value.email1Bk        = dataListTrader.data[0].email1
            value.email2Bk        = dataListTrader.data[0].email2
            value.email3Bk        = dataListTrader.data[0].email3
      
            value.chargeDivision  = dataListTrader.data[0].chargeDivision
            value.traderKbn       = dataListTrader.data[0].traderKbn
    //        value.officeDivName     = dataListTrader.data[0].officeDivName
    //        value.officeDivKana     = dataListTrader.data[0].officeDivKana
    //        value.officeChargeName1 = dataListTrader.data[0].officeChargeName1
    //        value.officeChargeName2 = dataListTrader.data[0].officeChargeName2
    //        value.officeChargeKana1 = dataListTrader.data[0].officeChargeKana1
    //        value.officeChargeKana2 = dataListTrader.data[0].officeChargeKana2
  
          setValue({
              ...value,
              [e.target.name]: e.target.value,
            });
          }
  
//         setOpen(false);
//        } catch (e) {
//          console.log(e);
//        }
////      }
    };



return (
    <>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
{/*}      <SpaceRow height={20} />  */}

      <Grid item xs={12}>
        <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Select
            id='effectiveflg'
            label='申請状況'
            variant="outlined"
            type='text'
            name='effectiveflg'
            margin="dense"

            style={{ marginTop: 10 ,marginBottom: 10 }}

            onChange={(e) => handleChange(e)}
            value={value.effectiveflg}
          >
            <MenuItem value={value.effectiveflg}>
              <em>申請状況</em>
            </MenuItem>
            <MenuItem value={'0'}>0:入力中</MenuItem>
            <MenuItem value={'1'}>1:入力完了</MenuItem>
          </Select>
        
          <Typography variant="h7" style={{ fontWeight: 700 }}>
            　入力内容を申請する場合は、入力完了に変えてください。但し、入力完了に変えた場合、入力内容を編集できなくなります
          </Typography>
        
        </Box>  
      </Grid>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                お申込日・変更予定日
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='entryDate' label='お申込日' variant="outlined" type='date' name='entryDate' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
//              error={errorentryDate}
              value={value.entryDate} />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='henkouDate' label='変更予定日' variant="outlined" type='date' name='henkouDate' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.henkouDate} />
            </Box>  
{/*
              <Select
                id='effectiveflg'
                label='申請状況'
                variant="outlined"
                type='text'
                name='effectiveflg'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.effectiveflg}
              >
                <MenuItem value={value.effectiveflg}>
                  <em>申請状況</em>
                </MenuItem>
                <MenuItem value={'0'}>0:入力中</MenuItem>
                <MenuItem value={'1'}>1:入力完了</MenuItem>
              </Select>
             
          </Grid>
          
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                業者・料金区分
              </Typography>
            </Box>
          </Grid>
{/*}
          <Grid item xs={3}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Select
                id='henkouKbn'
                label='変更区分'
                variant="outlined"
                type='text'
                name='henkouKbn'
                margin="dense"

                style={{ marginTop: 10 ,marginBottom: 10 }}

                onChange={(e) => handleChange(e)}
                value={value.henkouKbn}
              >
                <MenuItem value={value.henkouKbn}>
                  <em>業者区分</em>
                </MenuItem>
                <MenuItem value={' '}> :指定しない</MenuItem>
                <MenuItem value={'1'}>1:新規登録</MenuItem>
                <MenuItem value={'2'}>2:情報変更</MenuItem>
                <MenuItem value={'3'}>3:解約申込</MenuItem>
              </Select>
            </Box>
*/}
          </Grid>

          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                業者・料金区分
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Select
                id='traderKbn'
                label='業者区分'
                variant="outlined"
                type='text'
                name='traderKbn'
                margin="dense"

                style={{ marginTop: 10 ,marginBottom: 10 }}

//                onChange={(e) => handleChange(e)}
                value={value.traderKbn}
              >
                <MenuItem value={value.traderKbn}>
                  <em>業者区分</em>
                </MenuItem>
                <MenuItem value={' '}> :指定しない</MenuItem>
                <MenuItem value={'1000000000'}>1:排出業者</MenuItem>
                <MenuItem value={'0001000000'}>2:中間処理</MenuItem>
                <MenuItem value={'0000100000'}>3:最終処分</MenuItem>
                <MenuItem value={'0001001000'}>4:中間排出</MenuItem>
              </Select>
            </Box>
          </Grid>

          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Select
                id='chargeDivision'
                label='料金区分'
                variant="outlined"
                type='text'
                name='chargeDivision'
                margin="dense"

                style={{ marginTop: 10 ,marginBottom: 10 }}

//s                onChange={(e) => handleChange(e)}
                value={value.chargeDivision}
              >
                <MenuItem value={value.chargeDivision}>
                  <em>料金区分</em>
                </MenuItem>
                <MenuItem value={''}> :指定しない</MenuItem>
                <MenuItem value={' '}> :指定しない</MenuItem>
                <MenuItem value={'1'}>1:料金区分A</MenuItem>
                <MenuItem value={'2'}>2:料金区分B</MenuItem>
                <MenuItem value={'3'}>3:料金区分C</MenuItem>
              </Select>
            </Box>

          </Grid>

        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                加入者番号・事業場名検索
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='jwnetNoIn' label='検索用加入者番号' variant="outlined" type='text' name='jwnetNoIn' margin="dense" 
//                onChange={(e) => jwnetChange(e)}
                onChange={(e) => jwnetChange1(e)}
                value={value.jwnetNoIn} />
            </Box>
          </Grid>

          <Grid item xs={3}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='traderNameIn' label='検索用事業場名' variant="outlined" type='text' name='traderNameIn' margin="dense" 
//              onChange={(e) => traderChange(e)}
              onChange={(e) => jwnetChange1(e)}
              value={value.traderNameIn} />
            </Box>
          </Grid>

        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                事業場情報
              </Typography>
            </Box>
          </Grid>

{/*}              　{/*{dataListTrader.data.length}}{value.jwnetNo}　{value.traderName}  */}

          <Grid item xs={1}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              該当件数　{value.getCount}件  {/*{value.getMessage} */} 
              </Typography>
            </Box>
          </Grid>

          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              
{/*}            <Select id='egsMTraderId1' label='事業場１' variant="standard" type='text' name='egsMTraderId1' halfWidth margin="dense" 
              onChange={(e) => handleChange(e)} 
          value={value.egsMTraderId1} >
            <Select id='traderNameIn' label='事業場１' variant="standard" type='text' name='traderNameIn' halfWidth margin="dense"  */}
            <Select id='traderCd' label='事業場１' variant="standard" type='text' name='traderCd' halfWidth margin="dense" 
            //              onChange={(e) => handleChange(e)} 
              onChange={(e) => jwnetChange1(e)} 
              value={value.traderCd} >
              {dataListTrader.map((dataListTrader) => (
                <MenuItem
                  key={dataListTrader.traderCd}
                  value={dataListTrader.traderCd}
                >
                  {dataListTrader.jwnetNo} :{dataListTrader.traderCd} :{dataListTrader.traderName} : {dataListTrader.phone} : {dataListTrader.city} : {dataListTrader.town} : {dataListTrader.address}
                </MenuItem>
              ))}

              </Select>

            </Box>
          </Grid>

        </Grid>

</div><div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                企業かな
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>{value.companyKanaBk}</Typography>
              <TextField id='companyKana' label='企業かな' variant="outlined" type='text' name='companyKana' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.companyKana} />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                企業名
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Typography variant="h7" style={{ fontWeight: 700 }}>{value.companyNameBk}</Typography>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='companyName' label='企業名' variant="outlined" type='text' name='companyName' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.companyName}  />
            </Box>
          </Grid>
        </Grid>
{/*}
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              変更前住所
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              〒{value.zipBk}　{value.prefBk}　{value.cityBk}　{value.townBk}　{value.addressBk}　{value.buildingBk}
            </Typography>
          </Grid>
        </Grid>
*/}
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              郵便番号（数値7桁）
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                変更前　〒{value.zipBk}　（{value.prefBk}　{value.cityBk}　{value.townBk}）
              </Typography>
              </Box>
          </Grid>
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='zip' label='郵便番号' variant="outlined" type='text' name='zip' halfWidth margin="dense"
              onChange={(e) => zipChange(e)}
              value={value.zip} />
              <Typography variant="h7" style={{ fontWeight: 700 }}>　（{value.pref}　：　{value.city}　：　{value.town}）
{/*}               style={{ marginTop: 2 ,marginBottom: 2 }}   */}
              </Typography>  
            </Box>
          </Grid>
          {/*}
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>住所　{value.pref}　：　{value.city}　：　{value.town}
              </Typography>  
            </Box>
          </Grid>
          */}
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                詳細住所
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
           <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>{value.addressBk}</Typography>
              <TextField id='address' label='詳細住所' variant="outlined" type='text' name='address' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.address} />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                ビル名
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Typography variant="h7" style={{ fontWeight: 700 }}>{value.buildingBk}</Typography>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='building' label='ビル名' variant="outlined" type='text' name='building' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.building}  />
            </Box>
          </Grid>
        </Grid>
{/*}
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              変更前電話番号・FAX番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              電話番号：{value.phoneBk}　FAX番号：{value.faxBk}
            </Typography>
          </Grid>
        </Grid>
*/}
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                電話・FAX番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>{value.phoneBk}　</Typography>
              <TextField id='phone' label='電話番号' variant="outlined" type='text' name='phone' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.phone} />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                FAX
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>{value.faxBk}　</Typography>
              <TextField id='fax' label='FAX番号' variant="outlined" type='text' name='fax' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.fax}  />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                代表者役職・かな名
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>代表者役職：{value.daihyoBk}</Typography>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='daihyo' label='代表者役職' variant="outlined" type='text' name='daihyo' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.daihyo} />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>{value.daihyoKana1Bk}　{value.daihyoKana2Bk}</Typography>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='daihyoKana1' label='代表者かな姓' variant="outlined" type='text' name='daihyoKana1' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.daihyoKana1} />
              <Typography variant="h7" style={{ fontWeight: 700 }}>　</Typography>
              <TextField id='daihyoKana2' label='代表者かな名' variant="outlined" type='text' name='daihyoKana2' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.daihyoKana2} />
            </Box>
          </Grid>
          <Grid item xs>
            <Typography variant="h7" style={{ fontWeight: 700 }}>{value.daihyoName1Bk}　{value.daihyoName2Bk}</Typography>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='daihyoName1' label='代表者姓' variant="outlined" type='text' name='daihyoName1' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.daihyoName1}  />
              <Typography variant="h7" style={{ fontWeight: 700 }}>　</Typography>
              <TextField id='daihyoName2' label='代表者名' variant="outlined" type='text' name='daihyoName2' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.daihyoName2}  />
            </Box>
          </Grid>
        </Grid>
{/*}
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                代表者名
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>{value.daihyoName1Bk}　{value.daihyoName2Bk}</Typography>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='daihyoName1' label='代表者姓' variant="outlined" type='text' name='daihyoName1' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.daihyoName1}  />
              <Typography variant="h7" style={{ fontWeight: 700 }}>　</Typography>
              <TextField id='daihyoName2' label='代表者名' variant="outlined" type='text' name='daihyoName2' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.daihyoName2}  />
            </Box>
          </Grid>
        </Grid>
*/}
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                事業場かな
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>{value.traderKanaBk}</Typography>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='traderKana' label='事業場かな' variant="outlined" type='text' name='traderKana' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.traderKana} />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                事業場名
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Typography variant="h7" style={{ fontWeight: 700 }}>{value.traderNameBk}</Typography>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='traderName' label='事業場名' variant="outlined" type='text' name='traderName' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.traderName}  />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                E-Mail
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>{value.email1Bk}　{value.email2Bk}　{value.email3Bk}</Typography>
              <TextField id='email1' label='E-mail1' variant="outlined" type='text' name='email1' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.email1}  />
              <Typography variant="h7" style={{ fontWeight: 700 }}>　</Typography>
              <TextField id='email2' label='E-mail2' variant="outlined" type='text' name='email2' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.email2}  />
              <Typography variant="h7" style={{ fontWeight: 700 }}>　</Typography>
              <TextField id='email3' label='E-mail2' variant="outlined" type='text' name='email3' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.email3}  />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >

          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
             <Typography variant="h7" style={{ fontWeight: 700 }}>
              業種
             </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Select id='businessCategory' label='業種' variant="outlined" type='text' name='businessCategory' margin="dense"
//              style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10 }}
//                style={{ marginTop: 2 ,marginBottom: 2 }} 
                fullWidth onChange={(e) => handleChange(e)} value={value.businessCategory}
               >
                {businessCategorydataList.map((businessCategorydataList) => (
                  <MenuItem key={businessCategorydataList.categoryCd} value={businessCategorydataList.categoryCd} > {businessCategorydataList.categoryCd}:{businessCategorydataList.categoryName} </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Select id='businessType' label='業種' variant="outlined" type='text' name='businessType' margin="dense"
//              style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10 }}
//                style={{ marginTop: 2 ,marginBottom: 2 }} 
                fullWidth onChange={(e) => handleChange(e)} value={value.businessType}
               >
                {businessTypedataList.map((businessTypedataList) => (
                  <MenuItem key={businessTypedataList.businessTypeCd} value={businessTypedataList.businessTypeCd} > {businessTypedataList.businessTypeCd}:{businessTypedataList.businessTypeName} </MenuItem>
                ))}
              </Select>


            </Box>
          </Grid>

        </Grid>
{/*}        
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                法人区分
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Select
                id='companyKind'
                label='法人区分'
                variant="outlined"
                type='text'
                name='companyKind'
                margin="dense"

                style={{ marginTop: 10 ,marginBottom: 10 }}

                onChange={(e) => handleChange(e)}
                value={value.companyKind}
              >
                <MenuItem value={value.companyKind}>
                  <em>法人区分</em>
                </MenuItem>
                <MenuItem value={'00'}>00:個人事業</MenuItem>
                <MenuItem value={'01'}>01:株式会社</MenuItem>
                <MenuItem value={'02'}>02:有限会社</MenuItem>
                <MenuItem value={'03'}>03:合名会社</MenuItem>
                <MenuItem value={'04'}>04:合資会社</MenuItem>
                <MenuItem value={'05'}>05:医療法人</MenuItem>
                <MenuItem value={'06'}>06:財団法人</MenuItem>
                <MenuItem value={'07'}>07:社団法人</MenuItem>
                <MenuItem value={'08'}>08:宗教法人</MenuItem>
                <MenuItem value={'09'}>09:学校法人</MenuItem>
                <MenuItem value={'10'}>10:社会福祉法人</MenuItem>
                <MenuItem value={'11'}>11:相互会社</MenuItem>
                <MenuItem value={'12'}>12:行政書士法人</MenuItem>
                <MenuItem value={'13'}>13:司法書士法人</MenuItem>
                <MenuItem value={'14'}>14:税理士法人</MenuItem>
                <MenuItem value={'15'}>15:国立大学法人</MenuItem>
                <MenuItem value={'16'}>16:特定非営利活動法人</MenuItem>
                <MenuItem value={'17'}>17:更生保護法人</MenuItem>
                <MenuItem value={'18'}>18:独立行政法人</MenuItem>
                <MenuItem value={'19'}>19:弁護士法人</MenuItem>
                <MenuItem value={'20'}>20:有限責任中間法人</MenuItem>
                <MenuItem value={'21'}>21:無限責任中間法人</MenuItem>
                <MenuItem value={'22'}>22:医療法人社団</MenuItem>
                <MenuItem value={'23'}>23:公共機関</MenuItem>
                <MenuItem value={'24'}>24:公益社団法人</MenuItem>
                <MenuItem value={'25'}>25:公益財団法人</MenuItem>
                <MenuItem value={'26'}>26:一般社団法人</MenuItem>
                <MenuItem value={'27'}>27:一般財団法人</MenuItem>
                <MenuItem value={'28'}>28:医療法人財団</MenuItem>
                <MenuItem value={'29'}>29:協同組合</MenuItem>
                <MenuItem value={'99'}>99:その他の法人</MenuItem>
              </Select>
            <Select
              id='companyKbn'
              label='前後'
              variant="outlined"
              type='text'
              name='companyKbn'
              margin="dense"

              style={{ marginTop: 10 ,marginBottom: 10 }}

              onChange={(e) => handleChange(e)}
              value={value.companyKbn}
            >
              <MenuItem value={value.companyKbn}>
                <em>前後</em>
              </MenuItem>
              <MenuItem value={' '}> :指定しない</MenuItem>
              <MenuItem value={'1'}>1:前</MenuItem>
              <MenuItem value={'2'}>2:後</MenuItem>
            </Select>
          </Box>
          </Grid>
        </Grid>
*/}

      </div>

      <div style={{ textAlign: 'right' }}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          onClick={(e) => {
//            handleSubmit(e);
            if (checkValidation()) {
              handleSubmit(e);
            }
          }}
          style={{ marginRight: 10 }}
        >
          {buttonType}
        </Button>
      </div>
    </>
  );
};
export default EgsMEntryUpdForm;
