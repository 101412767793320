import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
// style
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import './app.css';
import { statusName } from '../../constants'
// import FormControl from '@mui/material/FormControl';
import SpaceRow from '../commons/SpaceRow';
import { createEditDelRequest } from '../../lib/api/edit_del_request';
import { getChargeUser } from '../../lib/api/charge';
import { getWasteCdList } from '../../lib/api/waste_cd';
import { getEgsMUnitList } from '../../lib/api/egs_m_unit';
import { getEgsMPackingList } from '../../lib/api/egs_m_packing';
import { getEgsMDisposalMethodList } from '../../lib/api/egs_m_disposal_method';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "70vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#FFC",
    borderWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  title_box2: {
    background: "#FCF",
    borderWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  title_box3: {
    background: "#0CF",
    borderWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

function EditDelRequestForm(props) {
  const classes = useStyles();
  const { dataList2, value } = props;

  const [dataListWaste,setDataListWaste] = useState([]);  {/* 廃棄物種類 */}
  const [dataListUnit,setDataListUnit] = useState([]);  {/* 単位 */}
  const [dataListPacking,setDataListPacking] = useState([]);  {/* 荷姿 */}
  const [dataListDisposal,setDataListDisposal] = useState([]);  {/* 処分方法 */}

  const [selectedAction, setSelectedAction] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [exhaustDate, setExhaustDate] = useState('');
  const [exhaustCharge, setExhaustCharge] = useState('');
  const [wasteKind, setWasteKind] = useState('');
  const [wasteName, setWasteName] = useState('');
  const [amount1, setAmount1] = useState('');
  const [packingAmount1, setPackingAmount1] = useState('');
  const [unitName, setUnitName] = useState('');
  const [packingUnit,setPackingUnit] = useState('');
  const [disposalMethod,setDisposalMethod] = useState('');
  const [memo1, setMemo1] = useState('');
  const [memo2, setMemo2] = useState('');
  const [memo3, setMemo3] = useState('');
  const [memo4, setMemo4] = useState('');
  const [memo5, setMemo5] = useState('');
  const [sectEndDate, setSectEndDate] = useState('');

  const [middleEndDate, setMiddleEndDate] = useState('');

  const [sectChargeList, setSectChargeList] = useState('');
  const [selectedCharge, setSelectedCharge] = useState('');

  console.log("value",value);

  useEffect(() => {
    return () => {
      setSelectedAction('');
      setAdditionalInfo('');
      setSectEndDate('');

      setMiddleEndDate('');
      
      setSelectedCharge('');
      // 他の状態も同様にリセット
    };
  }, []);
  
  const handleRadioChange = (event) => {
    const newSelectedAction = event.target.value;
    setSelectedAction(newSelectedAction);
    // console.log("selectedAction", newSelectedAction);
    value.req_kind = newSelectedAction;

    if (newSelectedAction === 'マニフェスト修正') {
      // マニフェスト修正が選択された場合、getChargeUserを実行
      handleGetChargeUser(dataList2.exhaustCd);
      // console.log("sectChargeList",sectChargeList);
      handleGetList();
    }
    if (newSelectedAction === '区間１修正') {
      // 区間１修正が選択された場合、getChargeUserを実行
      handleGetChargeUser(dataList2.sect1TransportCd);
      // console.log("sectChargeList",sectChargeList);
    }
    if (newSelectedAction === '区間２修正') {
      // 区間２修正が選択された場合、getChargeUserを実行
      handleGetChargeUser(dataList2.sect2TransportCd);
      // console.log("sectChargeList",sectChargeList);
    }
    if (newSelectedAction === '区間３修正') {
      // 区間３修正が選択された場合、getChargeUserを実行
      handleGetChargeUser(dataList2.sect3TransportCd);
      // console.log("sectChargeList",sectChargeList);
    }
    if (newSelectedAction === '処分報告修正') {
      // 区間３修正が選択された場合、getChargeUserを実行
      handleGetChargeUser(value.trader_cd);
      // console.log("sectChargeList",sectChargeList);
    }
  };

  const handleGetChargeUser = async (traderCd) => {
    try {
      const res = await getChargeUser(traderCd);
      // console.log(res.data);
      setSectChargeList(res.data);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const handleGetList = async () => {
    try {
      const dataListWaste = await getWasteCdList();
      setDataListWaste(dataListWaste.data);
      const dataListUnit = await getEgsMUnitList();
      setDataListUnit(dataListUnit.data);
      const dataListPacking = await getEgsMPackingList();
      setDataListPacking(dataListPacking.data);
      const dataListDisposal = await getEgsMDisposalMethodList();
      setDataListDisposal(dataListDisposal.data);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const dataListWasteKind = dataListWaste.filter(dataListWaste =>
    { return dataListWaste.wasteKindCd == dataListWaste.wasteCd });
  const dataListWasteCd = dataListWaste.filter(dataListWaste =>
    { return dataListWaste.wasteKindName == wasteKind });
  const unitdataListCd = dataListUnit.filter(dataListUnit => 
    { return dataListUnit.unitclassCd != '' });
  const packingdataList = dataListPacking.filter(dataListPacking =>
    { return dataListPacking.packingCd != '' });
  const disposaldataList = dataListDisposal.filter(dataListDisposal => 
    { return dataListDisposal.disposalMethodCd != '' });

  const handleAdditionalInfoChange = (event) => {
    const newAdditionalInfo = event.target.value;
    setAdditionalInfo(newAdditionalInfo);
    // console.log("additionalInfo", newAdditionalInfo);
    value.req_comment = newAdditionalInfo;    
  };

  const handleExhaustDateChange = (event) => {
    const newExhaustDate = event.target.value;
    setExhaustDate(newExhaustDate);
    if (dataList2.exhaustDate != newExhaustDate) {
        value.req_item_1 = '排出日';
        value.old_data_1 = dataList2.exhaustDate;
        value.new_data_1 = newExhaustDate;
    }
  };
  const handleExhaustChargeChange = (event) => {
    const newExhaustCharge = event.target.value;
    setExhaustCharge(newExhaustCharge);
    if (dataList2.exhaustChargeName != newExhaustCharge) {
        value.req_item_2 = '排出担当者';
        value.old_data_2 = dataList2.exhaustChargeName;
        value.new_data_2 = newExhaustCharge;
    }
  };
  const handleWasteKindChange = (event) => {
    const newWasteKind = event.target.value;
    setWasteKind(newWasteKind);
    if (dataList2.wasteKindName != newWasteKind) {
      value.req_item_3 = '廃棄物種類';
      value.old_data_3 = dataList2.wasteKindName;
      value.new_data_3 = newWasteKind;
    }
  };
  const handleWasteNameChange = (event) => {
    const newWasteName = event.target.value;
    setWasteName(newWasteName);
    if (dataList2.wasteName != newWasteName) {
      value.req_item_4 = '廃棄物名称';
      value.old_data_4 = dataList2.wasteName;
      value.new_data_4 = newWasteName;
    }
  };
  const handleAmount1Change = (event) => {
    const newAmount1 = event.target.value;
    setAmount1(newAmount1);
    if (dataList2.amount1 != newAmount1) {
      value.req_item_5 = '廃棄物の数量';
      value.old_data_5 = dataList2.amount1;
      value.new_data_5 = newAmount1;
    }
  };
  const handleUnitNameChange = (event) => {
    const newUnitName = event.target.value;
    setUnitName(newUnitName);
    if (dataList2.unitName != newUnitName) {
      value.req_item_6 = '数量の単位';
      value.old_data_6 = dataList2.unitName;
      value.new_data_6 = newUnitName;
    }
  };
  const handlePackingAmount1Change = (event) => {
    const newPackingAmount1 = event.target.value;
    setPackingAmount1(newPackingAmount1);
    if (dataList2.packingAmount1 != newPackingAmount1) {
      value.req_item_7 = '荷姿の数量';
      value.old_data_7 = dataList2.packingAmount1;
      value.new_data_7 = newPackingAmount1;
    }
  };
  const handlePackingNameChange = (event) => {
    const newPackingName = event.target.value;
    setPackingUnit(newPackingName);
    if (dataList2.packingName != newPackingName) {
      value.req_item_8 = '荷姿の名称';
      value.old_data_8 = dataList2.packingName;
      value.new_data_8 = newPackingName;
    }
  };
  const handleMemo1Change = (event) => {
    const newMemo1 = event.target.value;
    setMemo1(newMemo1);
    if (dataList2.memo1 != newMemo1) {
      value.req_item_9 = '備考１';
      value.old_data_9 = dataList2.memo1;
      value.new_data_9 = newMemo1;
    }
  };
  const handleMemo2Change = (event) => {
    const newMemo2 = event.target.value;
    setMemo2(newMemo2);
    if (dataList2.memo2 != newMemo2) {
      value.req_item_10 = '備考２';
      value.old_data_10 = dataList2.memo2;
      value.new_data_10 = newMemo2;
    }
  };
  const handleMemo3Change = (event) => {
    const newMemo3 = event.target.value;
    setMemo3(newMemo3);
    if (dataList2.memo3 != newMemo3) {
      value.req_item_11 = '備考３';
      value.old_data_11 = dataList2.memo3;
      value.new_data_11 = newMemo3;
    }
  };
  const handleMemo4Change = (event) => {
    const newMemo4 = event.target.value;
    setMemo4(newMemo4);
    if (dataList2.memo4 != newMemo4) {
      value.req_item_12 = '備考４';
      value.old_data_12 = dataList2.memo4;
      value.new_data_12 = newMemo4;
    }
  };
  const handleMemo5Change = (event) => {
    const newMemo5 = event.target.value;
    setMemo5(newMemo5);
    if (dataList2.memo5 != newMemo5) {
      value.req_item_13 = '備考５';
      value.old_data_13 = dataList2.memo5;
      value.new_data_13 = newMemo5;
    }
  };
  const handleDisposalMethodChange = (event) => {
    const newDisposalMethod = event.target.value;
    setDisposalMethod(newDisposalMethod);
    if (dataList2.disposalMethodName != newDisposalMethod) {
      value.req_item_14 = '処分方法';
      value.old_data_14 = dataList2.disposalMethodName;
      value.new_data_14 = newDisposalMethod;
    }
  };
  const handleSect1EndDateChange = (event) => {
    const newSect1EndDate = event.target.value;
    setSectEndDate(newSect1EndDate);
        value.req_item_1 = '運搬終了日';
        value.old_data_1 = dataList2.destination1At;
        value.new_data_1 = newSect1EndDate;
  };

  const handleMiddleEndDateChange = (event) => {
    const newMiddleEndDate = event.target.value;
    setMiddleEndDate(newMiddleEndDate);
        value.req_item_1 = '運搬終了日';
        value.old_data_1 = dataList2.middleEnd;
        value.new_data_1 = newMiddleEndDate;
  };

  const handleSect1ChargeChange = (event) => {
    const newSect1ChargeName = event.target.value;
    setSelectedCharge(newSect1ChargeName);
      value.req_item_2 = '運搬担当者';
      value.old_data_2 = dataList2.sect1TransportChargeName;
      value.new_data_2 = newSect1ChargeName;
  };

  const handleSect2EndDateChange = (event) => {
    const newSect2EndDate = event.target.value;
    setSectEndDate(newSect2EndDate);
        value.req_item_1 = '運搬終了日';
        value.old_data_1 = dataList2.destination2At;
        value.new_data_1 = newSect2EndDate;
  };
  const handleSect2ChargeChange = (event) => {
    const newSect2ChargeName = event.target.value;
    setSelectedCharge(newSect2ChargeName);
      value.req_item_2 = '運搬担当者';
      value.old_data_2 = dataList2.sect2TransportChargeName;
      value.new_data_2 = newSect2ChargeName;
  };

  const handleSect3EndDateChange = (event) => {
    const newSect3EndDate = event.target.value;
    setSectEndDate(newSect3EndDate);
        value.req_item_1 = '運搬終了日';
        value.old_data_1 = dataList2.destination3At;
        value.new_data_1 = newSect3EndDate;
  };
  const handleSect3ChargeChange = (event) => {
    const newSect3ChargeName = event.target.value;
    setSelectedCharge(newSect3ChargeName);
      value.req_item_2 = '運搬担当者';
      value.old_data_2 = dataList2.sect3TransportChargeName;
      value.new_data_2 = newSect3ChargeName;
  };

  if (value.trader_kbn == '0100000000'){
  return (
    <>
      <Box component='div' sx={{ p: 0, textAlign: 'right' }}></Box>
      <div className={classes.container}>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h6" style={{ fontWeight: 700 }}>
                廃棄物一覧ID
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h6" style={{ fontWeight: 700 }}> {dataList2.id} </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h6" style={{ fontWeight: 700 }}>
                マニフェスト番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h6" style={{ fontWeight: 700 }}> {dataList2.manifestNo} </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h6" style={{ fontWeight: 700 }}>
                ゴミタグ番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h6" style={{ fontWeight: 700 }}> {dataList2.garbageTag} </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
          alignItems="center"  // 垂直方向の中央揃え
          justifyContent="center"  // 水平方向の中央揃え
          style={{
            borderTopWidth: 2,
            borderTopColor: "black",
            paddingTop: "10px", // 上余白を追加
            paddingBottom: "10px" // 下余白を追加
          }}
        >
        <Typography variant="h5" style={{ fontWeight: 700, color: "#800000" }}>
        　　{dataList2.exhaustName} 
        　　排出日：{dataList2.exhaustDate}
        　　廃棄物種類：{dataList2.wasteKindName}
        </Typography>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box2} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h6" style={{ fontWeight: 700 }}>
                依頼項目（選択必須）
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center" style={{ marginBottom: "2px" }}>
              <FormControl component="fieldset">
                <RadioGroup 
                  row 
                  aria-label="manifestAction" 
                  name="manifestAction" 
                  style={{ alignItems: 'center' }} 
                  // defaultValue="マニフェスト修正"
                  onChange={handleRadioChange}  // この行を追加
                >
                  <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                    マニフェスト情報：
                  </Typography>
                    <FormControlLabel value="マニフェスト修正" control={<Radio />} label="修正" />
                    <FormControlLabel value="マニフェスト取消" control={<Radio />} label="取消" />
                    <Box sx={{ width: '100%', height: 0 }}></Box>
                    <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                      運搬終了報告：　　
                    </Typography>
                    <FormControlLabel value="区間１修正" control={<Radio />} label="区間１修正" disabled={!dataList2.destination1At} />
                    <FormControlLabel value="区間２修正" control={<Radio />} label="区間２修正" disabled={!dataList2.destination2At} />
                    <FormControlLabel value="区間３修正" control={<Radio />} label="区間３修正" disabled={!dataList2.destination3At} />
                    <Box width="10ch"></Box>
                    <FormControlLabel value="区間１取消" control={<Radio />} label="区間１取消" disabled={!dataList2.destination1At}  />
                    <FormControlLabel value="区間２取消" control={<Radio />} label="区間２取消" disabled={!dataList2.destination2At}  />
                    <FormControlLabel value="区間３取消" control={<Radio />} label="区間３取消" disabled={!dataList2.destination3At}  />
                    <Box sx={{ width: '100%', height: 0 }}></Box>
                    <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                      その他：　　　　　　
                    <FormControlLabel value="その他" control={<Radio />} label="（補足事項へ依頼内容を記載）" />
                  </Typography>
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        {/* Existing Grids omitted for brevity */}
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          {/* Existing Grid items omitted for brevity */}
        </Grid>
        {selectedAction === 'マニフェスト修正' && (
          <Grid container spacing={0} className={classes.row}>
            <Grid item xs={2}>
              <Box className={classes.title_box3} p={1} display="flex" alignItems="center">
                {/* <Typography variant="h6" style={{ fontWeight: 700 }}>
                  マニフェスト修正
                </Typography> */}
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                 【排出日】{dataList2.exhaustDate}　⇒　修正後：
                  <TextField
                    type="date"
                    value={exhaustDate}
                    onChange={handleExhaustDateChange}
                    InputProps={{
                      style: { fontSize: "18px", color: "#ff00ff" } // フォントサイズを16pxに設定
                    }}
                  />
                </Typography>

              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                【排出担当者】{dataList2.exhaustChargeName}　⇒　修正後：
                </Typography>
                <Select
                  value={exhaustCharge} // 選択された排出担当者
                  onChange={handleExhaustChargeChange} // 運搬担当者が変更された時の処理
                  style={{ fontSize: "18px", color: "#ff00ff" }} // 選択後の文字の大きさを設定
                >
                {sectChargeList && sectChargeList.map((charge) => (
                  <MenuItem key={charge.id} value={charge.chargeName}>{charge.chargeName}</MenuItem>
                ))}
              </Select>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box3} p={1} display="flex" alignItems="center">
                {/* <Typography variant="h6" style={{ fontWeight: 700 }}>
                  マニフェスト修正
                </Typography> */}
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                【廃棄物種類】{dataList2.wasteKindName}　⇒　修正後：
                </Typography>
                <Select
                  value={wasteKind} 
                  onChange={handleWasteKindChange}
                  style={{ fontSize: "18px", color: "#ff00ff" }}
                >
                {dataListWasteKind && dataListWasteKind.map((dataListWasteKind) => (
                  <MenuItem key={dataListWasteKind.id} value={dataListWasteKind.wasteKindName}>{dataListWasteKind.wasteKindName}</MenuItem>
                ))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                【廃棄物名称】{dataList2.wasteName}　⇒　修正後：
                </Typography>
                <Select
                  value={wasteName} 
                  onChange={handleWasteNameChange}
                  style={{ fontSize: "18px", color: "#ff00ff" }}
                >
                {dataListWasteCd && dataListWasteCd.map((dataListWasteCd) => (
                  <MenuItem key={dataListWasteCd.id} value={dataListWasteCd.wasteName}>{dataListWasteCd.wasteName}</MenuItem>
                ))}
                </Select>
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box className={classes.title_box3} p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700 }}>
                  マニフェスト修正
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                【廃棄物の数量】{dataList2.amount1}　⇒　修正後：
                </Typography>
                <TextField
                  value={amount1}
                  type='number'
                  onChange={handleAmount1Change}
                  InputProps={{
                    style: { fontSize: "18px", color: "#ff00ff" } // フォントサイズを16pxに設定
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                【荷姿の数量】{dataList2.packingAmount1}　⇒　修正後：
                </Typography>
                <TextField
                  value={packingAmount1}
                  type='number'
                  onChange={handlePackingAmount1Change}
                  InputProps={{
                    style: { fontSize: "18px", color: "#ff00ff" } // フォントサイズを16pxに設定
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box3} p={1} display="flex" alignItems="center">
                {/* <Typography variant="h6" style={{ fontWeight: 700 }}>
                  マニフェスト修正
                </Typography> */}
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                【数量の単位】{dataList2.unitName}　⇒　修正後：
                </Typography>
                <Select
                  value={unitName} 
                  onChange={handleUnitNameChange}
                  style={{ fontSize: "18px", color: "#ff00ff" }}
                >
                {unitdataListCd && unitdataListCd.map((dataListUnit) => (
                  <MenuItem key={dataListUnit.id} value={dataListUnit.unitName}>{dataListUnit.unitName}</MenuItem>
                ))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                【荷姿の名称】{dataList2.packingName}　⇒　修正後：
                </Typography>
                <Select
                  value={packingUnit} 
                  onChange={handlePackingNameChange}
                  style={{ fontSize: "18px", color: "#ff00ff" }}
                >
                {packingdataList && packingdataList.map((dataListPacking) => (
                  <MenuItem key={dataListPacking.id} value={dataListPacking.packingName}>{dataListPacking.packingName}</MenuItem>
                ))}
                </Select>
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box className={classes.title_box3} p={1} display="flex" alignItems="center">
                {/* <Typography variant="h6" style={{ fontWeight: 700 }}>
                  マニフェスト修正
                </Typography> */}
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                【備考１】{dataList2.memo1}　⇒　修正後：
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  value={memo1}
                  onChange={handleMemo1Change}
                  InputProps={{
                    style: { fontSize: "18px", color: "#ff00ff" } // フォントサイズを16pxに設定
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                【備考２】{dataList2.memo2}　⇒　修正後：
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  value={memo2}
                  onChange={handleMemo2Change}
                  InputProps={{
                    style: { fontSize: "18px", color: "#ff00ff" } // フォントサイズを16pxに設定
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box3} p={1} display="flex" alignItems="center">
                {/* <Typography variant="h6" style={{ fontWeight: 700 }}>
                  マニフェスト修正
                </Typography> */}
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                【備考３】{dataList2.memo3}　⇒　修正後：
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  value={memo3}
                  onChange={handleMemo3Change}
                  InputProps={{
                    style: { fontSize: "18px", color: "#ff00ff" } // フォントサイズを16pxに設定
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                【備考４】{dataList2.memo4}　⇒　修正後：
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  value={memo4}
                  onChange={handleMemo4Change}
                  InputProps={{
                    style: { fontSize: "18px", color: "#ff00ff" } // フォントサイズを16pxに設定
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box3} p={1} display="flex" alignItems="center">
                {/* <Typography variant="h6" style={{ fontWeight: 700 }}>
                  マニフェスト修正
                </Typography> */}
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                【備考５】{dataList2.memo5}　⇒　修正後：
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  value={memo5}
                  onChange={handleMemo5Change}
                  InputProps={{
                    style: { fontSize: "18px", color: "#ff00ff" } // フォントサイズを16pxに設定
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                【処分方法】{dataList2.disposalMethodName}　⇒　修正後：
                </Typography>
                <Select
                  value={disposalMethod} 
                  onChange={handleDisposalMethodChange}
                  style={{ fontSize: "18px", color: "#ff00ff" }}
                >
                {disposaldataList && disposaldataList.map((disposaldataList) => (
                  <MenuItem key={disposaldataList.id} value={disposaldataList.disposalMethodName}>{disposaldataList.disposalMethodName}</MenuItem>
                ))}
              </Select>
              </Box>
            </Grid>

          </Grid>
        )}

        {/* Existing Grids omitted for brevity */}
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          {/* Existing Grid items omitted for brevity */}
        </Grid>
        {selectedAction === '区間１修正' && (
          <Grid container spacing={0} className={classes.row}>
            <Grid item xs={2}>
              <Box className={classes.title_box3} p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700 }}>
                  区間１運搬終了報告修正
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                【運搬終了日】{dataList2.destination1At}　⇒　修正後：
                  <TextField
                    type="date"
                    value={sectEndDate}
                    onChange={handleSect1EndDateChange}
                    InputProps={{
                      style: { fontSize: "18px", color: "#ff00ff" } // フォントサイズを16pxに設定
                    }}
                  />
                </Typography>

              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                【運搬担当者】{dataList2.sect1TransportChargeName}　⇒　修正後：
                </Typography>
                <Select
                  value={selectedCharge} // 選択された運搬担当者
                  onChange={handleSect1ChargeChange} // 運搬担当者が変更された時の処理
                  style={{ fontSize: "18px", color: "#ff00ff" }} // 選択後の文字の大きさを設定
                >
                {sectChargeList && sectChargeList.map((charge) => (
                  <MenuItem key={charge.id} value={charge.chargeName}>{charge.chargeName}</MenuItem>
                ))}
              </Select>
              </Box>
            </Grid>
          </Grid>
        )}
        {selectedAction === '区間２修正' && (
          <Grid container spacing={0} className={classes.row}>
            <Grid item xs={2}>
              <Box className={classes.title_box3} p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700 }}>
                  区間２運搬終了報告修正
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                【運搬終了日】{dataList2.destination2At}　⇒　修正後：
                  <TextField
                    type="date"
                    value={sectEndDate}
                    onChange={handleSect2EndDateChange}
                    InputProps={{
                      style: { fontSize: "18px", color: "#ff00ff" } // フォントサイズを16pxに設定
                    }}
                  />
                </Typography>

              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                【運搬担当者】{dataList2.sect2TransportChargeName}　⇒　修正後：
                </Typography>
                <Select
                  value={selectedCharge} // 選択された運搬担当者
                  onChange={handleSect2ChargeChange} // 運搬担当者が変更された時の処理
                  style={{ fontSize: "18px", color: "#ff00ff" }} // 選択後の文字の大きさを設定
                >
                {sectChargeList && sectChargeList.map((charge) => (
                  <MenuItem key={charge.id} value={charge.chargeName}>{charge.chargeName}</MenuItem>
                ))}
              </Select>
              </Box>
            </Grid>
          </Grid>
        )}
        {selectedAction === '区間３修正' && (
          <Grid container spacing={0} className={classes.row}>
            <Grid item xs={2}>
              <Box className={classes.title_box3} p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700 }}>
                  区間３運搬終了報告修正
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                【運搬終了日】{dataList2.destination3At}　⇒　修正後：
                  <TextField
                    type="date"
                    value={sectEndDate}
                    onChange={handleSect3EndDateChange}
                    InputProps={{
                      style: { fontSize: "18px", color: "#ff00ff" } // フォントサイズを16pxに設定
                    }}
                  />
                </Typography>

              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                【運搬担当者】{dataList2.sect3TransportChargeName}　⇒　修正後：
                </Typography>
                <Select
                  value={selectedCharge} // 選択された運搬担当者
                  onChange={handleSect3ChargeChange} // 運搬担当者が変更された時の処理
                  style={{ fontSize: "18px", color: "#ff00ff" }} // 選択後の文字の大きさを設定
                >
                {sectChargeList && sectChargeList.map((charge) => (
                  <MenuItem key={charge.id} value={charge.chargeName}>{charge.chargeName}</MenuItem>
                ))}
              </Select>
              </Box>
            </Grid>
          </Grid>
        )}

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box2} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h6" style={{ fontWeight: 700 }}>
                補足事項
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center" style={{ marginBottom: "2px" }}>
              <TextField
                fullWidth
                variant="outlined"
                value={additionalInfo}
                onChange={handleAdditionalInfoChange}
                placeholder="補足事項がございましたらご入力ください"
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  )
}else if(value.trader_kbn == '0001000000' || value.trader_kbn == '0001001000'){
  return (
    <>
      <Box component='div' sx={{ p: 0, textAlign: 'right' }}></Box>
      <div className={classes.container}>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h6" style={{ fontWeight: 700 }}>
                廃棄物一覧ID
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h6" style={{ fontWeight: 700 }}> {dataList2.id} </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h6" style={{ fontWeight: 700 }}>
                マニフェスト番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h6" style={{ fontWeight: 700 }}> {dataList2.manifestNo} </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h6" style={{ fontWeight: 700 }}>
                ゴミタグ番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h6" style={{ fontWeight: 700 }}> {dataList2.garbageTag} </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
          alignItems="center"  // 垂直方向の中央揃え
          justifyContent="center"  // 水平方向の中央揃え
          style={{
            borderTopWidth: 2,
            borderTopColor: "black",
            paddingTop: "10px", // 上余白を追加
            paddingBottom: "10px" // 下余白を追加
          }}
        >
        <Typography variant="h5" style={{ fontWeight: 700, color: "#800000" }}>
        　　{dataList2.exhaustName} 
        　　排出日：{dataList2.exhaustDate}
        　　廃棄物種類：{dataList2.wasteKindName}
        </Typography>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box2} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h6" style={{ fontWeight: 700 }}>
                依頼項目（選択必須）
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center" style={{ marginBottom: "2px" }}>
              <FormControl component="fieldset">
                <RadioGroup 
                  row 
                  aria-label="manifestAction" 
                  name="manifestAction" 
                  style={{ alignItems: 'center' }} 
                  // defaultValue="マニフェスト修正"
                  onChange={handleRadioChange}  // この行を追加
                >
                    <Box sx={{ width: '100%', height: 0 }}></Box>
                    <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                      処分終了報告：　　
                    </Typography>
                    <FormControlLabel value="処分報告修正" control={<Radio />} label="修正" />
                    <FormControlLabel value="処分報告取消" control={<Radio />} label="取消" />
                    <Box sx={{ width: '100%', height: 0 }}></Box>
                    <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                      その他：　　　　　　
                    <FormControlLabel value="その他" control={<Radio />} label="（補足事項へ依頼内容を記載）" />
                  </Typography>
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        {/* Existing Grids omitted for brevity */}
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          {/* Existing Grid items omitted for brevity */}
        </Grid>
        {selectedAction === '処分報告修正' && (
          <Grid container spacing={0} className={classes.row}>
            <Grid item xs={2}>
              <Box className={classes.title_box3} p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700 }}>
                  処分終了報告修正
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                【処分終了日】{dataList2.middleEnd}　⇒　修正後：  {/* destination1At */}
                  <TextField
                    type="date"
                    value={middleEndDate}  // sectEndDate
                    onChange={handleMiddleEndDateChange}  // handleSect1EndDateChange
                    InputProps={{
                      style: { fontSize: "18px", color: "#ff00ff" } // フォントサイズを16pxに設定
                    }}
                  />
                </Typography>

              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center">
                {/* <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                【処分担当者】{dataList2.destination1ChargeName}　⇒　修正後：
                </Typography> */}
                <Typography variant="h6" style={{ fontWeight: 700, marginRight: "20px" }}>
                【処分担当者】　修正後：
                </Typography>
                <Select
                  value={selectedCharge} // 選択された運搬担当者
                  onChange={handleSect1ChargeChange} // 運搬担当者が変更された時の処理
                  style={{ fontSize: "18px", color: "#ff00ff" }} // 選択後の文字の大きさを設定
                >
                {sectChargeList && sectChargeList.map((charge) => (
                  <MenuItem key={charge.id} value={charge.chargeName}>{charge.chargeName}</MenuItem>
                ))}
              </Select>
              </Box>
            </Grid>
          </Grid>
        )}

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box2} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h6" style={{ fontWeight: 700 }}>
                補足事項
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Box bgcolor="primary.gray" color="primary.black" p={1} display="flex" alignItems="center" style={{ marginBottom: "2px" }}>
              <TextField
                fullWidth
                variant="outlined"
                value={additionalInfo}
                onChange={handleAdditionalInfoChange}
                placeholder="補足事項がございましたらご入力ください"
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  )
}
}
const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
}
export default EditDelRequestForm;
