import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import QRCode from "qrcode.react"
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import Slide from "@material-ui/core/Slide";

import { updateEgsTGarbageEdi, getEgsTGarbageEdiDetail } from '../../lib/api/egs_t_garbage_edi';
import { updateStatusRun } from '../../lib/api/edit_del_request';
import FormBody from './TransportDateForm';
// style
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function EgsTGarbageEdiEdit({ isOpen, doClose, ediId , dataList2,  sectId,    currentUser}) {

  sectId = currentUser.nickname;
  // console.log("dataList2",ediId,dataList2);
  // console.log("sectId",sectId);
//  if (sectId !== '1') {
//    alert('sectId=' + sectId);
//  }

  const [value, setValue] = useState({
    garbageTag: '',
    processingSeqno: '',
    manifestNo: '',
    arrangementNo: '',
    exhaustDate: '',
    exhaustId: '',
    exhaustCd: '',
    exhaustName: '',
    exhaustChargeId: '',
    exhaustChargeCd: '',
    exhaustChargeName: '',
    sect1TransportId: '',
    sect1TransportCd: '',
    sect1TransportName: '',
    sect1TransportChargeId: '',
    sect1TransportChargeCd: '',
    sect1TransportChargeName: '',
    sect1TransportMethodId: '',
    sect1TransportMethodCd: '',
    sect1TransportMethodName: '',
    sect1CarNo: '',
    destination1Id: '',
    destination1Cd: '',
    destination1Name: '',
    destination1ChargeId: '',
    destination1ChargeCd: '',
    destination1ChargeName: '',
    sect2TransportId: '',
    sect2TransportCd: '',
    sect2TransportName: '',
    sect2TransportChargeId: '',
    sect2TransportChargeCd: '',
    sect2TransportChargeName: '',
    sect2TransportMethodId: '',
    sect2TransportMethodCd: '',
    sect2TransportMethodName: '',
    sect2CarNo: '',
    destination2Id: '',
    destination2Cd: '',
    destination2Name: '',
    destination2ChargeId: '',
    destination2ChargeCd: '',
    destination2ChargeName: '',
    sect3TransportId: '',
    sect3TransportCd: '',
    sect3TransportName: '',
    sect3TransportChargeId: '',
    sect3TransportChargeCd: '',
    sect3TransportChargeName: '',
    sect3TransportMethodId: '',
    sect3TransportMethodCd: '',
    sect3TransportMethodName: '',
    sect3CarNo: '',
    destination3Id: '',
    destination3Cd: '',
    destination3Name: '',
    destination3ChargeId: '',
    destination3ChargeCd: '',
    destination3ChargeName: '',
    sect4TransportId: '',
    sect4TransportCd: '',
    sect4TransportName: '',
    sect4TransportChargeId: '',
    sect4TransportChargeCd: '',
    sect4TransportChargeName: '',
    sect4TransportMethodId: '',
    sect4TransportMethodCd: '',
    sect4TransportMethodName: '',
    sect4CarNo: '',
    destination4Id: '',
    destination4Cd: '',
    destination4Name: '',
    destination4ChargeId: '',
    destination4ChargeCd: '',
    destination4ChargeName: '',
    sect5TransportId: '',
    sect5TransportCd: '',
    sect5TransportName: '',
    sect5TransportChargeId: '',
    sect5TransportChargeCd: '',
    sect5TransportChargeName: '',
    sect5TransportMethodId: '',
    sect5TransportMethodCd: '',
    sect5TransportMethodName: '',
    sect5CarNo: '',
    destination5Id: '',
    destination5Cd: '',
    destination5Name: '',
    destination5ChargeId: '',
    destination5ChargeCd: '',
    destination5ChargeName: '',






    lastProcessingTraderId: '',
    lastProcessingTraderCd: '',
    lastProcessingTraderName: '',
    lastProcessingChargeId: '',
    lastProcessingChargeCd: '',
    lastProcessingTraderChargeName: '',



    wasteCd: '',
    wasteName: '',

    disposalMethodCd: '',
    disposalMethodName: '',






//    amount: '',
    amount1: '',
    amount2: '',
    amount3: '',
    amount4: '',
    amount5: '',
    packingAmount1: '',





    unitCd: '',
    unitName: '',

    packingCd: '',
    packingName: '',
//    packingAmount: '',
    packingAmount1: '',






    memo: '',
    memo1: '',
    memo2: '',
    memo3: '',
    memo4: '',
    memo5: '',
    toxicCd01: '',
    toxicCd02: '',
    toxicCd03: '',
    toxicCd04: '',
    toxicCd05: '',
    toxicCd06: '',
    toxicName01: '',
    toxicName02: '',
    toxicName03: '',
    toxicName04: '',
    toxicName05: '',
    toxicName06: '',

    status: '',
    sect1At: '',
    destination1At: '',
    sect2At: '',
    destination2At: '',
    sect3At: '',
    destination3At: '',
    sect4At: '',
    destination4At: '',
    sect5At: '',
    destination5At: '',
    middleStart: '',
    middleEnd: '',
    lastProcessingStart: '',
    lastProcessingEnd: '',

    ediSendFlg: '',






    reserveAuthorityCd: '',
    lastReportFlg: '',
    lastReportName: '',
    middleProcessingInfoFlg: '',


    lastProcessingCnt: '',

    destination1AtBk: '',
    destination2AtBk: '',
    destination3AtBk: '',
    destination4AtBk: '',
    destination5AtBk: '',

    sect1TransportChargeNameBk: '',
    sect2TransportChargeNameBk: '',
    sect3TransportChargeNameBk: '',
    sect4TransportChargeNameBk: '',
    sect5TransportChargeNameBk: '',

    editDelRequestFlg: '0',

  });


  const history = useHistory();

  useEffect(() => {
    handleGetData(ediId,dataList2);
  }, [ediId]);


  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);




  const handleGetData = async (id,dataList2) => {
    try {
      setOpen(!open);
      const res = await getEgsTGarbageEdiDetail(id);
      // console.log(res.data);
      // console.log(ediId,id,dataList2);
      setValue({
//        garbageTag: res.data.garbageTag,
//        layoutNo: 'D01',    // res.data.layoutNo,
//        layoutNo: res.data.layoutNo,

        garbageTag: res.data.garbageTag,
        processingSeqno: res.data.processingSeqno,
        manifestNo: res.data.manifestNo,
        arrangementNo: res.data.arrangementNo,
        exhaustDate: res.data.exhaustDate,
        exhaustId: res.data.exhaustId,
        exhaustCd: res.data.exhaustCd,
        exhaustName: res.data.exhaustName,
        exhaustChargeId: res.data.exhaustChargeId,
        exhaustChargeCd: res.data.exhaustChargeCd,
        exhaustChargeName: res.data.exhaustChargeName,
        sect1TransportId: res.data.sect1TransportId,
        sect1TransportCd: res.data.sect1TransportCd,
        sect1TransportName: res.data.sect1TransportName,
        sect1TransportChargeId: res.data.sect1TransportChargeId,
        sect1TransportChargeCd: res.data.sect1TransportChargeCd,
        sect1TransportChargeName: res.data.sect1TransportChargeName,
        sect1TransportChargeNameBk: res.data.sect1TransportChargeName,
        sect1TransportMethodId: res.data.sect1TransportMethodId,
        sect1TransportMethodCd: res.data.sect1TransportMethodCd,
        sect1TransportMethodName: res.data.sect1TransportMethodName,
        sect1CarNo: res.data.sect1CarNo,
        destination1Id: res.data.destination1Id,
        destination1Cd: res.data.destination1Cd,
        destination1Name: res.data.destination1Name,
        destination1ChargeId: res.data.destination1ChargeId,
        destination1ChargeCd: res.data.destination1ChargeCd,
        destination1ChargeName: res.data.destination1ChargeName,
        sect2TransportId: res.data.sect2TransportId,
        sect2TransportCd: res.data.sect2TransportCd,
        sect2TransportName: res.data.sect2TransportName,
        sect2TransportChargeId: res.data.sect2TransportChargeId,
        sect2TransportChargeCd: res.data.sect2TransportChargeCd,
        sect2TransportChargeName: res.data.sect2TransportChargeName,
        sect2TransportChargeNameBk: res.data.sect2TransportChargeName,
        sect2TransportMethodId: res.data.sect2TransportMethodId,
        sect2TransportMethodCd: res.data.sect2TransportMethodCd,
        sect2TransportMethodName: res.data.sect2TransportMethodName,
        sect2CarNo: res.data.sect2CarNo,
        destination2Id: res.data.destination2Id,
        destination2Cd: res.data.destination2Cd,
        destination2Name: res.data.destination2Name,
        destination2ChargeId: res.data.destination2ChargeId,
        destination2ChargeCd: res.data.destination2ChargeCd,
        destination2ChargeName: res.data.destination2ChargeName,
        sect3TransportId: res.data.sect3TransportId,
        sect3TransportCd: res.data.sect3TransportCd,
        sect3TransportName: res.data.sect3TransportName,
        sect3TransportChargeId: res.data.sect3TransportChargeId,
        sect3TransportChargeCd: res.data.sect3TransportChargeCd,
        sect3TransportChargeName: res.data.sect3TransportChargeName,
        sect3TransportChargeNameBk: res.data.sect3TransportChargeName,
        sect3TransportMethodId: res.data.sect3TransportMethodId,
        sect3TransportMethodCd: res.data.sect3TransportMethodCd,
        sect3TransportMethodName: res.data.sect3TransportMethodName,
        sect3CarNo: res.data.sect3CarNo,
        destination3Id: res.data.destination3Id,
        destination3Cd: res.data.destination3Cd,
        destination3Name: res.data.destination3Name,
        destination3ChargeId: res.data.destination3ChargeId,
        destination3ChargeCd: res.data.destination3ChargeCd,
        destination3ChargeName: res.data.destination3ChargeName,
        sect4TransportId: res.data.sect4TransportId,
        sect4TransportCd: res.data.sect4TransportCd,
        sect4TransportName: res.data.sect4TransportName,
        sect4TransportChargeId: res.data.sect4TransportChargeId,
        sect4TransportChargeCd: res.data.sect4TransportChargeCd,
        sect4TransportChargeName: res.data.sect4TransportChargeName,
        sect4TransportChargeNameBk: res.data.sect4TransportChargeName,
        sect4TransportMethodId: res.data.sect4TransportMethodId,
        sect4TransportMethodCd: res.data.sect4TransportMethodCd,
        sect4TransportMethodName: res.data.sect4TransportMethodName,
        sect4CarNo: res.data.sect4CarNo,
        destination4Id: res.data.destination4Id,
        destination4Cd: res.data.destination4Cd,
        destination4Name: res.data.destination4Name,
        destination4ChargeId: res.data.destination4ChargeId,
        destination4ChargeCd: res.data.destination4ChargeCd,
        destination4ChargeName: res.data.destination4ChargeName,
        sect5TransportId: res.data.sect5TransportId,
        sect5TransportCd: res.data.sect5TransportCd,
        sect5TransportName: res.data.sect5TransportName,
        sect5TransportChargeId: res.data.sect5TransportChargeId,
        sect5TransportChargeCd: res.data.sect5TransportChargeCd,
        sect5TransportChargeName: res.data.sect5TransportChargeName,
        sect5TransportChargeNameBk: res.data.sect5TransportChargeName,
        sect5TransportMethodId: res.data.sect5TransportMethodId,
        sect5TransportMethodCd: res.data.sect5TransportMethodCd,
        sect5TransportMethodName: res.data.sect5TransportMethodName,
        sect5CarNo: res.data.sect5CarNo,
        destination5Id: res.data.destination5Id,
        destination5Cd: res.data.destination5Cd,
        destination5Name: res.data.destination5Name,
        destination5ChargeId: res.data.destination5ChargeId,
        destination5ChargeCd: res.data.destination5ChargeCd,
        destination5ChargeName: res.data.destination5ChargeName,






        lastProcessingTraderId: res.data.lastProcessingTraderId,
        lastProcessingTraderCd: res.data.lastProcessingTraderCd,
        lastProcessingTraderName: res.data.lastProcessingTraderName,
        lastProcessingChargeId: res.data.lastProcessingChargeId,
        lastProcessingChargeCd: res.data.lastProcessingChargeCd,
        lastProcessingTraderChargeName: res.data.lastProcessingTraderChargeName,



        wasteCd: res.data.wasteCd,
        wasteName: res.data.wasteName,

        disposalMethodCd: res.data.disposalMethodCd,
        disposalMethodName: res.data.disposalMethodName,






//        amount: res.data.amount,
        amount1: res.data.amount1,
        amount2: res.data.amount2,
        amount3: res.data.amount3,
        amount4: res.data.amount4,
        amount5: res.data.amount5,
        packingAmount1: res.data.packingAmount1,

        unitCd: res.data.unitCd,
        unitName: res.data.unitName,

        packingCd: res.data.packingCd,
        packingName: res.data.packingName,
//        packingAmount: res.data.packingAmount,
        packingAmount1: res.data.packingAmount1,






        memo: res.data.memo,
        memo1: res.data.memo1,
        memo2: res.data.memo2,
        memo3: res.data.memo3,
        memo4: res.data.memo4,
        memo5: res.data.memo5,
        toxicCd01: res.data.toxicCd01,
        toxicCd02: res.data.toxicCd02,
        toxicCd03: res.data.toxicCd03,
        toxicCd04: res.data.toxicCd04,
        toxicCd05: res.data.toxicCd05,
        toxicCd06: res.data.toxicCd06,
        toxicName01: res.data.toxicName01,
        toxicName02: res.data.toxicName02,
        toxicName03: res.data.toxicName03,
        toxicName04: res.data.toxicName04,
        toxicName05: res.data.toxicName05,
        toxicName06: res.data.toxicName06,

        status: res.data.status,
        sect1At: res.data.sect1At,
        destination1At: res.data.destination1At,
        destination1AtBk: res.data.destination1At,
        sect2At: res.data.sect2At,
        destination2At: res.data.destination2At,
        destination2AtBk: res.data.destination2At,
        sect3At: res.data.sect3At,
        destination3At: res.data.destination3At,
        destination3AtBk: res.data.destination3At,
        sect4At: res.data.sect4At,
        destination4At: res.data.destination4At,
        destination4AtBk: res.data.destination4At,
        sect5At: res.data.sect5At,
        destination5At: res.data.destination5At,
        destination5AtBk: res.data.destination5At,
        middleStart: res.data.middleStart,
        middleEnd: res.data.middleEnd,
        lastProcessingStart: res.data.lastProcessingStart,
        lastProcessingEnd: res.data.lastProcessingEnd,

        ediSendFlg: res.data.ediSendFlg,






        reserveAuthorityCd: res.data.reserveAuthorityCd,
        lastReportFlg: res.data.lastReportFlg,
        middleProcessingInfoFlg: res.data.middleProcessingInfoFlg,


        lastProcessingCnt: res.data.lastProcessingCnt,

      // 0901-E


      garbageTag: dataList2.garbageTag,
      processingSeqno: dataList2.processingSeqno,
      manifestNo: dataList2.manifestNo,
      arrangementNo: dataList2.arrangementNo,
      exhaustDate: dataList2.exhaustDate,
      exhaustId: dataList2.exhaustId,
      exhaustCd: dataList2.exhaustCd,
      exhaustName: dataList2.exhaustName,
      exhaustChargeId: dataList2.exhaustChargeId,
      exhaustChargeCd: dataList2.exhaustChargeCd,
      exhaustChargeName: dataList2.exhaustChargeName,
      sect1TransportId: dataList2.sect1TransportId,
      sect1TransportCd: dataList2.sect1TransportCd,
      sect1TransportName: dataList2.sect1TransportName,
      sect1TransportChargeId: dataList2.sect1TransportChargeId,
      sect1TransportChargeCd: dataList2.sect1TransportChargeCd,
      sect1TransportChargeName: dataList2.sect1TransportChargeName,
      sect1TransportMethodId: dataList2.sect1TransportMethodId,
      sect1TransportMethodCd: dataList2.sect1TransportMethodCd,
      sect1TransportMethodName: dataList2.sect1TransportMethodName,
      sect1CarNo: dataList2.sect1CarNo,
      destination1Id: dataList2.destination1Id,
      destination1Cd: dataList2.destination1Cd,
      destination1Name: dataList2.destination1Name,
      destination1ChargeId: dataList2.destination1ChargeId,
      destination1ChargeCd: dataList2.destination1ChargeCd,
      destination1ChargeName: dataList2.destination1ChargeName,
      sect2TransportId: dataList2.sect2TransportId,
      sect2TransportCd: dataList2.sect2TransportCd,
      sect2TransportName: dataList2.sect2TransportName,
      sect2TransportChargeId: dataList2.sect2TransportChargeId,
      sect2TransportChargeCd: dataList2.sect2TransportChargeCd,
      sect2TransportChargeName: dataList2.sect2TransportChargeName,
      sect2TransportMethodId: dataList2.sect2TransportMethodId,
      sect2TransportMethodCd: dataList2.sect2TransportMethodCd,
      sect2TransportMethodName: dataList2.sect2TransportMethodName,
      sect2CarNo: dataList2.sect2CarNo,
      destination2Id: dataList2.destination2Id,
      destination2Cd: dataList2.destination2Cd,
      destination2Name: dataList2.destination2Name,
      destination2ChargeId: dataList2.destination2ChargeId,
      destination2ChargeCd: dataList2.destination2ChargeCd,
      destination2ChargeName: dataList2.destination2ChargeName,
      sect3TransportId: dataList2.sect3TransportId,
      sect3TransportCd: dataList2.sect3TransportCd,
      sect3TransportName: dataList2.sect3TransportName,
      sect3TransportChargeId: dataList2.sect3TransportChargeId,
      sect3TransportChargeCd: dataList2.sect3TransportChargeCd,
      sect3TransportChargeName: dataList2.sect3TransportChargeName,
      sect3TransportMethodId: dataList2.sect3TransportMethodId,
      sect3TransportMethodCd: dataList2.sect3TransportMethodCd,
      sect3TransportMethodName: dataList2.sect3TransportMethodName,
      sect3CarNo: dataList2.sect3CarNo,
      destination3Id: dataList2.destination3Id,
      destination3Cd: dataList2.destination3Cd,
      destination3Name: dataList2.destination3Name,
      destination3ChargeId: dataList2.destination3ChargeId,
      destination3ChargeCd: dataList2.destination3ChargeCd,
      destination3ChargeName: dataList2.destination3ChargeName,
      sect4TransportId: dataList2.sect4TransportId,
      sect4TransportCd: dataList2.sect4TransportCd,
      sect4TransportName: dataList2.sect4TransportName,
      sect4TransportChargeId: dataList2.sect4TransportChargeId,
      sect4TransportChargeCd: dataList2.sect4TransportChargeCd,
      sect4TransportChargeName: dataList2.sect4TransportChargeName,
      sect4TransportMethodId: dataList2.sect4TransportMethodId,
      sect4TransportMethodCd: dataList2.sect4TransportMethodCd,
      sect4TransportMethodName: dataList2.sect4TransportMethodName,
      sect4CarNo: dataList2.sect4CarNo,
      destination4Id: dataList2.destination4Id,
      destination4Cd: dataList2.destination4Cd,
      destination4Name: dataList2.destination4Name,
      destination4ChargeId: dataList2.destination4ChargeId,
      destination4ChargeCd: dataList2.destination4ChargeCd,
      destination4ChargeName: dataList2.destination4ChargeName,
      sect5TransportId: dataList2.sect5TransportId,
      sect5TransportCd: dataList2.sect5TransportCd,
      sect5TransportName: dataList2.sect5TransportName,
      sect5TransportChargeId: dataList2.sect5TransportChargeId,
      sect5TransportChargeCd: dataList2.sect5TransportChargeCd,
      sect5TransportChargeName: dataList2.sect5TransportChargeName,
      sect5TransportMethodId: dataList2.sect5TransportMethodId,
      sect5TransportMethodCd: dataList2.sect5TransportMethodCd,
      sect5TransportMethodName: dataList2.sect5TransportMethodName,
      sect5CarNo: dataList2.sect5CarNo,
      destination5Id: dataList2.destination5Id,
      destination5Cd: dataList2.destination5Cd,
      destination5Name: dataList2.destination5Name,
      destination5ChargeId: dataList2.destination5ChargeId,
      destination5ChargeCd: dataList2.destination5ChargeCd,
      destination5ChargeName: dataList2.destination5ChargeName,






      lastProcessingTraderId: dataList2.lastProcessingTraderId,
      lastProcessingTraderCd: dataList2.lastProcessingTraderCd,
      lastProcessingTraderName: dataList2.lastProcessingTraderName,
      lastProcessingChargeId: dataList2.lastProcessingChargeId,
      lastProcessingChargeCd: dataList2.lastProcessingChargeCd,
      lastProcessingTraderChargeName: dataList2.lastProcessingTraderChargeName,



      wasteCd: dataList2.wasteCd,
//      wasteName: dataList2.wasteName,
      wasteKindName: dataList2.wasteKindName,
      wasteclassName: dataList2.wasteclassName,

      disposalMethodCd: dataList2.disposalMethodCd,
      disposalMethodName: dataList2.disposalMethodName,






//        amount: dataList2.amount,
      amount1: dataList2.amount1,
      amount2: dataList2.amount2,
      amount3: dataList2.amount3,
      amount4: dataList2.amount4,
      amount5: dataList2.amount5,
      packingAmount1: dataList2.packingAmount1,

      unitCd: dataList2.unitCd,
      unitName: dataList2.unitName,

      packingCd: dataList2.packingCd,
      packingName: dataList2.packingName,
//        packingAmount: dataList2.packingAmount,
      packingAmount1: dataList2.packingAmount1,






      memo: dataList2.memo,
      memo1: dataList2.memo1,
      memo2: dataList2.memo2,
      memo3: dataList2.memo3,
      memo4: dataList2.memo4,
      memo5: dataList2.memo5,
      toxicCd01: dataList2.toxicCd01,
      toxicCd02: dataList2.toxicCd02,
      toxicCd03: dataList2.toxicCd03,
      toxicCd04: dataList2.toxicCd04,
      toxicCd05: dataList2.toxicCd05,
      toxicCd06: dataList2.toxicCd06,
      toxicName01: dataList2.toxicName01,
      toxicName02: dataList2.toxicName02,
      toxicName03: dataList2.toxicName03,
      toxicName04: dataList2.toxicName04,
      toxicName05: dataList2.toxicName05,
      toxicName06: dataList2.toxicName06,

      status: dataList2.status,
      sect1At: dataList2.sect1At,
      destination1At: dataList2.destination1At,
      sect2At: dataList2.sect2At,
      destination2At: dataList2.destination2At,
      sect3At: dataList2.sect3At,
      destination3At: dataList2.destination3At,
      sect4At: dataList2.sect4At,
      destination4At: dataList2.destination4At,
      sect5At: dataList2.sect5At,
      destination5At: dataList2.destination5At,
      middleStart: dataList2.middleStart,
      middleEnd: dataList2.middleEnd,
      lastProcessingStart: dataList2.lastProcessingStart,
      lastProcessingEnd: dataList2.lastProcessingEnd,

      ediSendFlg: dataList2.ediSendFlg,






      reserveAuthorityCd: dataList2.reserveAuthorityCd,
      lastReportFlg: dataList2.lastReportFlg,
      lastReportName: dataList2.lastReportName,
      middleProcessingInfoFlg: dataList2.middleProcessingInfoFlg,


      lastProcessingCnt: dataList2.lastProcessingCnt,




      // 0901-E
          });
    } catch (e) {
      console.log(e);
    } finally {
      setOpen(false);
    }
  };

  const handleChange = (e) => {
  
    if (e.target.name == 'sect1TransportChargeId') {
      value.sect1TransportChargeCd = e.currentTarget.innerText.split(':')[0]  // 20240501
      value.sect1TransportChargeName = e.currentTarget.innerText.split(':')[1]  // 20240501
    }
    if (e.target.name == 'sect2TransportChargeId') {
      value.sect2TransportChargeCd = e.currentTarget.innerText.split(':')[0]  // 20240501
      value.sect2TransportChargeName = e.currentTarget.innerText.split(':')[1]  // 20240501
    }
    if (e.target.name == 'sect3TransportChargeId') {
      value.sect3TransportChargeCd = e.currentTarget.innerText.split(':')[0]  // 20240501
      value.sect3TransportChargeName = e.currentTarget.innerText.split(':')[1]  // 20240501
    }
    if (e.target.name == 'sect4TransportChargeId') {
      value.sect4TransportChargeCd = e.currentTarget.innerText.split(':')[0]  // 20240501
      value.sect4TransportChargeName = e.currentTarget.innerText.split(':')[1]  // 20240501
    }
    if (e.target.name == 'sect5TransportChargeId') {
      value.sect5TransportChargeCd = e.currentTarget.innerText.split(':')[0]  // 20240501
      value.sect5TransportChargeName = e.currentTarget.innerText.split(':')[1]  // 20240501
    }
  
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  // console.log('setvalue',value);

  const handleClose = () => {
    doClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // 追加
//    const params = generateParams1();
  {/*}
    try {
      const res = await updateEgsTGarbageEdi(ediId, params);
      // console.log(res);
      doClose();
      // egs_m_reserve_sets edi_d01
    } catch (e) {
      console.log(e);
    }
*/}
    if (window.confirm(' 廃棄物情報「' + value.manifestNo + '」の区間'+ sectId +'の運搬終了報告修正を行います。よろしいですか？')) {
      try {
        setOpen(!open);

//        const res = await updateEgsTGarbageEdi(ediId, params);
        if (sectId == '1') {
          const params = generateParams11();
          const res = await updateEgsTGarbageEdi(ediId, params);
          // console.log(res);
        }else{
        if (sectId == '2') {
          const params = generateParams12();
          const res = await updateEgsTGarbageEdi(ediId, params);
          // console.log(res);
        }else{
        if (sectId == '3') {
          const params = generateParams13();
          const res = await updateEgsTGarbageEdi(ediId, params);
          // console.log(res);
        }else{
        if (sectId == '4') {
          const params = generateParams14();
          const res = await updateEgsTGarbageEdi(ediId, params);
          // console.log(res);
        }else{
          const params = generateParams15();
          const res = await updateEgsTGarbageEdi(ediId, params);
          // console.log(res);
        }}}}
       if (value.editDelRequestFlg == '1'){
        const response = await updateStatusRun(value.manifestNo);
        // console.log('Status updated:', response.data);
        // history.push('/egs_m_trader_adm');
        // window.location.reload();
        window.alert(' 廃棄物情報「' + value.manifestNo + '」の区間'+ sectId +'の運搬終了報告修正を受付ました。')
       }else{ 
        window.alert(' マニフェスト修正依頼なし。　廃棄物情報「' + value.manifestNo + '」の区間'+ sectId +'の運搬終了報告修正を受付ました。')
       }

       setOpen(false);
       doClose();
       
     } catch (e) {
        console.log(e);
      }
    }

  };

  const handleCancel = async (e) => {
    e.preventDefault();
    // 追加
//    const params = generateParams2();
  {/*}
    try {
      const res = await updateEgsTGarbageEdi(ediId, params);
      // console.log(res);
      doClose();
      // egs_m_reserve_sets edi_d01
    } catch (e) {
      console.log(e);
    }
*/}
    if (window.confirm(' 廃棄物情報「' + value.manifestNo + '」の区間'+ sectId +'の運搬終了報告を取消します。よろしいですか？')) {
      try {
       setOpen(!open);

//        const res = await updateEgsTGarbageEdi(ediId, params);
        if (sectId == '1') {
          const params = generateParams21();
          const res = await updateEgsTGarbageEdi(ediId, params);
          // console.log(res);
        }else{
        if (sectId == '2') {
          const params = generateParams22();
          const res = await updateEgsTGarbageEdi(ediId, params);
          // console.log(res);
        }else{
        if (sectId == '3') {
          const params = generateParams23();
          const res = await updateEgsTGarbageEdi(ediId, params);
          // console.log(res);
        }else{
        if (sectId == '4') {
          const params = generateParams24();
          const res = await updateEgsTGarbageEdi(ediId, params);
          // console.log(res);
        }else{
          const params = generateParams25();
          const res = await updateEgsTGarbageEdi(ediId, params);
          // console.log(res);
        }}}}
        // history.push('/egs_m_trader_adm');
        // window.location.reload();
        setOpen(false);
        doClose();
        window.alert('廃棄物情報「' + value.manifestNo + '」の区間'+ sectId +'の運搬終了報告取消を受付ました。')
      } catch (e) {
        console.log(e);
      }
    }

  };


  // パラメータのオブジェクト構造を加工
  const generateParams11 = () => {
    const params = {
      ediSendFlg: '14',    // EDI送信状態フラグ
      destination1At: value.destination1At,
      sect1TransportChargeId: value.sect1TransportChargeId,
      sect1TransportChargeCd: value.sect1TransportChargeCd,      // 20240501
      sect1TransportChargeName: value.sect1TransportChargeName,  // 20240501
      middleProcessingInfoFlg: value.ediSendFlg,     // 変更前EDI送信状態フラグを保存 20230625uda
    };
    return params;
  };

// 更新用パラメタ
  // パラメータのオブジェクト構造を加工
  const generateParams12 = () => {
    const params = {
      ediSendFlg: '24',    // EDI送信状態フラグ
      destination2At: value.destination2At,
      sect2TransportChargeId: value.sect2TransportChargeId,
      sect2TransportChargeCd: value.sect2TransportChargeCd,      // 20240501
      sect2TransportChargeName: value.sect2TransportChargeName,  // 20240501
      middleProcessingInfoFlg: value.ediSendFlg,     // 変更前EDI送信状態フラグを保存 20230625uda
    };
    return params;
  };

  // パラメータのオブジェクト構造を加工
  const generateParams13 = () => {
    const params = {
      ediSendFlg: '34',    // EDI送信状態フラグ
      destination3At: value.destination3At,
      sect3TransportChargeId: value.sect3TransportChargeId,
      sect3TransportChargeCd: value.sect3TransportChargeCd,      // 20240501
      sect3TransportChargeName: value.sect3TransportChargeName,  // 20240501
      middleProcessingInfoFlg: value.ediSendFlg,     // 変更前EDI送信状態フラグを保存 20230625uda
    };
    return params;
  };

  // パラメータのオブジェクト構造を加工
  const generateParams14 = () => {
    const params = {
      ediSendFlg: '44',    // EDI送信状態フラグ
      destination4At: value.destination4At,
      sect4TransportChargeId: value.sect4TransportChargeId,
      sect4TransportChargeCd: value.sect4TransportChargeCd,      // 20240501
      sect4TransportChargeName: value.sect4TransportChargeName,  // 20240501
      middleProcessingInfoFlg: value.ediSendFlg,     // 変更前EDI送信状態フラグを保存 20230625uda
    };
    return params;
  };

  // パラメータのオブジェクト構造を加工
  const generateParams15 = () => {
    const params = {
      ediSendFlg: '54',    // EDI送信状態フラグ
      destination5At: value.destination5At,
      sect5TransportChargeId: value.sect5TransportChargeId,
      sect5TransportChargeCd: value.sect5TransportChargeCd,      // 20240501
      sect5TransportChargeName: value.sect5TransportChargeName,  // 20240501
      middleProcessingInfoFlg: value.ediSendFlg,     // 変更前EDI送信状態フラグを保存 20230625uda
    };
    return params;
  };

// 取消用パラメタ
  // パラメータのオブジェクト構造を加工
  const generateParams21 = () => {
    const params = {
      status: '10',    // ステータス
      ediSendFlg: '17',    // EDI送信状態フラグ
      destination1At: '',
      sect1TransportChargeId: value.sect1TransportChargeId,
      sect1TransportChargeCd: value.sect1TransportChargeCd,      // 20240501
      sect1TransportChargeName: value.sect1TransportChargeName,  // 20240501
      middleProcessingInfoFlg: value.ediSendFlg,     // 変更前EDI送信状態フラグを保存 20230625uda
    };
    return params;
  };

  // パラメータのオブジェクト構造を加工
  const generateParams22 = () => {
    const params = {
      status: '20',    // ステータス
      ediSendFlg: '27',    // EDI送信状態フラグ
      destination2At: '',
      sect2TransportChargeId: value.sect2TransportChargeId,
      sect2TransportChargeCd: value.sect2TransportChargeCd,      // 20240501
      sect2TransportChargeName: value.sect2TransportChargeName,  // 20240501
      middleProcessingInfoFlg: value.ediSendFlg,     // 変更前EDI送信状態フラグを保存 20230625uda
    };
    return params;
  };

  // パラメータのオブジェクト構造を加工
  const generateParams23 = () => {
    const params = {
      status: '30',    // ステータス
      ediSendFlg: '37',    // EDI送信状態フラグ
      destination3At: '',
      sect3TransportChargeId: value.sect3TransportChargeId,
      sect3TransportChargeCd: value.sect3TransportChargeCd,      // 20240501
      sect3TransportChargeName: value.sect3TransportChargeName,  // 20240501
      middleProcessingInfoFlg: value.ediSendFlg,     // 変更前EDI送信状態フラグを保存 20230625uda
    };
    return params;
  };

  // パラメータのオブジェクト構造を加工
  const generateParams24 = () => {
    const params = {
      status: '40',    // ステータス
      ediSendFlg: '47',    // EDI送信状態フラグ
      destination4At: '',
      sect4TransportChargeId: value.sect4TransportChargeId,
      sect4TransportChargeCd: value.sect4TransportChargeCd,      // 20240501
      sect4TransportChargeName: value.sect4TransportChargeName,  // 20240501
      middleProcessingInfoFlg: value.ediSendFlg,     // 変更前EDI送信状態フラグを保存 20230625uda
    };
    return params;
  };

  // パラメータのオブジェクト構造を加工
  const generateParams25 = () => {
    const params = {
      status: '50',    // ステータス
      ediSendFlg: '57',    // EDI送信状態フラグ
      destination5At: '',
      sect5TransportChargeId: value.sect5TransportChargeId,
      sect5TransportChargeCd: value.sect5TransportChargeCd,      // 20240501
      sect5TransportChargeName: value.sect5TransportChargeName,  // 20240501
      middleProcessingInfoFlg: value.ediSendFlg,     // 変更前EDI送信状態フラグを保存 20230625uda
    };
    return params;
  };

  if (sectId == '1') {
    return (
      <Dialog
        open={isOpen}
        onClose={() => {}}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title"
        maxWidth={"xl"}
        PaperComponent={PaperComponent}
      >
        <DialogTitle id="form-dialog-title">
          <h2>区間１運搬終了報告修正</h2>
        </DialogTitle>
        <DialogContent>
          <FormBody
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            handleClose={handleClose}
            value={value}
            currentUser={currentUser}
            buttonType1='更新'
            buttonType2='取消' />

          <Backdrop className={classes.backdrop} open={open} >
            <CircularProgress color="inherit" />
          </Backdrop>
        </DialogContent>
      </Dialog>
    );
    }else{
  if (sectId == '2') {
    return (
      <Dialog
        open={isOpen}
        onClose={() => {}}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title"
        maxWidth={"xl"}
        PaperComponent={PaperComponent}
      >
        <DialogTitle id="form-dialog-title">
          <h2>区間２運搬終了報告修正</h2>
        </DialogTitle>
        <DialogContent>
          <FormBody
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            handleClose={handleClose}
            value={value}
            currentUser={currentUser}
            buttonType1='更新'
            buttonType2='取消' />

          <Backdrop className={classes.backdrop} open={open} >
            <CircularProgress color="inherit" />
          </Backdrop>
        </DialogContent>
      </Dialog>
    );
    }else{
  if (sectId == '3') {
    return (
      <Dialog
        open={isOpen}
        onClose={() => {}}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title"
        maxWidth={"xl"}
        PaperComponent={PaperComponent}
      >
        <DialogTitle id="form-dialog-title">
          <h2>区間３運搬終了報告修正</h2>
        </DialogTitle>
        <DialogContent>
          <FormBody
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            handleClose={handleClose}
            value={value}
            currentUser={currentUser}
            buttonType1='更新'
            buttonType2='取消' />

          <Backdrop className={classes.backdrop} open={open} >
            <CircularProgress color="inherit" />
          </Backdrop>
        </DialogContent>
      </Dialog>
    );
    }else{
  if (sectId == '4') {
    return (
      <Dialog
        open={isOpen}
        onClose={() => {}}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title"
        maxWidth={"xl"}
        PaperComponent={PaperComponent}
      >
        <DialogTitle id="form-dialog-title">
          <h2>区間４運搬終了報告修正</h2>
        </DialogTitle>
        <DialogContent>
          <FormBody
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            handleClose={handleClose}
            value={value}
            currentUser={currentUser}
            buttonType1='更新'
            buttonType2='取消' />

          <Backdrop className={classes.backdrop} open={open} >
            <CircularProgress color="inherit" />
          </Backdrop>
        </DialogContent>
      </Dialog>
    );
    }else{
      return (
        <Dialog
          open={isOpen}
          onClose={() => {}}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="form-dialog-title"
          maxWidth={"xl"}
          PaperComponent={PaperComponent}
        >
          <DialogTitle id="form-dialog-title">
            <h2>区間５運搬終了報告修正</h2>
          </DialogTitle>
          <DialogContent>
            <FormBody
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              handleCancel={handleCancel}
              handleClose={handleClose}
              value={value}
              currentUser={currentUser}
              buttonType1='更新'
              buttonType2='取消' />

            <Backdrop className={classes.backdrop} open={open} >
              <CircularProgress color="inherit" />
            </Backdrop>
          </DialogContent>
        </Dialog>
      );
      }
  }
  }
  }
}
export default EgsTGarbageEdiEdit;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
