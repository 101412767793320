import React, { useEffect, useState, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { BrowserView, MobileView } from "react-device-detect"
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
//import SpaceRow from '../commons/SpaceRow';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Backdrop,
  CircularProgress,
  Grid,
  Typography
} from '@material-ui/core';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridColumnMenu,
  jaJP
} from '@mui/x-data-grid-pro'

import './app.css';
import { prefectureName } from '../../constants'
import SpaceRow from '../commons/SpaceRow';
import AdmSignInt from '../users/AdmSignInt'
import { getEgsMCompanyDetail } from '../../lib/api/egs_m_company';

import EgsMTraderEdit from "../egs_m_traders/EgsMTraderEdit";
//import EgsMTraderNew from "../egs_m_traders/EgsMTraderNew";    // 新規作成
import EgsMTraderAdd from "../egs_m_traders/EgsMTraderAdd";    // 新規作成（複写）
import EgsMTraderListTableForm from "../egs_m_traders/EgsMTraderListTableForm";
import EgsMCompanyListTableForm from "../egs_m_traders/EgsMCompanyListTableForm";
import EgsMChargeNew from "../egs_m_traders/EgsMChargeNew";    // 担当者新規作成
import { getTraderAdmOperation  } from '../../lib/api/trader_adm';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      componentsProps={{
        // Swap positions of filter and sort items
        columnMenuFilterItem: {
          displayOrder: 0, // Previously `10`
        },
        // columnMenuSortItem: {
        //   displayOrder: 10, // Previously `0`
        // },
      }}
    />
  );
}

// const useStyles = makeStyles({
//   table: {
//     minWidth: 650,
//   },
//   fontWeight: {
//     fontWeight: 900,
//   },
// });
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 100,  // 650
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',    // 150ch
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "85vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#EEE",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

// const grid1 = React.createRef();
const rowDetailRef = React.createRef();
const grid = React.createRef();

function EgsMTraderListTable(props) {
  const classes = useStyles();

  const [open2, setOpen2] = React.useState(false);

  const { dataList, handleDelete, currentUser } = props;
  // console.log('currentUser',currentUser);

  const [dataList2, setDataList2] = useState({});
  const [detailOpen, setDetailOpen] = useState(false);
//  const [creOpen, setCreOpen] = useState(false);    // 新規作成
  const [addOpen, setAddOpen] = useState(false);    // 新規作成（複写）
  const [chargeNewOpen, setChargeNewOpen] = useState(false);  // 担当者新規作成

  const [dataCom, setDataCom] = useState([]);
  const [open, setOpen] = React.useState(false);

  const [filteredDataList, setFilteredDataList] = React.useState([]);
  const [filter, setFilter] = React.useState({
//    exhaustDateStart: '',
//    exhaustDateEnd: '',
//    manifestNo: '',
//    wasteKindName: '',
    traderName: '',
//    statusName: '',
    traderCd: '',
    sect1TransportName: '',
    jwnetNo: '',
  })
  const query = useParams();
  const pdfRef = React.createRef();

  var param_item = '';

  useEffect(() => {
    setFilteredDataList(dataList);
    // console.log('setFilteredDataList', filteredDataList);
  }, [dataList, currentUser])

  const startFilter = () => {
/*    
    console.log('dataList', dataList);
    console.log('currentUser', currentUser);

    console.log('filter', filter);
    if (filter.exhaustDateStart == ''){
      filter.exhaustDateStart = dayjs().subtract(60,'d').format('YYYY-MM-DD');
    }
    if (filter.exhaustDateEnd == ''){
      filter.exhaustDateEnd = dayjs().format('YYYY-MM-DD');
    }
*/
//    param_item = currentUser.traderCd + "_"+currentUser.traderKbn + "_"+filter.exhaustDateStart+"_"+filter.exhaustDateEnd+"_"+filter.manifestNo+"_"+filter.statusName+"_"+filter.traderCd+"_"+filter.traderName+"_"+filter.sect1TransportName;
    param_item = currentUser.traderCd + "_"+currentUser.traderKbn + "_" + filter.traderCd+"_"+filter.traderName+"_"+filter.jwnetNo+"_";
    console.log("param_item",param_item)
    handleGetList(param_item);
  }

  const handleGetList = async () => {
    setOpen2(!open2);
    try {
      const  res = await getTraderAdmOperation(param_item);
      setFilteredDataList(res.data);
    } catch (e) {
      console.log(e);
    }
    setOpen2(false);
  };


  let resCom;
///  let wkEgsMCompanyId;

  const handleRowClick = (param, event) => {
    // // console.log("param.row",param.row)
    setDataList2(param.row);
    // // console.log("dataList2",dataList2);

///    alert('1:' + wkEgsMCompanyId + ':' + param.row.egsMCompanyId + ':' + param.row.companyName);

///    wkEgsMCompanyId = param.row.egsMCompanyId;
       dataList2.egsMCompanyId = param.row.egsMCompanyId;

///    alert('2:' + wkEgsMCompanyId + ':' + param.row.egsMCompanyId+ ':' + param.row.companyName);

    handleGetCom();

  };

  const handleGetCom = async () => {
    try {
      setOpen(!open);
       resCom = await getEgsMCompanyDetail(dataList2.egsMCompanyId);
///       resCom = await getEgsMCompanyDetail(wkEgsMCompanyId);
       setDataCom(resCom.data);
       // console.log('resCom1',resCom.data)
//       // console.log('dataCom1',dataCom)

      dataCom.companyName = resCom.data.companyName;

      setOpen(false);
     } catch (e) {
       console.log(e);
       console.log(e.response);
     }
  };

//  // console.log('resCom2',resCom.data)
  // console.log('dataCom2',dataCom)

  const handleEditOpen = () => {
    setDetailOpen(true);
  };
//  const handleCreOpen = () => {
//    setCreOpen(true);
//  };
  const handleAddOpen = () => {
    setAddOpen(true);
  };

  const handleChargeNewOpen = () => {
    setChargeNewOpen(true);
  }

  const getRowClassName = (params) => {
    if (params.row.effectiveflgName === '無効') {
      return 'disable-row';
    } else if (params.row.effectiveflgName !== '有効') {
      return 'non-enable-row';
    } else {
      return '';
    }
  }
   
  const cols = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80
    },
    {
      field: 'egsMCompanyId',
      headerName: '企業ID',
      hide: true,
      width: 80
    },
    {
      field: 'chargeDivisionName',
      headerName: '料金区分',
      width: 80
    },
    {
      field: 'jwnetNo',
      headerName: '加入者番号',
      width: 120
    },
    {
      field: 'traderCd',
      headerName: '事業場CD',
      width: 120
    },
    {
      field: 'traderKbnName',
      headerName: '事業者区分',
      width: 120
    },
    {
      field: 'traderName',
      headerName: '事業所名',
      width: 300
    },
    {
      field: 'zip',
      headerName: '郵便番号',
      width: 100
    },
    {
      field: 'prefectureName',
      headerName: '都道府県',
      width: 100
    },
    {
      field: 'address',
      headerName: '住所（市区町村）',
      width: 150
    },
    {
      field: 'address2',
      headerName: '住所（町域）',
      width: 200
    },
    {
      field: 'address3',
      headerName: '住所（丁目・番地以降）',
      width: 200
    },
    {
      field: 'phone',
      headerName: '連絡先電話番号',
      width: 150
    },
    {
      field: 'email1',
      headerName: 'メールアドレス',
      width: 200
    },
    {
      field: 'effectiveflgName',
      headerName: '有効／無効',
      width: 80
    },
    // {
    //   field: 'chargeDivisionName',
    //   headerName: '料金区分',
    //   width: 80
    // },
    // {
    //   field: 'email1',
    //   headerName: 'メールアドレス',
    //   width: 200
    // },
    // {
    //   field: 'email1Flg',
    //   headerName: '有効',
    //   width: 100
    // },
    // {
    //   field: 'exhaustPasswd',
    //   headerName: '事業場パスワード',
    //   width: 150
    // },
    // {
    //   field: 'rawpasswd',
    //   headerName: '生パスワード',
    //   width: 150
    // }
    {
      field: 'daihyo',
      headerName: '代表者役職',
      width: 80
    },
    {
      field: 'daihyoName1',
      headerName: '代表者　姓',
      width: 80
    },
    {
      field: 'daihyoName2',
      headerName: '代表者　名',
      width: 80
    }
  ]

  const handleOpen = () => {
    setDetailOpen(true);
  };

  const [rows, setRows] = React.useState(dataList);
  let selRows = React.useRef([]);
  const [pageSize, setPageSize] = React.useState(10);

  // 行の削除
  const delRows = () => {
    // console.log('delRows',dataList2)
    handleDelete(dataList2);
  }
  //事業場区分はシステム管理者（'1111111111'）またはグループ管理者（'0000000001'）？
  if (currentUser.traderKbn == '1111111111' || currentUser.traderKbn == '0000000001'){
    return (
      <>

      <BrowserView>
        <Grid
          container
          spacing={2}
        >
          <Grid item xs style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>　加入者番号：</Typography>
            <TextField
              id="jwnetNo"
              value={filter.jwnetNo}
              onChange={e => setFilter({ ...filter, jwnetNo: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  startFilter();
                }
              }}
            />       
            <Typography variant="h7" style={{ fontWeight: 700 }}>　事業場CD：</Typography>
            <TextField
              id="traderCd"
              value={filter.traderCd}
              onChange={e => setFilter({ ...filter, traderCd: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  startFilter();
                }
              }}
            />       
            <Typography variant="h7" style={{ fontWeight: 700 }}>　事業場名：</Typography>
            <TextField
              id="traderName"
              value={filter.traderName}
              onChange={e => setFilter({ ...filter, traderName: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  startFilter();
                }
              }}
            />
            <Button
              type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
              データ表示
            </Button>
            　　MAX：3000件{/*　初期表示：最新60日のデータを表示*/}
          </Grid>
        </Grid>
        <Backdrop className={classes.backdrop} open={open2} >
              <CircularProgress color="inherit" />
        </Backdrop>

        <div className={classes.container}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
                sx={styles.grid}
                columns={cols}
                rows={filteredDataList}  // dataList
                density="compact"
                components={{
                  Toolbar: CustomToolbar,　// カスタムツールバーを指定する
                }}
                showColumnRightBorder // 列ヘッダセルの右側に線を引く
                showCellRightBorder   // セルの右側に線を引く
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                onRowClick={handleRowClick}
                getRowClassName={getRowClassName} // ここに関数を設定  2024/12/11
                />
          </Box>
        </div>
        <SpaceRow height={20} />
        <div style={{ textAlign: 'right' }}>
          <AdmSignInt
            traderCd={dataList2.traderCd}
            rawpasswd={dataList2.rawpasswd}
            buttonType='選択事業場でログイン'
          />
        </div>
        <EgsMTraderEdit
            isOpen={detailOpen}
            doClose={() => setDetailOpen(false)}
            processId={dataList2.id}
            dataList2={dataList2}
            currentUser={currentUser}
          />
{/*}        <EgsMTraderNew    // 新規作成
            isOpen={creOpen}
            doClose={() => setCreOpen(false)}
//            processId={dataList2.id}
          />
*/}
        <EgsMTraderAdd    // 新規作成（複写）
            isOpen={addOpen}
            doClose={() => setAddOpen(false)}
            currentUser={currentUser}
            processId={dataList2.id}
          />
          
        <EgsMChargeNew
            isOpen={chargeNewOpen}
            doClose={() => setChargeNewOpen(false)}
            currentUser={currentUser}
            dataList2={dataList2}
          />

        <EgsMTraderListTableForm
          dataList2={dataList2}
          dataCom_traderCd={dataCom.traderCd}
          currentUser={currentUser}
          />

        <Box component='div' sx={{ p: 2, textAlign: 'right' }}>
{/*}          <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleCreOpen}>
              新規作成
</Button>  */}
          <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleAddOpen} disabled={!dataList2.id}>
              新規作成（複写）
          </Button>
          <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleEditOpen} disabled={!dataList2.id}>
            編集
          </Button>
          <Button variant="contained" color='secondary' onClick={delRows} disabled={!dataList2.id}>
            削除
          </Button>
          <Button variant="contained" color="primary" style={{ marginLeft: 50 }} onClick={handleChargeNewOpen} disabled={!dataList2.id}>
            担当者新規作成
          </Button>
        </Box>

        <EgsMCompanyListTableForm
          dataList2={dataList2}
          handleGetCom={handleGetCom}
//          resCom={resCom.data}
          dataCom={dataCom}
          currentUser={currentUser}
          egsMCompanyId={dataList2.egsMCompanyId}
          />
        </BrowserView>

      </>
    );
  }else{                                       //事業場区分は管理者以外？
    if (currentUser.traderKbn == '0100000000'){    // 事業場区分は収集運搬業者？

      return (
        <>
          <EgsMTraderListTableForm
            dataList2={dataList}
  //          dataCom={dataCom}
            currentUser={currentUser}
            />
  
          <Box component='div' sx={{ p: 2, textAlign: 'right' }}>
            <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleEditOpen}>
              編集
            </Button>
            <Button variant="contained" color="primary" style={{ marginLeft: 50 }} onClick={handleChargeNewOpen}>
              担当者新規作成
            </Button>
          </Box>
  
          <EgsMTraderEdit
              isOpen={detailOpen}
              doClose={() => setDetailOpen(false)}
              processId={dataList.id} //{dataList2.id}
              dataList2={dataList}  // dataList2
              currentUser={currentUser}
            />
          <EgsMChargeNew
              isOpen={chargeNewOpen}
              doClose={() => setChargeNewOpen(false)}
              currentUser={currentUser}
              dataList2={dataList}  // dataList2
            />
  
  
          <EgsMCompanyListTableForm
            dataList2={dataList}
            handleGetCom={handleGetCom}
  //          resCom={resCom}
//          resCom={resCom.data}
            dataCom={dataCom}
            currentUser={currentUser}
            egsMCompanyId={dataList2.egsMCompanyId}
          />
        </>
      );
  
    }else{

      return (
        <>
          <EgsMTraderListTableForm
            dataList2={dataList}
//            dataCom={dataCom}
            resCom={resCom}
            currentUser={currentUser}
            />
  
          <Box component='div' sx={{ p: 2, textAlign: 'right' }}>
            <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleEditOpen}>
              編集
            </Button>
            <Button variant="contained" color="primary" style={{ marginLeft: 50 }} onClick={handleChargeNewOpen}>
              担当者新規作成
            </Button>
          </Box>
  
          <EgsMTraderEdit
              isOpen={detailOpen}
              doClose={() => setDetailOpen(false)}
              processId={dataList.id} //{dataList2.id}
              dataList2={dataList}  // dataList2
              currentUser={currentUser}
            />
          <EgsMChargeNew
              isOpen={chargeNewOpen}
              doClose={() => setChargeNewOpen(false)}
              currentUser={currentUser}
              dataList2={dataList}  // dataList2
            />
        </>
      );
  
    }


  }
}
const styles = {
/*  
  grid: {
    // '.MuiDataGrid-toolbarContainer': {
    //   borderBottom: 'solid 1px rgba(224, 224, 224, 1)'
    // },
    // '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
    //   borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
    // },
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
*/    
grid: {
  '.MuiDataGrid-columnHeaders': {
    backgroundColor: '#65b2c6',  // タイトル行　青色　'#add8e6', // 薄青色  '#c71585',
    color: '#fff',
  },
  '& .disable-row': { // 無効の行に適用するスタイル
    backgroundColor: '#dbd3c5', // 薄灰色  // '#f5deb3', 
  },
  '& .non-enable-row': { // 有効無効以外の行に適用するスタイル
    backgroundColor: '#c71585', // 濃いピンク  '#add8e6', // 薄青色
  }
}
}
export default EgsMTraderListTable;
