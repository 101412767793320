// Form.jsx
import React, { useState, useEffect ,  useContext  } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Typography,
  InputLabel,
} from '@material-ui/core';

import {
  DataGridPro,
  jaJP
} from '@mui/x-data-grid-pro'

import SpaceRow from '../commons/SpaceRow';
//import { getEgsMChargeList } from '../../lib/api/egs_m_charge';
//import { getEgsMTraderList } from '../../lib/api/egs_m_trader';
import { getLinkageUser } from '../../lib/api/linkage';
import { getTraderSelList } from '../../lib/api/trader_sel';
import { getEgsMChargeList } from '../../lib/api/egs_m_charge';
import { getChargeSelList } from '../../lib/api/charge_sel';

import { getEgsMWasteclassList } from '../../lib/api/egs_m_wasteclass';
import { getEgsMToxicSubstanceList } from '../../lib/api/egs_m_toxic_substance';
import { getEgsMDisposalMethodList } from '../../lib/api/egs_m_disposal_method';
import { getEgsMTransportMethodList } from '../../lib/api/egs_m_transport_method';
import { getEgsMUnitList } from '../../lib/api/egs_m_unit';
import { getEgsMPackingList } from '../../lib/api/egs_m_packing';
import { getEditDelRequestManiNo } from '../../lib/api/edit_del_request';

import './app.css';
import { AuthContext } from '../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',    // 100ch // 200ch
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 2,
    borderColor: "black",
    borderStyle: "solid",
    marginBottom: 8,
    width: "40vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "rgb(225, 242, 188)",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

const MiddleDateForm = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { handleChange, handleSubmit, handleClose, value, buttonType1, buttonType2, handleCancel } = props;

  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);

//  const [dataListC01, setdataListC01] = useState([]);
  const [dataListC02, setdataListC02] = useState([]);

  const [dataListWaste,setDataListWaste] = useState([]);  {/* 廃棄物種類 */}
  const [dataListToxic,setDataListToxic] = useState([]);  {/* 有害物質 */}
  const [dataListDisposal,setDataListDisposal] = useState([]);  {/* 処分方法 */}
  const [dataListTransport,setDataListTransport] = useState([]);  {/* 運搬方法 */}
  const [dataListUnit,setDataListUnit] = useState([]);  {/* 単位 */}
  const [dataListPacking,setDataListPacking] = useState([]);  {/* 荷姿 */}

  const pdfRef = React.createRef();
  const [newData, setnewData] = useState([]);
//  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    handleGetList();
  }, []);

  useEffect(() => {
    fetchEditDelRequestManiNo(); // getEditDelRequestManiNoを呼び出す処理を追加
}, [value.manifestNo]);

  const fetchEditDelRequestManiNo = async () => {
    try {
      const response2 = await getEditDelRequestManiNo(value.manifestNo); // API呼び出し 
        setnewData(response2.data);
        console.log("EditDelRequestManiNo data:", newData); 
    } catch (error) {
      console.error("Failed to fetch EditDelRequestManiNo:", error);
    }
  };

  const handleGetList = async () => {
    try {
     setOpen(!open);
    const dataListWaste = await getEgsMWasteclassList();
    setDataListWaste(dataListWaste.data);
//    // console.log('wasteCd',value.wasteCd,'dataListWaste',dataListWaste.data);

    const dataListToxic = await getEgsMToxicSubstanceList();
    setDataListToxic(dataListToxic.data);
//    // console.log('toxicCd01',value.toxicCd01,'dataListToxic',dataListToxic.data);

    const dataListDisposal = await getEgsMDisposalMethodList();
    setDataListDisposal(dataListDisposal.data);
//    // console.log('disposalMethodCd',value.disposalMethodCd,'dataListDisposal',dataListDisposal.data);

    const dataListTransport = await getEgsMTransportMethodList();
    setDataListTransport(dataListTransport.data);
//    // console.log('sect1TransportMethodCd',value.sect1TransportMethodCd,'dataListTransport',dataListTransport.data);

    const dataListUnit = await getEgsMUnitList();
    setDataListUnit(dataListUnit.data);
//    // console.log('unitCd',value.unitCd,'dataListUnit',dataListUnit.data);

    const dataListPacking = await getEgsMPackingList();
    setDataListPacking(dataListPacking.data);
//    // console.log('packingCd',value.packingCd,'dataListPacking',dataListPacking.data);

//      const resC1 = await getEgsMChargeList();
//      // console.log('resC1',resC1.data);
//      setdataListC01(resC1.data);
//      const dataListC01 = await getEgsMChargeList();
//      // console.log('dataListC01',dataListC01.data);
//      setdataListC01(dataListC01.data);

//      const resC2 = await getChargeSelList();
//      // console.log('resC2',resC2.data);
//      setdataListC02(resC2.data);
      const dataListC02 = await getChargeSelList();
//      // console.log('dataListC02',dataListC02.data);
      setdataListC02(dataListC02.data);
     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };


const wastedataList = dataListWaste.filter(dataListWaste => { return dataListWaste.wastclassCd != '' });
//// console.log('wastedataList',wastedataList);

const toxicdataList = dataListToxic.filter(dataListToxic => { return dataListToxic.toxicSubstanceCd != '' });
//// console.log('toxicdataList',toxicdataList);

const disposaldataList = dataListDisposal.filter(dataListDisposal => { return dataListDisposal.disposalMethodCd != '' });
//// console.log('disposaldataList',disposaldataList);

//const trans1dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd == value.sect1TransportMethodCd });
const trans1dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
const trans2dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
const trans3dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
const trans4dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
const trans5dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
//// console.log('transport',value.sect1TransportMethodCd,value.sect2TransportMethodCd,value.sect3TransportMethodCd,value.sect4TransportMethodCd,value.sect5TransportMethodCd);

const unitdataList = dataListUnit.filter(dataListUnit => { return dataListUnit.unitclassCd != '' });
//// console.log('unitdataList',unitdataList);

const packingdataList = dataListPacking.filter(dataListPacking => { return dataListPacking.packingCd != '' });
//// console.log('packingdataList',packingdataList);


// 区間１～５

//  const chargedataList00 = dataListC01.filter(resC1 => { return resC1.id == value.exhaustId });
//  const chargedataList00 = dataListC01.filter(resC1 => { return resC1.egsMTraderid == value.exhaustId });
//const chargedataList00 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.exhaustId });
const chargedataList00 = dataListC02.filter(dataListC02 => { return dataListC02.egsMTraderId == value.exhaustId && dataListC02.effectiveflg != '9' });
//  // console.log('chargedataList00','->',chargedataList00,'<-',value.exhaustId);

//  const chargedataList07 = dataListC02.filter(resC2 => { return resC2.id == value.lastProcessingChargeId });
  const chargedataList07 = dataListC02.filter(dataListC02 => { return dataListC02.egsMTraderId == value.lastProcessingTraderId && dataListC02.effectiveflg != '9' });
//  // console.log('chargedataList07','->',chargedataList07,'<-',value.lastProcessingTraderId);

//const chargedataList01 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination1Id });
//const chargedataList01 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destinationId });
const chargedataList01 = dataListC02.filter(dataListC02 => { return dataListC02.egsMTraderId == value.destinationId && dataListC02.effectiveflg != '9' });
//const chargedataList02 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination2Id });
//const chargedataList03 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination3Id });
//const chargedataList04 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination4Id });
//const chargedataList05 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination5Id });
/*
const chargedataList11 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination1Id });
const chargedataList12 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination2Id });
const chargedataList13 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination3Id });
const chargedataList14 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination4Id });
const chargedataList15 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination5Id });
*/
const [errorPackingAmount1, setErrorPackingAmount1] = useState(false);  // 荷姿数量

const checkValidation = () => {
  let available = true;
  if (!value.packingAmount1 ||
      !value.packingAmount1
  ) {
    // console.log(!value.packingAmount1,);
    available = false;
    setErrorPackingAmount1(!value.packingAmount1);
  }
  return available;
}

const cols = [
  {
    field: 'id',
    headerName: 'ID',
    hide: true,
    width: 80
  },
  {
    field: 'reqDate',
    headerName: '依頼日',
    hide: true,
    width: 100
  },
  {
    field: 'reqCharge',
    headerName: '依頼担当者',
    hide: true,
    width: 100
  },
  {
    field: 'status',
    headerName: '処理状況',
    hide: true,
    width: 100
  },
  {
    field: 'exhaustName',
    headerName: '排出事業場',
    hide: true,
    width: 300
  },
  {
    field: 'manifestNo',
    headerName: 'マニフェストＮｏ',
    hide: true,
    width: 140
  },
  {
    field: 'reqKind',
    headerName: '依頼種別',
    width: 160
  },
  {
    field: 'reqComment',
    headerName: '補足事項',
    cellClassName: 'newData-cell',
    width: 200
  },
  {
    field: 'reqItem_1',
    headerName: '依頼項目１',
    cellClassName: 'reqItem-cell',
    width: 120
  },
  {
    field: 'oldData_1',
    headerName: '修正前１',
    width: 100
  },
  {
    field: 'newData_1',
    headerName: '修正後１',
    cellClassName: 'newData-cell',
    width: 100
  },
  {
    field: 'reqItem_2',
    headerName: '依頼項目２',
    cellClassName: 'reqItem-cell',
    width: 120
  },
  {
    field: 'oldData_2',
    headerName: '修正前２',
    width: 100
  },
  {
    field: 'newData_2',
    headerName: '修正後２',
    cellClassName: 'newData-cell',
    width: 100
  },
]
  return (
    <>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div style={{ textAlign: 'right', margin: '10px' }}>
          <Button variant='contained' onClick={e => {
            e.preventDefault();
            // history.push('/garbage_edi');
            props.handleClose();
          }}>    {/*  egs_m_reserve_sets  */}
            戻る
          </Button>
        </div>
      <div className={classes.container}>
            <Box sx={{ height: 150, width: '100%' }}>
              <DataGridPro
                sx={styles.grid}
                columns={cols}
                rows={newData}
                density="compact"
                showColumnRightBorder // 列ヘッダセルの右側に線を引く
                showCellRightBorder   // セルの右側に線を引く
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              />
            </Box>
          </div>
          <SpaceRow height={10} />
      <div className={classes.container} ref={pdfRef}>

      <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                中間処理日
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='middleEnd' variant="outlined" type='date' name='middleEnd' fullWidth margin="dense" value={value.middleEnd} onChange={(e) => handleChange(e)} />
            </Box>
          </Grid>
      </Grid>

      <Grid
          container
          spacing={0}
          className={classes.row}
        >
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                ゴミタグ番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.garbageTag} </Typography>
            </Box>
          </Grid>

        </Grid>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                マニフェスト番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.manifestNo} </Typography>
            </Box>
          </Grid>

        </Grid>

          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                排出日
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
{/*}              <TextField id='exhaustDate' variant="outlined" type='date' name='exhaustDate' fullWidth margin="dense" value={value.exhaustDate} onChange={(e) => handleChange(e)} />  */}
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.exhaustDate} </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                排出事業者・担当者
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}      justifyContent={"flex-start"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.exhaustName} </Typography>
{/*}              <Select
                id='exhaustChargeId'
                label='排出担当者'
                variant="outlined"
                type='text'
                name='exhaustChargeId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.exhaustChargeId}
              >
              {chargedataList00.map((chargedataList00) => (
                <MenuItem
                  key={chargedataList00.id}
                  value={chargedataList00.id}
                >
                  {chargedataList00.chargeCd}:{chargedataList00.chargeName}
                </MenuItem>
              ))}
              </Select>  */}
              　
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.exhaustChargeName} </Typography>
            </Box>
          </Grid>
          <Grid item xs />
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                廃棄物の数量
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
{/*}                <TextField id='amount1' variant="outlined" type='number' name='amount1' size="small" halfWidth margin="dense" value={value.amount1} onChange={(e) => handleChange(e)} />  */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.amount1} </Typography>
                　
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.unitName} </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                荷姿の数量（整数）
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
{/*}                <TextField id='packingAmount1' variant="outlined"
                 type='number' // number text integer
                 name='packingAmount1' size="small" halfWidth margin="dense"
                 error={errorPackingAmount1}
                 // これでも小数点以下が入力できてしまう！！！
                 inputProps={{ pattern: "[1-9][0-9]*" }}  // maxLength: 3, pattern: "0-9"
                 value={value.packingAmount1}
                 onChange={(e) => handleChange(e)}
              />  */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.packingAmount1} </Typography>
                　
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.packingName} </Typography>
              </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                中間処理業者
              </InputLabel>
            </Box>
          </Grid>

          <Grid item xs={6}>
              <Box p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.destinationName} </Typography>
                <Select halfWidth id='destinationChargeId' label='中間処理担当者' variant="outlined" type='text' name='destinationChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destinationChargeId} >
                  {chargedataList01.map((chargedataList01) => (<MenuItem key={chargedataList01.id} value={chargedataList01.id} > {chargedataList01.chargeCd}:{chargedataList01.chargeName} </MenuItem>))}
                </Select>
              </Box>
          </Grid>

          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}      justifyContent={"flex-start"} alignItems={"center"}>
            </Box>
          </Grid>
          <Grid item xs />
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                処分方法
              </InputLabel>
            </Box>
          </Grid>

          <Grid item xs={6}>
              <Box p={1}>
        <Select
          id='disposalMethodCd'
          label='処分方法'
          variant="outlined"
          type='text'
          name='disposalMethodCd'
          margin="dense"
          onChange={(e) => handleChange(e)}
          value={value.disposalMethodCd}
        >
          <MenuItem value={value.disposalMethodCd}>
            <em>処分方法</em>
          </MenuItem>
          <MenuItem value={'100'}>100:再生（※）</MenuItem>
          <MenuItem value={'101'}>101:再使用（リユース）</MenuItem>
          <MenuItem value={'102'}>102:素材再生</MenuItem>
          <MenuItem value={'103'}>103:多用途原材料化</MenuItem>
          <MenuItem value={'104'}>104:燃料化</MenuItem>
          <MenuItem value={'105'}>105:コンポスト化</MenuItem>
          <MenuItem value={'106'}>106:その他再生</MenuItem>
          <MenuItem value={'200'}>200:中間処理</MenuItem>
          <MenuItem value={'201'}>201:脱水</MenuItem>
          <MenuItem value={'202'}>202:機械乾燥</MenuItem>
          <MenuItem value={'203'}>203:天日乾燥</MenuItem>
          <MenuItem value={'204'}>204:焼却</MenuItem>
          <MenuItem value={'205'}>205:油水分解</MenuItem>
          <MenuItem value={'206'}>206:中和</MenuItem>
          <MenuItem value={'207'}>207:破砕</MenuItem>
          <MenuItem value={'208'}>208:圧縮</MenuItem>
          <MenuItem value={'209'}>209:溶融</MenuItem>
          <MenuItem value={'210'}>210:選別</MenuItem>
          <MenuItem value={'211'}>211:固形化</MenuItem>
          <MenuItem value={'212'}>212:ばい焼</MenuItem>
          <MenuItem value={'213'}>213:分解</MenuItem>
          <MenuItem value={'214'}>214:洗浄</MenuItem>
          <MenuItem value={'215'}>215:減菌</MenuItem>
          <MenuItem value={'216'}>216:消毒</MenuItem>
          <MenuItem value={'217'}>217:煮沸</MenuItem>
          <MenuItem value={'299'}>299:その他中間処理</MenuItem>
          <MenuItem value={'300'}>300:最終処分</MenuItem>
          <MenuItem value={'301'}>301:埋立処分</MenuItem>
          <MenuItem value={'302'}>302:安定型埋立処分</MenuItem>
          <MenuItem value={'303'}>303:管理型埋立処分</MenuItem>
          <MenuItem value={'304'}>304:遮断型埋立処分</MenuItem>
          <MenuItem value={'310'}>310:海洋投入</MenuItem>
          <MenuItem value={'900'}>900:その他（有価・買取）</MenuItem>
         </Select>
              </Box>
          </Grid>

          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}      justifyContent={"flex-start"} alignItems={"center"}>
            </Box>
          </Grid>
          <Grid item xs />
        </Grid>

      </div>

      <div style={{ textAlign: 'right' }}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          onClick={(e) => {
//            handleSubmit(e);
            if (checkValidation()) {
              handleSubmit(e);
            }
         }}
          style={{ marginRight: 10 }}
        >
          {buttonType1}
        </Button>
{/*}
        <Button
          type='submit'
          variant='contained'
          color='secondary'
          onClick={(e) => {
            if (checkValidation()) {
              handleCancel(e);
            }
         }}
          style={{ marginRight: 10 }}
        >
          {buttonType2}
        </Button>
*/}
      </div>



    </>
  );
};
const styles = {
  grid: {
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#c71585',
      color: '#fff',
    }
  }
 }
export default MiddleDateForm;
