import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { BrowserView, MobileView } from "react-device-detect"

//import ReactToPdf from "react-to-pdf";
import QRCode from "qrcode.react"
import dayjs from 'dayjs';

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import { makeStyles } from '@material-ui/core/styles';
// style
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridColumnMenu,
  jaJP
} from '@mui/x-data-grid-pro'
import ExpandMoreIcon  from '@material-ui/icons/ExpandMore';

import FormDialog from "../commons/FormDialog";
import './app.css';
import { statusName,ediFlg,toxicSubstanceName } from '../../constants'
import EgsTGarbageEdiEdit from './EdiD01ItemEdit';
import EditDelRequest from './EditDelRequest';
//1112import FirstSelEdit from './FirstSelEdit';
//import TransportDateEdit from './TransportDateEdit';
import TransportDateEdit from './TransportDateEdit';
//import TransportDateEdit1 from './TransportDateEdit1';
//import TransportDateEdit2 from './TransportDateEdit2';
//import TransportDateEdit3 from './TransportDateEdit3';
//import TransportDateEdit4 from './TransportDateEdit4';
//import TransportDateEdit5 from './TransportDateEdit5';
import TransportLoadDateEdit from './TransportLoadDateEdit';
import MiddleDateEdit from './MiddleDateEdit';
import LastDateEdit from './LastDateEdit';
//1112import FirstSelList from './FirstSelList';
import GarbageEdiListTableForm from "./GarbageEdiListTableForm"
import { getEgsTPhotoGbgid } from '../../lib/api/egs_t_photo';
import EgsTGarbageEdi2ndEdit from "./EgsTGarbageEdi2ndEdit"
import EdiD01ItemCancel from "./EdiD01ItemCancel"
import LastDisposalEdit from "./LastDisposalEdit"
import GarbageEdiPdf from "./GarbageEdiPdf"
import GarbageEdiPdfDL from "./GarbageEdiPdfDL"
import { getGarbageDetailOperation, getGarbageDetailId } from '../../lib/api/garbage_detail';
import { getGarbagePcOperation } from '../../lib/api/garbage_pc';
import SpaceRow from '../commons/SpaceRow';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: 'Sdgs12_GarbageEdi',
          delimiter: ',',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
}

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      componentsProps={{
        // Swap positions of filter and sort items
        columnMenuFilterItem: {
          displayOrder: 0, // Previously `10`
        },
        // columnMenuSortItem: {
        //   displayOrder: 10, // Previously `0`
        // },
      }}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "95vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#FFC",
    borderWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

function GarbageEdiListTable(props) {
  const classes = useStyles();
  const { dataList, handleDelete, currentUser, handleCancel, mbluser } = props;
  const [detailOpen1, setDetailOpen1] = useState(false);
  const [detailOpen2, setDetailOpen2] = useState(false);
  const [detailOpen30, setDetailOpen30] = useState(false);  // 区間１～５運搬終了報告修正
//  const [detailOpen31, setDetailOpen31] = useState(false);  // 区間１運搬終了報告修正
//  const [detailOpen32, setDetailOpen32] = useState(false);  // 区間２運搬終了報告修正
//  const [detailOpen33, setDetailOpen33] = useState(false);  // 区間３運搬終了報告修正
//  const [detailOpen34, setDetailOpen34] = useState(false);  // 区間４運搬終了報告修正
//  const [detailOpen35, setDetailOpen35] = useState(false);  // 区間５運搬終了報告修正
  const [detailOpen36, setDetailOpen36] = useState(false);  // 区間１～５積込日修正
  const [detailOpen4, setDetailOpen4] = useState(false);  // 中間処理日修正
  const [detailOpen5, setDetailOpen5] = useState(false);  // 最終処分日修正
  const [detailOpen6, setDetailOpen6] = useState(false);  // ２次マニフェスト
  const [detailOpen7, setDetailOpen7] = useState(false);  // 取消（論理削除）
  const [detailOpen8, setDetailOpen8] = useState(false);  // 最終処分終了報告
  const [detailOpen9, setDetailOpen9] = useState(false);  // 廃棄物詳細PDF
  const [detailOpen10, setDetailOpen10] = useState(false);  // マニフェスト修正・取消
  const [detailOpen11, setDetailOpen11] = useState(false);  // PDF一括ダウンロード

  const [dataList2, setDataList2] = useState({});
  const [dataList3, setDataList3] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [curData, setCurData] = useState();

  const [filteredDataList, setFilteredDataList] = React.useState([]);
  const [filter, setFilter] = React.useState({
    exhaustDateStart: '',
    exhaustDateEnd: '',
    manifestNo: '',
//    wasteKindName: '',
    wasteName: '',
    statusName: '',
    exhaustName: '',
    sect1TransportName: ''
  })

  const history = useHistory();
  const query = useParams();
  const pdfRef = React.createRef();

  var param_item = '';

  useEffect(() => {
    setFilteredDataList(dataList);
    // console.log('setFilteredDataList', filteredDataList);
  }, [dataList, currentUser])

  const startFilter = () => {
    // console.log('dataList', dataList);
    // console.log('currentUser', currentUser);

    // console.log('filter', filter);
    if (filter.exhaustDateStart == ''){
      filter.exhaustDateStart = dayjs().subtract(90,'d').format('YYYY-MM-DD');
    }
    if (filter.exhaustDateEnd == ''){
      filter.exhaustDateEnd = dayjs().format('YYYY-MM-DD');
    }
    param_item = currentUser.traderCd + "_"+currentUser.traderKbn + "_"+filter.exhaustDateStart+"_"+filter.exhaustDateEnd+"_"+filter.manifestNo+"_"+filter.statusName+"_"+filter.exhaustName+"_"+filter.wasteName+"_"+filter.sect1TransportName;
    // console.log("param_item",param_item)
    handleGetList(param_item);
  }

  const handleGetList = async () => {
    setOpen2(!open2);
    try {
      //const res = await getGarbagePcOperation(param_item);  // 20230809 getGarbageDetailOperation → getGarbagePcOperation
      const res = await getGarbageDetailOperation(param_item);  // 20230809 getGarbageDetailOperation → getGarbagePcOperation
      setFilteredDataList(res.data);
      // console.log('setFilteredDataList', filteredDataList);
    } catch (e) {
      console.log(e);
    }
    setOpen2(false);
  };
  const handleGetData = async (param) => {
    setOpen2(!open2);
    try {
      const  res = await getGarbageDetailId(param.id);
      // console.log("res",res);
      setDataList2(res.data[0]);
      // console.log("dataList2",dataList2);
    } catch (e) {
      console.log(e);
    }
    setOpen2(false);
  };

  const handleGetPhotos = async () => {
      try {
      const res = await getEgsTPhotoGbgid(dataList2.id);
      setDataList3(res.data);
    } catch (e) {
      console.log(e);
    }

  };

  useEffect(() => {
    // console.log("dataList3",dataList3);
    }, []);

  const handleRowClick = (param, event) => {
    // console.log("param",param);
    handleGetData(param);

    handleGetPhotos(dataList2.id);
  };

  const handleOpen1 = () => {
    setDetailOpen1(true);
  };

  const handleOpen2 = () => {
    setDetailOpen2(true);
  };

  let sectId = '1';
  const handleOpen31 = (sectId) => {
    sectId = '1';
    currentUser.nickname = '1';
//    alert('sectId='+sectId + currentUser.nickname);
//    titleId=='区間１運搬終了報告修正';
    setDetailOpen30(true);    // setDetailOpen31
  };
  const handleOpen32 = (sectId) => {
    sectId = '2';
    currentUser.nickname = '2';
//    alert('sectId='+sectId + currentUser.nickname);
//    titleId=='区間２運搬終了報告修正';
    setDetailOpen30(true);    // setDetailOpen32
  };
  const handleOpen33 = (sectId) => {
    sectId = '3';
    currentUser.nickname = '3';
//    alert('sectId='+sectId + currentUser.nickname);
//    titleId=='区間３運搬終了報告修正';
    setDetailOpen30(true);    // setDetailOpen33
  };
  const handleOpen34 = (sectId) => {
    sectId = '4';
    currentUser.nickname = '4';
//    alert('sectId='+sectId + currentUser.nickname);
//    titleId=='区間４運搬終了報告修正';
    setDetailOpen30(true);    // setDetailOpen34
  };
  const handleOpen35 = (sectId) => {
    sectId = '5';
    currentUser.nickname = '5';
//    alert('sectId='+sectId + currentUser.nickname);
//    titleId=='区間５運搬終了報告修正';
    setDetailOpen30(true);    // setDetailOpen35
  };

  const handleOpen36 = (sectId) => {
    sectId = '1';
    currentUser.nickname = '1';
    setDetailOpen36(true);
  };
  const handleOpen37 = (sectId) => {
    sectId = '2';
    currentUser.nickname = '2';
    setDetailOpen36(true);
  };
  const handleOpen38 = (sectId) => {
    sectId = '3';
    currentUser.nickname = '3';
    setDetailOpen36(true);
  };
  const handleOpen39 = (sectId) => {
    sectId = '4';
    currentUser.nickname = '4';
    setDetailOpen36(true);
  };
  const handleOpen3A = (sectId) => {
    sectId = '5';
    currentUser.nickname = '5';
    setDetailOpen36(true);
  };

  const handleOpen4 = () => {
    setDetailOpen4(true);
  };

  const handleOpen5 = () => {
    setDetailOpen5(true);
  };

  const handleOpen6 = () => {
    setDetailOpen6(true);
  };

  const handleOpen7 = () => {
    setDetailOpen7(true);
  };

  const handleOpen8 = () => {
    setDetailOpen8(true);
  };

  const handleOpen9 = () => {
    setDetailOpen9(true);
  };

  const handleOpen10 = () => {
    setDetailOpen10(true);
  };
  const handleOpen11 = () => {
    setDetailOpen11(true);
  };

  const handleOpenMbl1 = () => {
    var rtn = dataList2.garbageTag.split( '-' );
    let d = new Date();
    const garbageTag = rtn[0] + "-" + d.valueOf();
    history.push(`/mbl_emission_set/${garbageTag}`)
  };

  const handleOpenMbl2 = () => {
    history.push(`/mbl_garbage_tmp/${dataList2.exhaustCd}`);
  };

  const [rows, setRows] = React.useState(dataList);
  let selRows = React.useRef([]);
  // console.log("selRows",selRows);
  const [pageSize, setPageSize] = React.useState(10);


  // 行の削除
  const delRows = () => {
    // console.log('delRows',dataList2)
    handleDelete(dataList2);
  }

  // 行の取消（論理削除）
  const cancelRows = () => {
    // console.log('cancelRows',dataList2)
    handleCancel(dataList2);
  }

  // console.log('dataList',dataList);
  // console.log('dataList2',dataList2);
  // console.log('dataList.id',dataList.id);

  const qrCodeUrl = "https://www.sdgs12.com/waste/" + dataList2.garbageTag;
  // console.log('qrCodeUrl', qrCodeUrl);

  const colsA = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'sect1TransportName',
      headerName: '区間１運搬事業者',
      width: 250
    },
    {
      field: 'sect2TransportName',
      headerName: '区間２運搬事業者',
      hide: true,
      width: 300
    },
    {
      field: 'exhaustDate',
      headerName: '排出日',
      type: 'date',
      width: 100
    },
    {
      field: 'garbageTag',
      headerName: 'ゴミタグ',
      hide: true,
      width: 200
    },
    {
      field: 'manifestNo',
      headerName: 'マニフェストＮｏ',
      width: 140
    },
    {
      field: 'statusName',
      headerName: '状態',
      width: 110
    },
    {
      field: 'ediSendName',
      headerName: 'JWNET登録状況',
      width: 200
    },
    {
      field: 'noticeName',
      headerName: 'JWNET通知情報',
      width: 200
    },
    {
      field: 'jwnetNo',
      headerName: '加入者番号',
      width: 100
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 300
    },
    {
      field: 'wasteName',  // wasteclassName
      headerName: '廃棄物種類',
      width: 200
    },
    {
      field: 'amount1',
      headerName: '数量',
      width: 100,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100
    },
    {
      field: 'packingAmount1',
      headerName: '荷姿数量',
      width:90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 150
    },
    {
      field: 'memo1',
      headerName: '備考１',
      width: 150
    },
    {
      field: 'memo2',
      headerName: '備考２',
      hide: true,
      width: 150
    },
    {
      field: 'memo3',
      headerName: '備考３',
      hide: true,
      width: 150
    },
    {
      field: 'memo4',
      headerName: '備考４',
      hide: true,
      width: 150
    },
    {
      field: 'memo5',
      headerName: '備考５',
      hide: true,
      width: 150
    },
    {
      field: 'destination1Name',
      headerName: '区間１運搬先事業者',
      hide: true,
      width: 300
    },
    {
      field: 'destination2Name',
      headerName: '区間２運搬先事業者',
      hide: true,
      width: 300
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場',
      width: 300
    },
    {
      field: 'lastProcessingEnd',
      headerName: '最終処分終了日',
      type: 'date',
      width: 120
    },
    {
      field: 'secondFlgName',
      headerName: '1/2次',
      width: 60
    }
  ]

  const colsG = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'sect1TransportName',
      headerName: '区間１運搬事業者',
      width: 250
    },
    {
      field: 'sect2TransportName',
      headerName: '区間２運搬事業者',
      hide: true,
      width: 300
    },
    {
      field: 'exhaustDate',
      headerName: '排出日',
      type: 'date',
      width: 100
    },
    {
      field: 'garbageTag',
      headerName: 'ゴミタグ',
      hide: true,
      width: 200
    },
    {
      field: 'manifestNo',
      headerName: 'マニフェストＮｏ',
      width: 140
    },
    {
      field: 'statusName',
      headerName: '状態',
      width: 110
    },
    {
      field: 'ediSendName',
      headerName: 'JWNET登録状況',
      width: 200
    },
    {
      field: 'noticeName',
      headerName: 'JWNET通知情報',
      width: 200
    },
    {
      field: 'jwnetNo',
      headerName: '加入者番号',
      width: 100
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 300
    },
    {
      field: 'wasteName',  // wasteclassName
      headerName: '廃棄物種類',
      width: 200
    },
    {
      field: 'amount1',
      headerName: '数量',
      width: 100,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100
    },
    {
      field: 'packingAmount1',
      headerName: '荷姿数量',
      width:90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 150
    },
    {
      field: 'memo1',
      headerName: '備考１',
      width: 150
    },
    {
      field: 'memo2',
      headerName: '備考２',
      hide: true,
      width: 150
    },
    {
      field: 'memo3',
      headerName: '備考３',
      hide: true,
      width: 150
    },
    {
      field: 'memo4',
      headerName: '備考４',
      hide: true,
      width: 150
    },
    {
      field: 'memo5',
      headerName: '備考５',
      hide: true,
      width: 150
    },
    {
      field: 'destination1Name',
      headerName: '区間１運搬先事業者',
      hide: true,
      width: 300
    },
    {
      field: 'destination2Name',
      headerName: '区間２運搬先事業者',
      hide: true,
      width: 300
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場',
      width: 300
    },
    {
      field: 'lastProcessingEnd',
      headerName: '最終処分終了日',
      type: 'date',
      width: 120
    },
    {
      field: 'secondFlgName',
      headerName: '1/2次',
      width: 60
    }
  ]

  const cols1 = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'exhaustDate',
      headerName: '排出日',
      type: 'date',
      width: 100
    },
    {
      field: 'garbageTag',
      headerName: 'ゴミタグ',
      hide: true,
      width: 200
    },
    {
      field: 'manifestNo',
      headerName: 'マニフェストＮｏ',
      width: 140
    },
    {
      field: 'statusName',
      headerName: '状態',
      width: 110
    },
    {
      field: 'ediSendName',
      headerName: 'JWNET登録状況',
      width: 210
    },
    {
      field: 'noticeName',
      headerName: 'JWNET通知情報',
      width: 210
    },
    {
      field: 'jwnetNo',
      headerName: '加入者番号',
      width: 100
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 300
    },
    {
      field: 'wasteName',  // wasteclassName
      headerName: '廃棄物種類',
      width: 240
    },
    {
      field: 'amount1',
      headerName: '数量',
      width: 100,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100
    },
    {
      field: 'packingAmount1',
      headerName: '荷姿数量',
      width:90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 150
    },
    {
      field: 'memo1',
      headerName: '備考１',
      width: 150
    },
    {
      field: 'memo2',
      headerName: '備考２',
      hide: true,
      width: 150
    },
    {
      field: 'memo3',
      headerName: '備考３',
      hide: true,
      width: 150
    },
    {
      field: 'memo4',
      headerName: '備考４',
      hide: true,
      width: 150
    },
    {
      field: 'memo5',
      headerName: '備考５',
      hide: true,
      width: 150
    },
    {
      field: 'sect1TransportName',
      headerName: '区間１運搬事業者',
      hide: true,
      width: 300
    },
    {
      field: 'destination1Name',
      headerName: '区間１運搬先事業者',
      hide: true,
      width: 300
    },
    {
      field: 'sect2TransportName',
      headerName: '区間２運搬事業者',
      hide: true,
      width: 300
    },
    {
      field: 'destination2Name',
      headerName: '区間２運搬先事業者',
      hide: true,
      width: 300
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場',
      width: 300
    },
    {
      field: 'lastProcessingEnd',
      headerName: '最終処分終了日',
      type: 'date',
      width: 120
    },
    {
      field: 'secondFlgName',
      headerName: '1/2次',
      width: 60
    }
  ]
  const cols2MB = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'sect1TransportName',
      headerName: '区間１運搬事業者',
      hide: true,
      width: 240,
    },
    {
      field: 'sect2TransportName',
      headerName: '区間２運搬事業者',
      hide: true,
      width: 240,
    },
    {
      field: 'exhaustDate',
      headerName: '排出日',
      type: 'date',
      width: 100,
    },
    {
      field: 'garbageTag',
      headerName: 'ゴミタグ',
      width: 80,
    },
    {
      field: 'manifestNo',
      headerName: 'マニフェストＮｏ',
      width: 130,
    },
    {
      field: 'statusName',
      headerName: '状態',
      width: 110,
    },
    {
      field: 'ediSendName',
      headerName: 'JWNET登録状況',
      hide:true,
      width: 210,
    },
    {
      field: 'noticeName',
      headerName: 'JWNET通知情報',
      hide:true,
      width: 210,
    },
    {
      field: 'jwnetNo',
      headerName: '加入者番号',
      hide:true,
      width: 100,
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 240,
    },
    {
      field: 'wasteName',  // wasteclassName
      headerName: '廃棄物種類',
      width: 200,
    },
    {
      field: 'amount1',
      headerName: '数量',
      width: 100,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100,
    },
    {
      field: 'packingAmount1',
      headerName: '荷姿数量',
      width:90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 150,
    },
    {
      field: 'memo1',
      headerName: '備考１',
      width: 100,
    },
    {
      field: 'memo2',
      headerName: '備考２',
      width: 60,
    },
    {
      field: 'memo3',
      headerName: '備考３',
      width: 60,
    },
    {
      field: 'memo4',
      headerName: '備考４',
      width: 60,
    },
    {
      field: 'memo5',
      headerName: '備考５',
      width: 60,
    },
    {
      field: 'destination1Name',
      headerName: '区間１運搬先事業者',
      width: 240,
    },
    {
      field: 'destination2Name',
      headerName: '区間２運搬先事業者',
      hide: true,
      width: 240,
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場',
      width: 300,
    },
    {
      field: 'lastProcessingEnd',
      headerName: '最終処分終了日',
      type: 'date',
      hide:true,
      width: 120,
    },
    {
      field: 'secondFlgName',
      headerName: '1/2次',
      hide:true,
      width: 60,
    }
  ]

  const cols2PC = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'exhaustDate',
      headerName: '排出日',
      type: 'date',
      width: 100
    },
    {
      field: 'sect1TransportChargeName',
      headerName: '収集担当',
      width: 120
    },
    {
      field: 'garbageTag',
      headerName: 'ゴミタグ',
      hide: true,
      width: 200
    },
    {
      field: 'manifestNo',
      headerName: 'マニフェストＮｏ',
      width: 140
    },
    {
      field: 'statusName',
      headerName: '状態',
      width: 110
    },
    {
      field: 'ediSendName',
      headerName: 'JWNET登録状況',
      width: 210
    },
    {
      field: 'noticeName',
      headerName: 'JWNET通知情報',
      width: 210
    },
    {
      field: 'jwnetNo',
      headerName: '加入者番号',
      width: 100
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 300
    },
    {
      field: 'wasteName',  // wasteclassName
      headerName: '廃棄物種類',
      width: 200
    },
    {
      field: 'amount1',
      headerName: '数量',
      width: 80,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100
    },
    {
      field: 'packingAmount1',
      headerName: '荷姿数量',
      width:80,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 150
    },
    {
      field: 'memo1',
      headerName: '備考１',
      width: 160
    },
    {
      field: 'memo2',
      headerName: '備考２',
      width: 80
    },
    {
      field: 'memo3',
      headerName: '備考３',
      width: 80
    },
    {
      field: 'memo4',
      headerName: '備考４',
      width: 60
    },
    {
      field: 'memo5',
      headerName: '備考５',
      width: 60
    },
    {
      field: 'sect1TransportName',
      headerName: '区間１運搬事業者',
      hide: true,
      width: 240
    },
    {
      field: 'destination1Name',
      headerName: '区間１運搬先事業者',
      width: 240
    },
    {
      field: 'sect2TransportName',
      headerName: '区間２運搬事業者',
      hide: true,
      width: 240
    },
    {
      field: 'destination2Name',
      headerName: '区間２運搬先事業者',
      hide: true,
      width: 240
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場',
      width: 300
    },
    {
      field: 'lastProcessingEnd',
      headerName: '最終処分終了日',
      type: 'date',
      width: 120
    },
    {
      field: 'secondFlgName',
      headerName: '1/2次',
      width: 60
    }
  ]

  const cols4 = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'secondFlgName',
      headerName: '1/2次',
      width: 50
    },
    {
      field: 'sect1TransportName',
      headerName: '区間１運搬事業者',
      width: 250
    },
    {
      field: 'sect2TransportName',
      headerName: '区間２運搬事業者',
      hide: true,
      width: 250
    },
    {
      field: 'exhaustDate',
      headerName: '排出日',
      type: 'date',
      width: 100
    },
    {
      field: 'garbageTag',
      headerName: 'ゴミタグ',
      hide: true,
      width: 200
    },
    {
      field: 'manifestNo',
      headerName: 'マニフェストＮｏ',
      width: 140
    },
    {
      field: 'statusName',
      headerName: '状態',
      width: 110
    },
    {
      field: 'ediSendName',
      headerName: 'JWNET登録状況',
      width: 210
    },
    {
      field: 'noticeName',
      headerName: 'JWNET通知情報',
      width: 210
    },
    {
      field: 'jwnetNo',
      headerName: '加入者番号',
      width: 100
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 300
    },
    {
      field: 'wasteName',  // wasteclassName
      headerName: '廃棄物種類',
      width: 240
    },
    {
      field: 'amount1',
      headerName: '数量',
      width: 100,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100
    },
    {
      field: 'packingAmount1',
      headerName: '荷姿数量',
      width:90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 150
    },
    {
      field: 'memo1',
      headerName: '備考１',
      width: 150
    },
    {
      field: 'memo2',
      headerName: '備考２',
      hide: true,
      width: 150
    },
    {
      field: 'memo3',
      headerName: '備考３',
      hide: true,
      width: 150
    },
    {
      field: 'memo4',
      headerName: '備考４',
      hide: true,
      width: 150
    },
    {
      field: 'memo5',
      headerName: '備考５',
      hide: true,
      width: 150
    },
    {
      field: 'destination1Name',
      headerName: '区間１運搬先事業者',
      hide: true,
      width: 300
    },
    {
      field: 'destination2Name',
      headerName: '区間２運搬先事業者',
      hide: true,
      width: 300
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場',
      width: 300
    },
    {
      field: 'lastProcessingEnd',
      headerName: '最終処分終了日',
      type: 'date',
      hide: true,
      width: 120
    }
  ]

  if (currentUser.traderKbn == '1111111111'){   //事業場区分は管理者？
  return (
    <>
      <BrowserView>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>　区間１運搬事業者：</Typography>
          <TextField
            id="sect1TransportName"
            value={filter.sect1TransportName}
            onChange={e => setFilter({ ...filter, sect1TransportName: e.target.value })}
            label=""
            type="text"
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                startFilter();
              }
            }}
          />
          <Box p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>排出期間：</Typography>
          </Box>
          <TextField
            id="exhaustDate-start" value={filter.exhaustDateStart} onChange={e => setFilter({ ...filter, exhaustDateStart: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }}
          />
          <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
          <TextField
            id="exhaustDate-end" value={filter.exhaustDateEnd} onChange={e => setFilter({ ...filter, exhaustDateEnd: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　マニフェストNo：</Typography>
          <TextField
            id="manifestNo"
            value={filter.manifestNo}
            onChange={e => setFilter({ ...filter, manifestNo: e.target.value })}
            label=""
            type="text"
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                startFilter();
              }
            }}
          />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　状態：</Typography>
          <TextField
            id="statusName"
            value={filter.statusName}
            onChange={e => setFilter({ ...filter, statusName: e.target.value })}
            label=""
            type="text"
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                startFilter();
              }
            }}
          />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　排出業者：</Typography>
          <TextField
            id="exhaustName"
            value={filter.exhaustName}
            onChange={e => setFilter({ ...filter, exhaustName: e.target.value })}
            label=""
            type="text"
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                startFilter();
              }
            }}
          />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物名称：</Typography>
          <TextField
            id="wasteName"
            value={filter.wasteName}
            onChange={e => setFilter({ ...filter, wasteName: e.target.value })}
            label=""
            type="text"
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                startFilter();
              }
            }}
          />
          <Button
            type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
            データ表示
          </Button>
          　　MAX：3000件　初期表示：最新2日の排出データを表示
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={open2} >
            <CircularProgress color="inherit" />
      </Backdrop>

      <div style={{ width: '100%', height: 600 }}>
        <Box sx={{ height: 520, width: '100%' }}>
        <DataGridPro
          sx={styles.grid}
          columns={colsA}
          rows={filteredDataList}
          density="compact"
          // checkboxSelection
          // disableSelectionOnClick
          // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
          components={{
            Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            ColumnMenu: CustomColumnMenu,　// カスタムカラムメニューを指定する
          }}
          setDetailOpen2
          showColumnRightBorder // 列ヘッダセルの右側に線を引く
          showCellRightBorder   // セルの右側に線を引く
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          onRowClick={handleRowClick}
        />
        </Box>
        <EgsTGarbageEdiEdit
          isOpen={detailOpen1}
          doClose={() => setDetailOpen1(false)}
          ediId={dataList2.id}
          dataList2={dataList2}  // 0901
        />

        <TransportLoadDateEdit
          currentUser={currentUser}
          isOpen={detailOpen36}
          doClose={() => setDetailOpen36(false)}
          ediId={dataList2.id}
          dataList2={dataList2}
          sectId={sectId}
        />

        <TransportDateEdit                 // TransportDateEdit1
          currentUser={currentUser}
          isOpen={detailOpen30} // {detailOpen31}
          doClose={() => setDetailOpen30(false)}  // setDetailOpen31(false)}
          ediId={dataList2.id}
          dataList2={dataList2}
          sectId={sectId}
        />

        <MiddleDateEdit
          currentUser={currentUser}
          isOpen={detailOpen4}
          doClose={() => setDetailOpen4(false)}
          ediId={dataList2.id}
          dataList2={dataList2}
        />

        <LastDateEdit
          currentUser={currentUser}
          isOpen={detailOpen5}
          doClose={() => setDetailOpen5(false)}
          ediId={dataList2.id}
          dataList2={dataList2}
        />

        {/* <EgsTGarbageEdi2ndEdit       // 20230801 スピード対策のためコメント 内容チェック要
          currentUser={currentUser}
          isOpen={detailOpen6}
          doClose={() => setDetailOpen6(false)}
          ediId={dataList2.id}
          dataList2={dataList2}
        /> */}

        <EdiD01ItemCancel
          currentUser={currentUser}
          isOpen={detailOpen7}
          doClose={() => setDetailOpen7(false)}
          ediId={dataList2.id}
          dataList2={dataList2}
        />

        <LastDisposalEdit
          currentUser={currentUser}
          isOpen={detailOpen8}
          doClose={() => setDetailOpen8(false)}
          ediId={dataList2.id}
          dataList2={dataList2}
        />

        <Box component='div' sx={{ p: 1, textAlign: 'right' }}>
          <GarbageEdiPdf
            currentUser={currentUser}
            isOpen={detailOpen9}
            doClose={() => setDetailOpen9(false)}
            dataList2={dataList2}
          />
          <GarbageEdiPdfDL
            currentUser={currentUser}
            isOpen={detailOpen11}
            doClose={() => setDetailOpen11(false)}
            dataList={filteredDataList}
          />
          <Button variant="outlined" color='primary' style={{ marginRight: 10 }} onClick={handleOpen9}
            startIcon={<PictureAsPdfIcon />} disabled={!dataList2.id }>
            廃棄物実績報告PDF
          </Button>
          <Button
            variant="outlined"
            color='primary'
            style={{ marginRight: 10 }}
            onClick={handleOpen11}
            startIcon={<PictureAsPdfIcon />}
            disabled={filteredDataList.length === 0 || filteredDataList.length > 300}  // 300件を超える場合はボタンを無効化
          >
            PDF一括(MAX300件)ダウンロード
          </Button>
        </Box>
        <Box component='div' sx={{textAlign: 'left' }}>
          <GarbageEdiListTableForm
            dataList2={dataList2}
          />
      </Box>
      <Box component='div' sx={{ p: 1, textAlign: 'right' }}>
{/*}        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen1} disabled={!dataList2.id}>  */}
        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen1}
          disabled={(dataList2.ediSendName == null || dataList2.secondFlg === '1' || dataList2.ediSendFlg === '07' || dataList2.ediSendFlg === '08' || dataList2.ediSendFlg === '09' )}>
          マニフェスト情報修正
        </Button>
{/*}
        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen2} disabled={!(dataList2.secondFlg === '1')}>
          １次マニフェスト修正
        </Button>
*/}
        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen6}
          disabled={(dataList2.ediSendName == null || dataList2.secondFlg !== '1' || dataList2.ediSendFlg === '07' || dataList2.ediSendFlg === '08' || dataList2.ediSendFlg === '09' )}>
          ２次マニフェスト情報修正
        </Button>

        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen4}
          disabled={!dataList2.id || dataList2.ediSendFlg === '07' || dataList2.ediSendFlg === '08' || dataList2.ediSendFlg === '09' }>
          処分終了報告（中間）修正
        </Button>

        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen5}
          disabled={!dataList2.id || dataList2.ediSendFlg === '07' || dataList2.ediSendFlg === '08' || dataList2.ediSendFlg === '09' }>
          処分終了報告（最終）修正
        </Button>

        <Button variant="contained" color='secondary' style={{ marginRight: 10 }} onClick={handleOpen7}
          disabled={!dataList2.id || dataList2.ediSendFlg === '07' || dataList2.ediSendFlg === '08' || dataList2.ediSendFlg === '09' }>
          マニフェスト取消、区間・処分報告取消
        </Button>
        </Box>
      <Box component='div' sx={{ p: 1, textAlign: 'right' }}>
{/*}
        <Button variant="contained" color='secondary' style={{ marginRight: 10 }} onClick={cancelRows} disabled={!dataList2.id}>
          取消（論理削除）
        </Button>

        <Button variant="contained" color='secondary' onClick={delRows} disabled={!dataList2.id}>
          物理削除
        </Button>
*/}
{/*
        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen36}
          disabled={!dataList2.sect1TransportId || dataList2.ediSendFlg === '07' || dataList2.ediSendFlg === '08' || dataList2.ediSendFlg === '09' }>
          　区間１積込日修正　
        </Button>
*/}
        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen37}
          disabled={!dataList2.sect2TransportId || dataList2.ediSendFlg === '07' || dataList2.ediSendFlg === '08' || dataList2.ediSendFlg === '09' }>
          　区間２積込日修正　
        </Button>
        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen38}
          disabled={!dataList2.sect3TransportId || dataList2.ediSendFlg === '07' || dataList2.ediSendFlg === '08' || dataList2.ediSendFlg === '09' }>
          　区間３積込日修正　
        </Button>
        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen39}
          disabled={!dataList2.sect4TransportId || dataList2.ediSendFlg === '07' || dataList2.ediSendFlg === '08' || dataList2.ediSendFlg === '09' }>
          　区間４積込日修正　
        </Button>
        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen3A}
          disabled={!dataList2.sect5ransportId || dataList2.ediSendFlg === '07' || dataList2.ediSendFlg === '08' || dataList2.ediSendFlg === '09' }>
          　区間５積込日修正　
        </Button>
        　　　　　　　　　　　
      <Box component='div' sx={{ p: 1, textAlign: 'right' }}>

      </Box>

{/*}
        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen31} disabled={!dataList2.sect1TransportId}>
          区間１～５運搬終了報告修正
        </Button>
*/}
{/*}        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen31} disabled={!dataList2.sect1TransportId}>  */}
        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen31}
          disabled={!dataList2.sect1TransportId || dataList2.ediSendFlg === '07' || dataList2.ediSendFlg === '08' || dataList2.ediSendFlg === '09' }>
          区間１運搬終了報告修正
        </Button>
{/*}        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen32} disabled={!dataList2.sect2TransportId}>  */}
        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen32}
          disabled={!dataList2.sect2TransportId || dataList2.ediSendFlg === '07' || dataList2.ediSendFlg === '08' || dataList2.ediSendFlg === '09' }>
          区間２運搬終了報告修正
        </Button>
{/*}        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen33} disabled={!dataList2.sect3TransportId}>  */}
        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen33}
          disabled={!dataList2.sect3TransportId || dataList2.ediSendFlg === '07' || dataList2.ediSendFlg === '08' || dataList2.ediSendFlg === '09' }>
          区間３運搬終了報告修正
        </Button>
{/*}        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen34} disabled={!dataList2.sect4TransportId}>  */}
        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen34}
          disabled={!dataList2.sect4TransportId || dataList2.ediSendFlg === '07' || dataList2.ediSendFlg === '08' || dataList2.ediSendFlg === '09' }>
          区間４運搬終了報告修正
        </Button>
{/*}        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen35} disabled={!dataList2.sect5TransportId}>  */}
        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen35}
          disabled={!dataList2.sect5TransportId || dataList2.ediSendFlg === '07' || dataList2.ediSendFlg === '08' || dataList2.ediSendFlg === '09' }>
          区間５運搬終了報告修正
        </Button>

        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen8}
          disabled={(dataList2.ediSendName == null || dataList2.secondFlg !== '1' || dataList2.ediSendFlg === '07' || dataList2.ediSendFlg === '08' || dataList2.ediSendFlg === '09' )}>
          最終処分終了報告
        </Button>
      </Box>

      <div className="container">
        {
          dataList3.map((dataList3) =>(
            <div key={dataList3.id} className="card" onClick={e => {
              e.preventDefault();
              setCurData(dataList3);
              setOpen(true);
            }}>
            <img src={dataList3.imageName.url}  alt="" style={{ width: "150px" }}/>
            <div className="card-content">
                <h1 className="card-title">
                {statusName[dataList3.status]}　　{dataList3.content}<br />
                {dataList3.gpsLat}<br />{dataList3.gpsLon}
                </h1>
              </div>
            </div>
          )
        )}
      </div>
      <Dialog
        open={isOpen}
        onClose={() => {}}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'xl'}
      >
        <DialogContent>
          <div key={curData?.id} className="card">
            <img src={curData?.imageName?.url} alt="" style={{
              width: "100%"
            }}/>
          </div>
          <Button onClick={() => setOpen(false)} style={{ marginTop: 16, float: 'right' }}>閉じる</Button>
        </DialogContent>
      </Dialog>

      <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
        <h3>ＱＲコード（情報更新用）</h3>
        <QRCode value={qrCodeUrl} />
        <p>{qrCodeUrl}</p>
      </Box>
      </div>
    </BrowserView>
    </>
  );

}else if ((currentUser.traderKbn == '0001001000') ||
          (currentUser.traderKbn == '0001000000')){   //事業場区分は中間処理・排出事業者？
  return (
    <>
    <BrowserView>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
          <Box p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>排出期間：</Typography>
          </Box>
          <TextField
            id="exhaustDate-start" value={filter.exhaustDateStart} onChange={e => setFilter({ ...filter, exhaustDateStart: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }}
          />
          <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
          <TextField
            id="exhaustDate-end" value={filter.exhaustDateEnd} onChange={e => setFilter({ ...filter, exhaustDateEnd: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　マニフェストNo：</Typography>
          <TextField
            id="manifestNo"
            value={filter.manifestNo}
            onChange={e => setFilter({ ...filter, manifestNo: e.target.value })}
            label=""
            type="text"
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                startFilter();
              }
            }}
          />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　状態：</Typography>
          <TextField
            id="statusName"
            value={filter.statusName}
            onChange={e => setFilter({ ...filter, statusName: e.target.value })}
            label=""
            type="text"
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                startFilter();
              }
            }}
          />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　排出業者：</Typography>
          <TextField
            id="exhaustName"
            value={filter.exhaustName}
            onChange={e => setFilter({ ...filter, exhaustName: e.target.value })}
            label=""
            type="text"
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                startFilter();
              }
            }}
          />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物名称：</Typography>
          <TextField
            id="wasteName"
            value={filter.wasteName}
            onChange={e => setFilter({ ...filter, wasteName: e.target.value })}
            label=""
            type="text"
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                startFilter();
              }
            }}
          />
          <Button
            type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
            データ表示
          </Button>
          　　初期表示：最新60日の排出データを表示
        </Grid>
      </Grid>

      <Backdrop className={classes.backdrop} open={open2} >
            <CircularProgress color="inherit" />
      </Backdrop>

      <div style={{ width: '100%', height: 600 }}>
        <Box sx={{ height: 520, width: '100%' }}>
          <DataGridPro
            sx={styles.grid}
            columns={cols4}
            rows={filteredDataList}
            density="compact"
            // checkboxSelection
            // disableSelectionOnClick
            // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}setDetailOpen2
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
          />
        </Box>
        <EgsTGarbageEdiEdit
          isOpen={detailOpen1}
          doClose={() => setDetailOpen1(false)}
          ediId={dataList2.id}
          dataList2={dataList2}  // 0901
        />

        <MiddleDateEdit
          currentUser={currentUser}
          isOpen={detailOpen4}
          doClose={() => setDetailOpen4(false)}
//          ediId={dataList2.id}
          dataList2={dataList2}
//          garbageTag={garbageTag}
        />

        <LastDateEdit
          currentUser={currentUser}
          isOpen={detailOpen5}
          doClose={() => setDetailOpen5(false)}
//          ediId={dataList2.id}
          dataList2={dataList2}
//          garbageTag={garbageTag}
        />

        <EgsTGarbageEdi2ndEdit
          currentUser={currentUser}
          isOpen={detailOpen6}
          doClose={() => setDetailOpen6(false)}
          ediId={dataList2.id}
          dataList2={dataList2}
//          garbageTag={garbageTag}
        />

        <EdiD01ItemCancel
          currentUser={currentUser}
          isOpen={detailOpen7}
          doClose={() => setDetailOpen7(false)}
          ediId={dataList2.id}
          dataList2={dataList2}
//          garbageTag={garbageTag}
        />

        <LastDisposalEdit
          currentUser={currentUser}
          isOpen={detailOpen8}
          doClose={() => setDetailOpen8(false)}
          ediId={dataList2.id}
          dataList2={dataList2}
//          garbageTag={garbageTag}
        />
        <Box component='div' sx={{ p: 1, textAlign: 'right' }}>
          <GarbageEdiPdf
            currentUser={currentUser}
            isOpen={detailOpen9}
            doClose={() => setDetailOpen9(false)}
            dataList2={dataList2}
          />
          <GarbageEdiPdfDL
            currentUser={currentUser}
            isOpen={detailOpen11}
            doClose={() => setDetailOpen11(false)}
            dataList={filteredDataList}
          />
          <Button variant="outlined" color="secondary" style={{ marginRight: 10 }} onClick={handleOpen10}
            disabled={(dataList2.ediSendName == null || dataList2.secondFlg === '1' || dataList2.ediSendFlg === '07' || dataList2.ediSendFlg === '08' || dataList2.ediSendFlg === '09' )}>
            マニフェスト情報修正・取消依頼
          </Button>
          <Button variant="outlined" color='primary' style={{ marginRight: 10 }} onClick={handleOpen9}
            startIcon={<PictureAsPdfIcon />} disabled={!dataList2.id }>
            廃棄物実績報告PDF
          </Button>
          <Button
            variant="outlined"
            color='primary'
            style={{ marginRight: 10 }}
            onClick={handleOpen11}
            startIcon={<PictureAsPdfIcon />}
            disabled={filteredDataList.length === 0 || filteredDataList.length > 300}  // 300件を超える場合はボタンを無効化
          >
            PDF一括(MAX300件)ダウンロード
          </Button>
        </Box>
        <Box component='div' sx={{textAlign: 'left' }}>
          <GarbageEdiListTableForm
            dataList2={dataList2}
          />
          <EditDelRequest
            isOpen={detailOpen10}
            doClose={() => setDetailOpen10(false)}
            currentUser={currentUser}
            ediId={dataList2.id}
            dataList2={dataList2}  // 0901
          />
          <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen8}
            disabled={(dataList2.ediSendName == null || dataList2.secondFlg !== '1' || dataList2.ediSendFlg === '07' || dataList2.ediSendFlg === '08' || dataList2.ediSendFlg === '09' )}>
            最終処分終了報告
          </Button>
        </Box>

      <div className="container">
        {
          dataList3.map((dataList3) =>(
            <div key={dataList3.id} className="card" onClick={e => {
              e.preventDefault();
              setCurData(dataList3);
              setOpen(true);
            }}>
            <img src={dataList3.imageName.url}  alt="" style={{ width: "150px" }}/>
            <div className="card-content">
                <h1 className="card-title">
                {statusName[dataList3.status]}　　{dataList3.content}<br />
                {dataList3.gpsLat}<br />{dataList3.gpsLon}
                </h1>
              </div>
            </div>
          )
        )}
        </div>
        <Dialog
          open={isOpen}
          onClose={() => {}}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth={'xl'}
        >
          <DialogContent>
            <div key={curData?.id} className="card">
              <img src={curData?.imageName?.url} alt="" style={{
                width: "100%"
              }}/>
            </div>
            <Button onClick={() => setOpen(false)} style={{ marginTop: 16, float: 'right' }}>閉じる</Button>
          </DialogContent>
        </Dialog>

        <Box component='div' sx={{ textAlign: 'left' }}>
          <h3>ＱＲコード（情報更新用）</h3>
          <QRCode value={qrCodeUrl} />
          <p>{qrCodeUrl}</p>
        </Box>
      </div>
    </BrowserView>
    </>
  );

}else if (currentUser.traderKbn == '0100000000'){   //事業場区分は収集運搬業者？

  return (
    <>
      {mbluser ? (
        <div>
      <Grid
        container
        spacing={2}
      >
        <div style={{ width: '100%', height: 600 }}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
              sx={styles.grid}
              columns={cols2MB}
              rows={filteredDataList}
              density="compact"
              // checkboxSelection
              // disableSelectionOnClick
              // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
              components={{
                Toolbar: CustomToolbar,　// カスタムツールバーを指定する
              }}setDetailOpen2
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleRowClick}
            />
          </Box>
          <Box component='div' sx={{ p: 1, textAlign: 'right' }}>
            <Button variant="outlined" color='primary' style={{ marginRight: 120 }}  onClick={handleOpenMbl2} disabled={!dataList2.id }>
              排出仮登録一覧
            </Button>
            <Button variant="outlined" color='primary' style={{ marginRight: 10 }}  onClick={handleOpenMbl1} disabled={!dataList2.id }>
              排出仮登録（同じ内容で作成）
            </Button>
          </Box>
        </div>
      </Grid>
      </div>
      ):(
      <div>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
          <Box p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>排出期間：</Typography>
          </Box>
          <TextField
            id="exhaustDate-start" value={filter.exhaustDateStart} onChange={e => setFilter({ ...filter, exhaustDateStart: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }}
          />
          <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
          <TextField
            id="exhaustDate-end" value={filter.exhaustDateEnd} onChange={e => setFilter({ ...filter, exhaustDateEnd: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　マニフェストNo：</Typography>
          <TextField
            id="manifestNo"
            value={filter.manifestNo}
            onChange={e => setFilter({ ...filter, manifestNo: e.target.value })}
            label=""
            type="text"
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                startFilter();
              }
            }}
          />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　状態：</Typography>
          <TextField
            id="statusName"
            value={filter.statusName}
            onChange={e => setFilter({ ...filter, statusName: e.target.value })}
            label=""
            type="text"
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                startFilter();
              }
            }}
          />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　排出業者：</Typography>
          <TextField
            id="exhaustName"
            value={filter.exhaustName}
            onChange={e => setFilter({ ...filter, exhaustName: e.target.value })}
            label=""
            type="text"
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                startFilter();
              }
            }}
          />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物名称：</Typography>
          <TextField
            id="wasteName"
            value={filter.wasteName}
            onChange={e => setFilter({ ...filter, wasteName: e.target.value })}
            label=""
            type="text"
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                startFilter();
              }
            }}
          />
          <Button
            type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
            データ表示
          </Button>
          　　初期表示：最新30日の排出データを表示
        </Grid>
      </Grid>

      <Backdrop className={classes.backdrop} open={open2} >
            <CircularProgress color="inherit" />
      </Backdrop>

      <div style={{ width: '100%', height: 600 }}>
        <Box sx={{ height: 520, width: '100%' }}>
          <DataGridPro
            sx={styles.grid}
            columns={cols2PC}
            rows={filteredDataList}
            density="compact"
            // checkboxSelection
            // disableSelectionOnClick
            // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}setDetailOpen2
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
          />
        </Box>

        <Box component='div' sx={{ p: 1, textAlign: 'right' }}>
          <GarbageEdiPdf
            currentUser={currentUser}
            isOpen={detailOpen9}
            doClose={() => setDetailOpen9(false)}
            dataList2={dataList2}
          />
          <GarbageEdiPdfDL
            currentUser={currentUser}
            isOpen={detailOpen11}
            doClose={() => setDetailOpen11(false)}
            dataList={filteredDataList}
          />
          <Button variant="outlined" color="secondary" style={{marginRight: 10 }} onClick={handleOpen10}
            disabled={(dataList2.ediSendName == null || dataList2.secondFlg === '1' || dataList2.ediSendFlg === '07' || dataList2.ediSendFlg === '08' || dataList2.ediSendFlg === '09' )}>
            マニフェスト情報修正・取消依頼
          </Button>
          <Button variant="outlined" color='primary' style={{ marginRight: 10 }} onClick={handleOpen9}
            startIcon={<PictureAsPdfIcon />} disabled={!dataList2.id }>
            廃棄物実績報告PDF
          </Button>
          <Button
            variant="outlined"
            color='primary'
            style={{ marginRight: 10 }}
            onClick={handleOpen11}
            startIcon={<PictureAsPdfIcon />}
            disabled={filteredDataList.length === 0 || filteredDataList.length > 300}  // 300件を超える場合はボタンを無効化
          >
            PDF一括(MAX300件)ダウンロード
          </Button>
        </Box>
        <Box component='div' sx={{textAlign: 'left' }}>
         <GarbageEdiListTableForm
          dataList2={dataList2}
        />
        <EditDelRequest
          isOpen={detailOpen10}
          doClose={() => setDetailOpen10(false)}
          currentUser={currentUser}
          ediId={dataList2.id}
          dataList2={dataList2}  // 0901
        />

      </Box>

      <div className="container">
        {
          dataList3.map((dataList3) =>(
            <div key={dataList3.id} className="card" onClick={e => {
              e.preventDefault();
              setCurData(dataList3);
              setOpen(true);
            }}>
            <img src={dataList3.imageName.url}  alt="" style={{ width: "150px" }}/>
            <div className="card-content">
                <h1 className="card-title">
                {statusName[dataList3.status]}　　{dataList3.content}<br />
                {dataList3.gpsLat}<br />{dataList3.gpsLon}
                </h1>
              </div>
            </div>
          )
        )}
      </div>
      <Dialog
        open={isOpen}
        onClose={() => {}}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'xl'}
      >
        <DialogContent>
          <div key={curData?.id} className="card">
            <img src={curData?.imageName?.url} alt="" style={{
              width: "100%"
            }}/>
          </div>
          <Button onClick={() => setOpen(false)} style={{ marginTop: 16, float: 'right' }}>閉じる</Button>
        </DialogContent>
      </Dialog>

      <Box component='div' sx={{ textAlign: 'left' }}>
        <h3>ＱＲコード（情報更新用）</h3>
        <QRCode value={qrCodeUrl} />
        <p>{qrCodeUrl}</p>
      </Box>
      </div>
      </div>
      )}
    </>
  );
}else if (currentUser.traderKbn == '0000000001'){   //事業場区分はグループ管理者？
  return (
    <>
    <MobileView>
      <Grid
        container
        spacing={2}
      >
        <div style={{ width: '100%', height: 600 }}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
              sx={styles.grid}
              columns={colsG}
              rows={filteredDataList}
              density="compact"
              // checkboxSelection
              // disableSelectionOnClick
              // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
              components={{
                Toolbar: CustomToolbar,　// カスタムツールバーを指定する
              }}setDetailOpen2
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleRowClick}
            />
          </Box>
        </div>
      </Grid>
    </MobileView>
    <BrowserView>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>　区間１運搬事業者：</Typography>
          <TextField
            id="sect1TransportName"
            value={filter.sect1TransportName}
            onChange={e => setFilter({ ...filter, sect1TransportName: e.target.value })}
            label=""
            type="text"
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                startFilter();
              }
            }}
          />
          <Box p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>排出期間：</Typography>
          </Box>
          <TextField
            id="exhaustDate-start" value={filter.exhaustDateStart} onChange={e => setFilter({ ...filter, exhaustDateStart: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }}
          />
          <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
          <TextField
            id="exhaustDate-end" value={filter.exhaustDateEnd} onChange={e => setFilter({ ...filter, exhaustDateEnd: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          {/* <Typography variant="h7" style={{ fontWeight: 700 }}>　マニフェストNo：</Typography>
          <TextField id="manifestNo" value={filter.manifestNo} onChange={e => setFilter({ ...filter, manifestNo: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} /> */}
          <Typography variant="h7" style={{ fontWeight: 700 }}>　状態：</Typography>
          <TextField
            id="statusName"
            value={filter.statusName}
            onChange={e => setFilter({ ...filter, statusName: e.target.value })}
            label=""
            type="text"
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                startFilter();
              }
            }}
          />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　排出業者：</Typography>
          <TextField
            id="exhaustName"
            value={filter.exhaustName}
            onChange={e => setFilter({ ...filter, exhaustName: e.target.value })}
            label=""
            type="text"
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                startFilter();
              }
            }}
          />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物名称：</Typography>
          <TextField
            id="wasteName"
            value={filter.wasteName}
            onChange={e => setFilter({ ...filter, wasteName: e.target.value })}
            label=""
            type="text"
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                startFilter();
              }
            }}
          />
          <Button
            type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
            データ表示
          </Button>
          　　初期表示：最新30日の排出データを表示
        </Grid>
      </Grid>

      <Backdrop className={classes.backdrop} open={open2} >
            <CircularProgress color="inherit" />
      </Backdrop>
      
      <div style={{ width: '100%', height: 600 }}>
        <Box sx={{ height: 520, width: '100%' }}>
          <DataGridPro
            sx={styles.grid}
            columns={colsG}
            rows={filteredDataList}
            density="compact"
            // checkboxSelection
            // disableSelectionOnClick
            // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}setDetailOpen2
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
          />
        </Box>
        <EgsTGarbageEdiEdit
          isOpen={detailOpen1}
          doClose={() => setDetailOpen1(false)}
          ediId={dataList2.id}
          dataList2={dataList2}  // 0901
        />
        <Box component='div' sx={{ p: 1, textAlign: 'right' }}>
          <GarbageEdiPdf
            currentUser={currentUser}
            isOpen={detailOpen9}
            doClose={() => setDetailOpen9(false)}
            dataList2={dataList2}
          />
          <GarbageEdiPdfDL
            currentUser={currentUser}
            isOpen={detailOpen11}
            doClose={() => setDetailOpen11(false)}
            dataList={filteredDataList}
          />
          <Button variant="outlined" color='primary' style={{ marginRight: 10 }} onClick={handleOpen9}
            startIcon={<PictureAsPdfIcon />} disabled={!dataList2.id }>
            廃棄物実績報告PDF
          </Button>
          <Button
            variant="outlined"
            color='primary'
            style={{ marginRight: 10 }}
            onClick={handleOpen11}
            startIcon={<PictureAsPdfIcon />}
            disabled={filteredDataList.length === 0 || filteredDataList.length > 300}  // 300件を超える場合はボタンを無効化
          >
            PDF一括(MAX300件)ダウンロード
          </Button>
        </Box>
        <Box component='div' sx={{textAlign: 'left' }}>
          <GarbageEdiListTableForm
            dataList2={dataList2}
          />
        </Box>

      <div className="container">
        {
          dataList3.map((dataList3) =>(
            <div key={dataList3.id} className="card" onClick={e => {
              e.preventDefault();
              setCurData(dataList3);
              setOpen(true);
            }}>
            <img src={dataList3.imageName.url}  alt="" style={{ width: "150px" }}/>
            <div className="card-content">
                <h1 className="card-title">
                {statusName[dataList3.status]}　　{dataList3.content}<br />
                {dataList3.gpsLat}<br />{dataList3.gpsLon}
                </h1>
              </div>
            </div>
          )
        )}
      </div>
      <Dialog
        open={isOpen}
        onClose={() => {}}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'xl'}
      >
        <DialogContent>
          <div key={curData?.id} className="card">
            <img src={curData?.imageName?.url} alt="" style={{
              width: "100%"
            }}/>
          </div>
          <Button onClick={() => setOpen(false)} style={{ marginTop: 16, float: 'right' }}>閉じる</Button>
        </DialogContent>
      </Dialog>

      <Box component='div' sx={{ textAlign: 'left' }}>
        <h3>ＱＲコード（情報更新用）</h3>
        <QRCode value={qrCodeUrl} />
        <p>{qrCodeUrl}</p>
      </Box>
      </div>
    </BrowserView>
    </>
  );

}else{                                       //事業場区分は管理者以外？
  return (
    <>
      <MobileView>
      <Grid
        container
        spacing={2}
      >
        <div style={{ width: '100%', height: 600 }}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
              sx={styles.grid}
              columns={cols1}
              rows={filteredDataList}
              density="compact"
              // checkboxSelection
              // disableSelectionOnClick
              // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
              components={{
                Toolbar: CustomToolbar,　// カスタムツールバーを指定する
              }}setDetailOpen2
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleRowClick}
            />
          </Box>
          <Box component='div' sx={{ p: 1, textAlign: 'right' }}>
          <SpaceRow height={10} />
        <table>
        <tr>
          <th>排出日</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.exhaustDate}</Typography>
            </td>
          </tr>
          <tr>
            <th>マニフェストＮｏ</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.manifestNo}</Typography>
            </td>
          </tr>
          <tr>
            <th>廃棄物種類</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.wasteName}</Typography>
            </td>
          </tr>
          <tr>
            <th>処分方法</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.disposalMethodName}</Typography>
            </td>
          </tr>
          <tr>
          <th>数量　（単位）</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.amount1}　（{dataList2.unitName}）</Typography>
            </td>
          </tr>
          <tr>
            <th>荷姿数量　（荷姿）</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.packingAmount1}　（{dataList2.packingName}）</Typography>
            </td>
          </tr>

        </table>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>詳細</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <table>
            <tr>
              <th>運搬先事業場</th>
              <td>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.destination1Name}</Typography>
              </td>
            </tr>
            <tr>
              <th>最終処分場</th>
              <td>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.lastProcessingTraderName}</Typography>
              </td>
            </tr>
              <tr>
                <th>備考１</th>
                <td>
                    <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.memo1}</Typography>
                </td>
              </tr>
              <tr>
                <th>備考２</th>
                <td>
                    <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.memo2}</Typography>
                </td>
              </tr>
              <tr>
                <th>備考３</th>
                <td>
                    <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.memo3}</Typography>
                </td>
              </tr>
              <tr>
                <th>備考４</th>
                <td>
                    <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.memo4}</Typography>
                </td>
              </tr>
              <tr>
                <th>備考５</th>
                <td>
                    <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.memo5}</Typography>
                </td>
              </tr>
            </table>
          </AccordionDetails>
        </Accordion>

        </Box>

        </div>
      </Grid>
    </MobileView>

    <BrowserView>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
          <Box p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>排出期間：</Typography>
          </Box>
          <TextField
            id="exhaustDate-start" value={filter.exhaustDateStart} onChange={e => setFilter({ ...filter, exhaustDateStart: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }}
          />
          <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
          <TextField
            id="exhaustDate-end" value={filter.exhaustDateEnd} onChange={e => setFilter({ ...filter, exhaustDateEnd: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          {/* <Typography variant="h7" style={{ fontWeight: 700 }}>　マニフェストNo：</Typography>
          <TextField id="manifestNo" value={filter.manifestNo} onChange={e => setFilter({ ...filter, manifestNo: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} /> */}
          <Typography variant="h7" style={{ fontWeight: 700 }}>　状態：</Typography>
          <TextField
            id="statusName"
            value={filter.statusName}
            onChange={e => setFilter({ ...filter, statusName: e.target.value })}
            label=""
            type="text"
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                startFilter();
              }
            }}
          />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物名称：</Typography>
          <TextField
            id="wasteName"
            value={filter.wasteName}
            onChange={e => setFilter({ ...filter, wasteName: e.target.value })}
            label=""
            type="text"
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                startFilter();
              }
            }}
          />
          <Button
            type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
            データ表示
          </Button>
          　　初期表示：最新30日の排出データを表示
        </Grid>
      </Grid>

      <div style={{ width: '100%', height: 600 }}>
        <Box sx={{ height: 520, width: '100%' }}>
          <DataGridPro
            sx={styles.grid}
            columns={cols1}
            rows={filteredDataList}
            density="compact"
            // checkboxSelection
            // disableSelectionOnClick
            // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}setDetailOpen2
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
          />
        </Box>
        <EgsTGarbageEdiEdit
          isOpen={detailOpen1}
          doClose={() => setDetailOpen1(false)}
          ediId={dataList2.id}
          dataList2={dataList2}  // 0901
        />
        <Box component='div' sx={{ p: 1, textAlign: 'right' }}>
          <GarbageEdiPdf
            currentUser={currentUser}
            isOpen={detailOpen9}
            doClose={() => setDetailOpen9(false)}
            dataList2={dataList2}
          />
          <GarbageEdiPdfDL
            currentUser={currentUser}
            isOpen={detailOpen11}
            doClose={() => setDetailOpen11(false)}
            dataList={filteredDataList}
          />
          <Button variant="outlined" color='primary' style={{ marginRight: 10 }} onClick={handleOpen9}
            startIcon={<PictureAsPdfIcon />} disabled={!dataList2.id }>
            廃棄物実績報告PDF
          </Button>
          <Button
            variant="outlined"
            color='primary'
            style={{ marginRight: 10 }}
            onClick={handleOpen11}
            startIcon={<PictureAsPdfIcon />}
            disabled={filteredDataList.length === 0 || filteredDataList.length > 300}  // 300件を超える場合はボタンを無効化
          >
            PDF一括(MAX300件)ダウンロード
          </Button>
        </Box>
        <Box component='div' sx={{textAlign: 'left' }}>
          <GarbageEdiListTableForm
            dataList2={dataList2}
          />
        </Box>

      <div className="container">
        {
          dataList3.map((dataList3) =>(
            <div key={dataList3.id} className="card" onClick={e => {
              e.preventDefault();
              setCurData(dataList3);
              setOpen(true);
            }}>
            <img src={dataList3.imageName.url}  alt="" style={{ width: "150px" }}/>
            <div className="card-content">
                <h1 className="card-title">
                {statusName[dataList3.status]}　　{dataList3.content}<br />
                {dataList3.gpsLat}<br />{dataList3.gpsLon}
                </h1>
              </div>
            </div>
          )
        )}
      </div>
      <Dialog
        open={isOpen}
        onClose={() => {}}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'xl'}
      >
        <DialogContent>
          <div key={curData?.id} className="card">
            <img src={curData?.imageName?.url} alt="" style={{
              width: "100%"
            }}/>
          </div>
          <Button onClick={() => setOpen(false)} style={{ marginTop: 16, float: 'right' }}>閉じる</Button>
        </DialogContent>
      </Dialog>

      <Box component='div' sx={{ textAlign: 'left' }}>
        <h3>ＱＲコード（情報更新用）</h3>
        <QRCode value={qrCodeUrl} />
        <p>{qrCodeUrl}</p>
      </Box>
      </div>
    </BrowserView>
    </>
  );

}
}
const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
}
export default GarbageEdiListTable;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
