import React, { useEffect, useState, useContext, useRef } from 'react';
import { BrowserView, MobileView } from "react-device-detect"
import { useHistory } from 'react-router-dom';
import { getEgsTGarbageTagUser } from '../../lib/api/egs_t_garbage_tag';
import { getEgsMTraderList, getEgsMTraderUser } from '../../lib/api/egs_m_trader';
//import { getGarbageDetailList,getGarbageDetailUser } from '../../lib/api/garbage_detail';
//import { getGarbageDetailUserOpe,getGarbageDetailOperation, getGarbageDetailUser,  getGarbageDetailGroup1, getGarbageDetailGroup2  } from '../../lib/api/garbage_detail';
import { getGarbageMobileUserOpe,getGarbageMobileUser,getGarbageMobileUserToday } from '../../lib/api/garbage_mobile';
import { getGarbagePcOperation } from '../../lib/api/garbage_pc';
import { getGarbageDetailOperation } from '../../lib/api/garbage_detail';
import { deleteEgsTGarbageEdi } from '../../lib/api/egs_t_garbage_edi';
import { getProcessFlowReserveNo } from '../../lib/api/process_flow';
import { getChargeUser } from '../../lib/api/charge';
import { getEditDelRequestList, getEditDelRequestUser, getEditDelRequestExhaust } from '../../lib/api/edit_del_request';

import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import RsvQRReaderDialog from './RsvQRReaderDialog';
import AdmSignInt from '../users/AdmSignInt'
// style
import { Button,Backdrop,CircularProgress,makeStyles,Typography,Link,TextField,Switch,FormControlLabel,Badge } from '@material-ui/core';

// component
import GarbageEdiListTable from '../garbage_edi/GarbageEdiListTable';
import EditDelReqListTable from '../garbage_edi/EditDelReqListTable';
import SpaceRow from '../commons/SpaceRow';
// context
import { AuthContext } from '../../App';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function SysLginBtn() {
  const { currentUser } =  useContext(AuthContext);
  const sysuser = Cookies.get('sys_user');
  // console.log("sysuser",sysuser);
  // console.log("currentUser",currentUser);
  if ((sysuser !== undefined ) && (currentUser.traderKbn !== '1111111111')){
    return (
      <AdmSignInt
      traderCd={process.env.REACT_APP_ADMIN_ID}
      rawpasswd={process.env.REACT_APP_ADMIN_PASSWD}
      buttonType='管理サイトへ戻る'
    />
    )
  }else{
    return(
      <h6></h6>
    );
  };
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://www.ecotechl.com/">
        ecotechl.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Home = () => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
    const history = useHistory();
  const [mbluser, setMbluser] = useState(Cookies.get('mbl_user')); // mbluserの状態を管理
  const [open, setOpen] = useState(false);
  const [dataList, setDataList] = useState([]);

  const [reqdataList, setReqDataList] = useState([]);
  const [value, setValue] = useState({
    telNo: '',
    wasteKind: '',
  });
  const inputRef1 = useRef(null);
  const [inputError1, setInputError1] = useState(false);
  const inputRef2 = useRef(null);
  const [inputError2, setInputError2] = useState(false);

  const classes = useStyles();
  const [dialogopen, setDialogOpen] = React.useState(false);
  const [qrcodeValue, setQrcodeValue] = React.useState();
  const [reserveNo, setReserveNo] = useState('');

  const [telNo, setTelNo] = useState('');
  const [wasteKind, setWasteKind] = useState('');
  const [checked, setChecked] = useState(true);
  const [mbluserid, setMbluserid] = useState('');
  const [mblusername, setMblusername] = useState('');
  const [showEditDelReq, setShowEditDelReq] = useState(false);
  const isEligibleTrader = ['0100000000', '0001000000', '0001001000','1111111111'].includes(currentUser.traderKbn);

  var res;
  var reqdata;
  var retrader;
  var mbluser2;

  useEffect(() => {
    const exhaustTelno = Cookies.get('exhaust_telno');
    setValue({telNo: exhaustTelno})
    setChecked(!checked);
    handleGetList();
  }, []);

  // useEffect(() => {
  //   const mbluserString = Cookies.get('mbl_user');
  //   if (mbluserString) {
  //     const mbluser2 = mbluserString.split(':');
  //     setMblusername(mbluser2[2]); // 状態を更新
  //   }
  // }, []);

  const handleGetList = async () => {

    const mbluser = Cookies.get('mbl_user');
    console.log("mbluser",mbluser);

    if (navigator.userAgent.match(/iPhone|Android.+Mobile/) || mbluser) {
      if (currentUser.traderKbn == '0100000000'){  // 収集運搬業者
        if (mbluser === undefined ){
          history.push(`/mbl_user_set/${currentUser.traderCd}` );
        }else{
          // mbluserが存在する場合の処理
          setMbluser(mbluser); // 状態を更新
          setOpen(!open);

          mbluser2 = mbluser.split(':');
          setMbluserid(mbluser2[0]); // id
          setMblusername(mbluser2[2]); // 状態を更新
          // // console.log("mbluser2",mbluser2)
          // mblusername = mbluser2[2];
          // console.log("mblusername",mblusername);
          if (checked == true){
            try {
              // res = await getGarbageDetailUserOpe(mbluser2[0]);  20230807chg Mobileへ
              res = await getGarbageMobileUserToday(mbluser2[0]);
            } catch (e) {
              console.log(e);
              console.log(e.response);
            }            
          }else{
            try {
              // res = await getGarbageDetailUserOpe(mbluser2[0]);  20230807chg Mobileへ
              res = await getGarbageMobileUserOpe(mbluser2[0]);
            } catch (e) {
              console.log(e);
              console.log(e.response);
            }
          }
          setOpen(false);
        }
      }else{
        setOpen(!open);
        try {
          res = await getGarbageMobileUser(currentUser.traderCd);
        } catch (e) {
          console.log(e);
          console.log(e.response);
        }
        setOpen(false);
      }
    }else{
      setOpen(!open);
      try {
        // ログイン後の初期表示日数（groupId5）取り出し　add 20230905
        retrader = await getEgsMTraderUser(currentUser.traderCd);
        if (currentUser.traderKbn == '1111111111'){
          const today = dayjs().format('YYYY-MM-DD');
          const startday = dayjs().subtract(1,'d').format('YYYY-MM-DD');
          const param_manifest = "";
          const param_status = "";
          const param_exhaust = "";
          const param_waste = "";
          const param_item = currentUser.traderCd + "_"+currentUser.traderKbn + "_"+startday+"_"+today+"_"+param_manifest+"_"+param_status+"_"+param_exhaust+"_"+param_waste;
          // console.log("param_item",param_item);
          res = await getGarbagePcOperation(param_item);  // 20230809 getGarbageDetailOperation → getGarbagePcOperation
        }else{
          const today = dayjs().format('YYYY-MM-DD');
          // ログイン後の初期表示日数（groupId5）セット　update 20230905
          const startday = dayjs().subtract(retrader.data[0].groupId5,'d').format('YYYY-MM-DD');
          const param_manifest = "";
          const param_status = "";
          const param_exhaust = "";
          const param_waste = "";
          const param_item = currentUser.traderCd + "_"+currentUser.traderKbn + "_"+startday+"_"+today+"_"+param_manifest+"_"+param_status+"_"+param_exhaust+"_"+param_waste;
          // console.log("param_item",param_item);
          // res = await getGarbagePcOperation(param_item);  // 20230809 getGarbageDetailOperation → getGarbagePcOperation
          res = await getGarbageDetailOperation(param_item);
        }
      } catch (e) {
        console.log(e);
        console.log(e.response);
      }
      setOpen(false);

    }
    setDataList(res.data);

    if (currentUser.traderKbn === '1000000000') {
      try {
        reqdata = await getEditDelRequestExhaust(currentUser.traderCd);
      } catch (e) {
        console.log(e);
        console.log(e.response);
      }
    }else if ((currentUser.traderKbn === '0100000000')
            ||(currentUser.traderKbn === '0001000000')
            ||(currentUser.traderKbn === '0001001000')){
      try {
        reqdata = await getEditDelRequestUser(currentUser.traderCd);
      } catch (e) {
        console.log(e);
        console.log(e.response);
      }
    }else if(currentUser.traderKbn === '1111111111'){
      try {
        reqdata = await getEditDelRequestList();
      } catch (e) {
        console.log(e);
        console.log(e.response);
      }
    }
    console.log("reqdata", reqdata);
    setReqDataList(reqdata.data);
  };

  const handleDelete = async (item) => {
    if (window.confirm(' ゴミタグ「' + item.garbageTag + '」を削除します。よろしいですか？')) {
      try {
        const res = await deleteEgsTGarbageEdi(item.id);
        // console.log(res.data);
        handleGetList();
      } catch (e) {
        console.log(e.response);
      }
    }
  };

  // const [dialogopen, setDialogOpen] = React.useState(false);
  // const handleOpen = () => {
  //     setDialogOpen(true);
  // };
  // const handleClose = () => {
  //     setDialogOpen(false);
  // };

  const handleClickOpen = () => {
    // setOpen(true);
    setDialogOpen(true);
  };

  const handleClicClose = (value) => {
    // setOpen(false);
    setDialogOpen(false);
    setQrcodeValue(value);
  };

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
    if (inputRef1.current) {
      const ref = inputRef1.current;
      if (!ref.validity.valid) {
        setInputError1(true);
      } else {
        setInputError1(false);
      }
    }
    if (inputRef2.current) {
      const ref = inputRef2.current;
      if (!ref.validity.valid) {
        setInputError2(true);
      } else {
        setInputError2(false);
      }
    }
  };

  const handleClickRsvno = async() => {
    // console.log("reserveNo",reserveNo);
    // const res = await getProcessFlowReserveNo(reserveNo);
    // setFlowData(res.data);
    // // console.log("flowData",flowData);
    // let d = new Date();
    // const garbageTag = res.data[0].id + "-" + d.valueOf();
    // // history.push(`/mbl_emission_set/${garbageTag}` );

    Cookies.set('exhaust_telno', value.telNo);
    history.push(`/mbl_process_flow/${value.telNo}${value.wasteKind}` );
//    history.push(`/mbl_process_flow/${reserveNo}` );
  };

  if (qrcodeValue) {
    // 区切り文字に「/」を指定
    var rtn = qrcodeValue.split('/');
    // 2番目の要素から3番目の要素まで取り出し（ドメイン）
    var rtn2 = rtn.slice(2, 3);
    // 3番目の要素から4番目の要素まで取り出し（廃棄物タグwaste）
    var rtn3 = rtn.slice(3, 4);
    // 4番目の要素から5番目の要素まで取り出し（ゴミタグ）
    var rtn4 = rtn.slice(4, 5)[0];
  
    var rtnflowtag = rtn4.split('-');
    var rtnflowtag1 = rtnflowtag.slice(1, 2);
    var rtnflowtag2 = rtnflowtag.slice(2, 3);
  
    // 廃棄物タグQRの場合は入力画面、排出担当QRの場合は仮登録一覧
    if (rtn3[0] === "waste") { 
      if (rtnflowtag2.length === 0) {
        var newRtnflowtag = rtn4 + dayjs().unix();
        console.log("newRtnflowtag", newRtnflowtag);
        history.push(`/mbl_emission_set/${newRtnflowtag}`);
      } else {
        history.push(`/mbl_emission_set/${rtn4}`);
      }
    }else if (rtn3[0].length === 10) {
      history.push(`/mbl_garbage_tmp/${rtn4}`);
    }
  };

  const handleChangeSwitch = () => {
    setChecked(!checked);
    handleGetList();
  };

  const processingDataCount = reqdataList.filter(data => data.status === '処理前').length;

  return (
    <>
      {mbluser ? (
        <div>
          <h2>廃棄物一覧（現在、運搬・処分中の廃棄物）</h2>
          <div>
            {/* <p>表示対象: {checked ? '当日分' : '全データ'} */}
            <p>　　表示対象：　当日分　
            <Switch
              checked={checked}
              onChange={handleChangeSwitch}
              color="primary"
            />
            　全データ　　　　　
            <Button
              variant='text'  // テキストスタイルを使用
              color='primary'
              onClick={() => {
                history.push(`/mbl_sagyo_report/${mbluserid}`);
              }}
              style={{ textDecoration: 'underline', fontSize: '1.2em' }}  // アンダーラインとフォントサイズを指定
            >
              担当：{mblusername} 
            </Button>
            </p>
          </div>
          <Backdrop className={classes.backdrop} open={open} >
            <CircularProgress color="inherit" />
          </Backdrop>
          <GarbageEdiListTable
            dataList={dataList}    // resdataList
            handleDelete={handleDelete}
            currentUser={currentUser}
            mbluser={mbluser}
          />

          <Button variant='contained'   color="primary" onClick={handleClickOpen} style={{ marginRight: 10, fontSize: '1.5em' }}>
            <div>
              <Typography variant="h6">
                排出仮登録（廃棄物QRコード読込）
              </Typography>
              <Typography variant="body2">
                排出担当者QRコード読込で排出仮登録一覧
              </Typography>
            </div>
          </Button>
          <RsvQRReaderDialog qrcodeValue={qrcodeValue} open={dialogopen} onClose={handleClicClose} />
          <SpaceRow height={20} />
          <h4>廃棄物QRコードの無い場合は処理フロー番号を入力して「送信」下さい ↓</h4>
          <table>
            <tr>
              <th>処理フロー番号</th>
                <td>
                  <TextField
                    error={inputError1}
                    inputProps={{ maxLength: 11, pattern: "^[0-9]+$" }}
                    inputRef={inputRef1}
                    defaultValue=""
                    id='telNo'
                    variant="outlined"
                    type='text'
                    name='telNo'
                    label="電話番号"
                    fullWidth
                    margin="dense"
                    helperText={inputRef1?.current?.validationMessage}
                    onChange={(e) => handleChange(e)}
                    value={value.telNo}
                  />
                  <TextField
                    error={inputError2}
                    inputProps={{ inputMode: "numeric", maxLength: 4, pattern: "^[0-9]+$" }}
                    inputRef={inputRef2}
                    defaultValue=""
                    id='wasteKind'
                    variant="outlined"
                    type='text'
                    name='wasteKind'
                    label="廃棄物種類コード"
                    fullWidth
                    margin="dense"
                    helperText={inputRef2?.current?.validationMessage}
                    onChange={(e) => handleChange(e)}
                    value={value.wasteKind}
                  />
                  <h4 style={{ margin: 0 }}>廃棄物種類コードに 9999 で排出仮登録一覧</h4>
                </td>
                <td>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={(e) => handleClickRsvno(e)}
                  style={{ marginRight: 10, fontSize: '1.2em' }}
                  disabled={!value.telNo || !value.wasteKind}
                >
                  送信
                </Button>

              </td>
            </tr>
          </table>
          <SpaceRow height={30} />
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              history.push(`/mbl_receipt_unload1` );
            }}
            style={{ marginRight: 10, fontSize: '1.5em' }}
          >
            荷降登録（区間１）
          </Button>
          <SpaceRow height={20} />
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              history.push(`/mbl_receipt_load` );
            }}
            style={{ marginRight: 10, fontSize: '1.5em' }}
          >
            積保積込登録（区間２）
          </Button>
          <SpaceRow height={20} />
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              history.push(`/mbl_receipt_unload2` );
            }}
            style={{ marginRight: 10, fontSize: '1.5em' }}
          >
            荷降登録（区間２）
          </Button>
        </div>

      ) : (

          <div>
            <h1>廃棄物一覧（{new Date().toLocaleString()})　　　
              {isEligibleTrader && (
                <>
                  <FormControlLabel
                    control={
                      <Badge badgeContent={processingDataCount} color="secondary">
                        <Switch
                          checked={showEditDelReq}
                          onChange={() => setShowEditDelReq(!showEditDelReq)}
                          color="secondary"
                        />
                      </Badge>
                    }
                    label={<Typography variant="body1" style={{ fontWeight: 'bold' }}>修正・取消依頼</Typography>}
                  />
                  <Button
                    onClick={() => window.location.reload()}
                    color="primary"
                    style={{ marginLeft: '20px' }}  // ここで左側のマージンを設定
                  >
                    最新（再読み込み）
                  </Button>
                  <SpaceRow height={10} />
                  {showEditDelReq && (
                    <EditDelReqListTable
                      reqdataList={reqdataList}
                      setReqDataList={setReqDataList}
                    />
                  )}
                  <SpaceRow height={10} />
                </>
              )}
              {!isEligibleTrader && (
                <Button
                  onClick={() => window.location.reload()}
                  color="primary"
                  style={{ marginLeft: '20px' }}  // ここで左側のマージンを設定
                >
                  最新（再読み込み）
                </Button>
              )}
              <SysLginBtn />
            </h1>
            <Backdrop className={classes.backdrop} open={open} >
              <CircularProgress color="inherit" />
            </Backdrop>
            <GarbageEdiListTable
              dataList={dataList}
              handleDelete={handleDelete}
              currentUser={currentUser}
            />
            <SpaceRow height={1200} />
          </div>
        )}
      <SpaceRow height={30} />
      <Copyright />
    </>
  );
};
export default Home;
