// /src/lib/api/invoice_setting.js
import client from './client';
import Cookies from 'js-cookie';

// Helper function to add authorization headers
const authHeaders = () => ({
  'access-token': Cookies.get('_access_token'),
  client: Cookies.get('_client'),
  uid: Cookies.get('_uid'),
});

// Fetch the list of invoice settings
export const getInvoiceSettingList = () => {
  return client.get('/invoice_settings');
};

// Fetch details of a specific invoice setting by ID
export const getInvoiceSettingDetail = (id) => {
  return client.get(`/invoice_settings/${id}`);
};

// Fetch invoice setting by trader_cd (user-specific data)
export const getInvoiceSettingUser = (trader_cd) => {
  return client.get(`/invoice_settings/show_user/${trader_cd}`);
};

// Helper function to prepare image parameters by encoding in Base64
const prepareImageParams = async (params) => {
  if (params.logo_image && params.logo_image instanceof File) {
    params.logo_image = await toBase64(params.logo_image);
  }
  if (params.stamp_image && params.stamp_image instanceof File) {
    params.stamp_image = await toBase64(params.stamp_image);
  }
  return params;
};

// Convert image file to Base64 format
const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]); // Removing the prefix for pure Base64 data
    reader.onerror = (error) => reject(error);
  });
};

// Create a new invoice setting with headers and image processing
export const createInvoiceSetting = async (params) => {
  const preparedParams = await prepareImageParams(params);
  return client.post('/invoice_settings', preparedParams, {
    headers: authHeaders(),
  });
};

// Update an existing invoice setting with headers and image processing
export const updateInvoiceSetting = async (id, params) => {
  const preparedParams = await prepareImageParams(params);
  return client.patch(`/invoice_settings/${id}`, preparedParams, {
    headers: authHeaders(),
  });
};

// Delete an invoice setting with headers
export const deleteInvoiceSetting = (id) => {
  return client.delete(`/invoice_settings/${id}`, {
    headers: authHeaders(),
  });
};
