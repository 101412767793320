import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView } from "react-device-detect"
import { Link , useParams} from 'react-router-dom';
import QRCode from "qrcode.react"
import dayjs from 'dayjs';

import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import SpaceRow from '../commons/SpaceRow';
import EgsMEntryAdminNew from "./EgsMEntryAdmin";
import EgsMEntryAdminUpd from "./EgsMEntryAdmin";
import EgsMEntryAdminDel from "./EgsMEntryAdmin";
import EgsMEntryNew from "./EgsMEntryNew";
import EgsMEntryUpd from "./EgsMEntryUpd";
import EgsMEntryDel from "./EgsMEntryDel";
import EgsMEntryNewEdit from "./EgsMEntryNewEdit";
import EgsMEntryUpdEdit from "./EgsMEntryUpdEdit";
import EgsMEntryDelEdit from "./EgsMEntryDelEdit";
//import EgsMEntryListTableForm from "./EgsMEntryListTableForm"
import EntryPdf from "./EntryPdf"
import './app.css';

import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Typography
} from '@material-ui/core';
import {
  DataGridPro,
  GridColDef,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridCsvExportOptions,
  GridActionsCellItem,
  GridColumnMenu,
  jaJP
} from '@mui/x-data-grid-pro'
import { getEntryOperation  } from '../../lib/api/entry';


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: '加入者登録・変更・解約一覧',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
}

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      componentsProps={{
        // Swap positions of filter and sort items
        columnMenuFilterItem: {
          displayOrder: 0, // Previously `10`
        },
        // columnMenuSortItem: {
        //   displayOrder: 10, // Previously `0`
        // },
      }}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "85vw",
    margin: "auto",
  },
  container2: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "1000px",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#EEE",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

function EgsMEntryListTable(props) {
  const classes = useStyles();

  const [open2, setOpen2] = React.useState(false);

  const { dataList, handleDelete, currentUser } = props;
  console.log('currentUser',currentUser);
  const [rows, setRows] = React.useState(dataList);
  let selRows = React.useRef([]);
  console.log(selRows);
  const [pageSize, setPageSize] = React.useState(10);
  const [dataList2, setDataList2] = useState([]);

  // const resdataList = dataList.filter(dataList => {
  //   return dataList.traderCd === currentUser.traderCd
  // })
  const [adminNewOpen, setAdminNewOpen] = useState(false);
  const [adminUpdOpen, setAdminUpdOpen] = useState(false);
  const [adminDelOpen, setAdminDelOpen] = useState(false);
  const [newOpen, setNewOpen] = useState(false);
  const [updOpen, setUpdOpen] = useState(false);
  const [delOpen, setDelOpen] = useState(false);
  const [newEditOpen, setNewEditOpen] = useState(false);
  const [updEditOpen, setUpdEditOpen] = useState(false);
  const [delEditOpen, setDelEditOpen] = useState(false);

  const [detailPdf, setDetailPdf] = useState(false);  // 廃棄物詳細PDF

  const [filteredDataList, setFilteredDataList] = React.useState([]);
  const [filter, setFilter] = React.useState({
//    exhaustDateStart: '',
//    exhaustDateEnd: '',
//    manifestNo: '',
//    wasteKindName: '',
    traderName: '',
//    statusName: '',
    traderCd: '',
    sect1TransportName: ''
  })
  const query = useParams();
  const pdfRef = React.createRef();

//  const pdfRef = React.createRef();
  var param_item = '';

  useEffect(() => {
    setFilteredDataList(dataList);
    // console.log('setFilteredDataList', filteredDataList);
  }, [dataList, currentUser])

  const startFilter = () => {
/*    
    console.log('dataList', dataList);
    console.log('currentUser', currentUser);

    console.log('filter', filter);
    if (filter.exhaustDateStart == ''){
      filter.exhaustDateStart = dayjs().subtract(60,'d').format('YYYY-MM-DD');
    }
    if (filter.exhaustDateEnd == ''){
      filter.exhaustDateEnd = dayjs().format('YYYY-MM-DD');
    }
*/
//    param_item = currentUser.traderCd + "_"+currentUser.traderKbn + "_"+filter.exhaustDateStart+"_"+filter.exhaustDateEnd+"_"+filter.manifestNo+"_"+filter.statusName+"_"+filter.traderCd+"_"+filter.traderName+"_"+filter.sect1TransportName;
    param_item = currentUser.traderCd + "_"+currentUser.traderKbn + "_"+filter.traderCd+"_"+filter.traderName+"_";
//    console.log("param_item",param_item)
    handleGetList(param_item);
  }

  const handleGetList = async () => {
    setOpen2(!open2);
    try {
      const  res = await getEntryOperation(param_item);
      setFilteredDataList(res.data);
    } catch (e) {
      console.log(e);
    }
    setOpen2(false);
  };

  const handleAdminNewOpen = () => {
    setAdminNewOpen(true);
  };
  const handleAdminUpdOpen = () => {
    setAdminUpdOpen(true);
  };
  const handleAdminDelOpen = () => {
    setAdminDelOpen(true);
  };
  const handleNewOpen = () => {
    setNewOpen(true);
  };
  const handleUpdOpen = () => {
    setUpdOpen(true);
  };
  const handleDelOpen = () => {
    setDelOpen(true);
  };
  const handleNewEditOpen = () => {
    setNewEditOpen(true);
  };
  const handleUpdEditOpen = () => {
    setUpdEditOpen(true);
  };
  const handleDelEditOpen = () => {
    setDelEditOpen(true);
  };

  const handlePdf = () => {
    setDetailPdf(true);
  };

  const handleRowClick = (param, event) => {
//    console.log(param.row);
    setDataList2(param.row);
//    console.log('dataList2',dataList2);
  };

  // 行の削除
  const delRows = () => {
//    console.log('delRows',dataList2)
    handleDelete(dataList2);
  }

  // アイコンをクリックしたときの処理
  const handleDetailClick = React.useCallback((params) => (event) => {
    event.stopPropagation();
    console.log(`handleDetailClick:id=${params.id}`);
  }, []);

  const getRowClassName = (params) => {
    if (params.row.effectiveName === '2:登録完了') {
      return 'finish-row';
    } else if (params.row.effectiveName === '1:申請済') {
      return 'apply-row';
    } else {
      return '';
    }
  }

//  管理者用の一覧データ。dataList2で全カラムを渡すために、非表示はhideで残す

  const cols1 = [
    {field: 'id', headerName: 'ID', width: 50},
    {field: 'henkouDate', headerName: '変更予定日', width: 100},
    {field: 'entryDate', headerName: 'お申込日', width: 100},
    {field: 'henkouKbn', headerName: '変更区分', hide: true, width: 20},
    {field: 'henkouKbnName', headerName: '変更区分名', width: 100},
    {field: 'effectiveName',headerName: '申請状況',width: 100},  //      type: 'boolean',
    {field: 'demandCd', headerName: '請求先CD', width: 100, hide:true},
    {field: 'demandName', headerName: '請求先', width: 200},

    {field: 'jwnetNo',headerName: '加入者番号',width: 100},


    {field: 'companyKana', headerName: '企業かな', hide: true,width: 200},
    {field: 'companyName', headerName: '企業名', width: 200},
    {field: 'daihyoName', headerName: '代表者', width: 150},
    {field: 'daihyo', headerName: '代表者役職', width: 100},
    {field: 'daihyoKana1', headerName: '代表者かな1',hide: true, width: 100},
    {field: 'daihyoKana2', headerName: '代表者かな2',hide: true, width: 100},
    {field: 'daihyoName1', headerName: '代表者名1', hide: true, width: 100},
    {field: 'daihyoName2',headerName: '代表者名2', hide: true,width: 100},

    {field: 'traderCd',headerName: '事業場CD',hide: true,width: 120},
    {field: 'traderKana',headerName: '事業場かな',hide: true,width: 120},
    {field: 'traderName',headerName: '事業場名',width: 120},
    {field: 'traderKbn',headerName: '事業者区分',hide: true,width: 100},
    {field: 'chargeName',headerName: '担当者名',width: 100},
    {field: 'chargeKana1',headerName: '担当者かな1',hide: true,width: 100},
    {field: 'chargeKana2',headerName: '担当者かな2',hide: true,width: 100},
    {field: 'chargeName1',headerName: '担当者名1', hide: true,width: 100},
    {field: 'chargeName2',headerName: '担当者名2', hide: true,width: 100},

    {field: 'traderFullAddress',headerName: '住所',width: 200},
    {field: 'zip',headerName: '郵便番号',hide: true,width: 80},
    {field: 'pref',headerName: '都道府県',hide: true,width: 80},
    {field: 'city',headerName: '市区町村',hide: true,width: 100},
    {field: 'town',headerName: '町域',hide: true,width: 100},
    {field: 'address',headerName: '詳細住所',hide: true,width: 100},
    {field: 'building',headerName: 'ビル名',hide: true,width: 100},
    {field: 'phone',headerName: '電話番号',width: 100},
    {field: 'fax',headerName: 'FAX番号',hide: true,width: 100},
    {field: 'email1',headerName: 'メールアドレス1',hide: true,width:100},

    {field: 'officeChargeKana1',headerName: '事務担当者かな1',hide: true,width: 100},
    {field: 'officeChargeKana2',headerName: '事務担当者かな2',hide: true,width: 100},
    {field: 'officeChargeName1',headerName: '事務担当者名1',hide: true,width: 100},
    {field: 'officeChargeName2',headerName: '事務担当者名2',hide: true,width: 100},

    {field: 'chargeDivision',headerName: '料金区分',width: 100},
    {field: 'business',headerName: '職種',width: 100},
    {field: 'group1',headerName: 'グループ１',width: 100},
    {field: 'group2',headerName: 'グループ２',width: 100},
    {field: 'jwnetEdipass',headerName: 'EDI版パスワード',hide: true,width: 100},
    {field: 'rawpasswd',headerName: 'パスワード',hide: true,width: 80},
    {field: 'exhaustPasswd',headerName: '承認コード',hide: true,width: 80},

    {field: 'companyKind',headerName: '法人区分',hide: true,width: 50},
    {field: 'companyKindName',headerName: '法人区分名',hide: true,width: 100},
    {field: 'companyKbn',headerName: '法人位置',hide: true,width: 50},
    {field: 'companyKbnName',headerName: '法人位置名',hide: true,width: 50},
  ]

//  運搬業者用の一覧データ。dataList2で全カラムを渡すために、非表示はhideで残す

  const cols2 = [
    {field: 'id', headerName: 'ID', width: 50},
    {field: 'henkouDate', headerName: '変更予定日', width: 100},
    {field: 'entryDate', headerName: 'お申込日', width: 100},
    {field: 'henkouKbn', headerName: '変更区分', hide: true, width: 20},
    {field: 'henkouKbnName', headerName: '変更区分名', width: 100},
    {field: 'effectiveName',headerName: '申請状況',width: 100},  //      type: 'boolean',
    {field: 'demandCd', headerName: '請求先CD',hide: true, width: 100},
    {field: 'demandName', headerName: '請求先',hide: true, width: 200},

    {field: 'jwnetNo',headerName: '加入者番号',width: 100},

    {field: 'companyKana', headerName: '企業かな', hide: true,width: 200},
    {field: 'companyName', headerName: '企業名', width: 200},
    {field: 'daihyoName', headerName: '代表者', width: 150},
    {field: 'daihyo', headerName: '代表者役職', width: 100},
    {field: 'daihyoKana1', headerName: '代表者かな1',hide: true, width: 100},
    {field: 'daihyoKana2', headerName: '代表者かな2',hide: true, width: 100},
    {field: 'daihyoName1', headerName: '代表者名1', hide: true, width: 100},
    {field: 'daihyoName2',headerName: '代表者名2', hide: true, width: 100},

    {field: 'traderCd',headerName: '事業場CD',hide: true,width: 120},
    {field: 'traderKana',headerName: '事業場かな',hide: true,width: 120},
    {field: 'traderName',headerName: '事業場名',width: 120},
    {field: 'traderKbn',headerName: '事業者区分',hide: true,width: 100},
    {field: 'chargeName',headerName: '担当者名',width: 100},
    {field: 'chargeKana1',headerName: '担当者かな1',hide: true,width: 100},
    {field: 'chargeKana2',headerName: '担当者かな2',hide: true,width: 100},
    {field: 'chargeName1',headerName: '担当者名1', hide: true, width: 100},
    {field: 'chargeName2',headerName: '担当者名2', hide: true, width: 100},

    {field: 'traderFullAddress',headerName: '住所',width: 200},
    {field: 'zip',headerName: '郵便番号',hide: true,width: 80},
    {field: 'pref',headerName: '都道府県',hide: true,width: 80},
    {field: 'city',headerName: '市区町村',hide: true,width: 100},
    {field: 'town',headerName: '町域',hide: true,width: 100},
    {field: 'address',headerName: '詳細住所',hide: true,width: 100},
    {field: 'building',headerName: 'ビル名',hide: true,width: 100},
    {field: 'phone',headerName: '電話番号',width: 100},
    {field: 'fax',headerName: 'FAX番号',hide: true,width: 100},
    {field: 'email1',headerName: 'メールアドレス1',hide: true,width:100},

    {field: 'officeChargeKana1',headerName: '事務担当者かな1',hide: true,width: 100},
    {field: 'officeChargeKana2',headerName: '事務担当者かな2',hide: true,width: 100},
    {field: 'officeChargeName1',headerName: '事務担当者名1',hide: true,width: 100},
    {field: 'officeChargeName2',headerName: '事務担当者名2',hide: true,width: 100},

    {field: 'chargeDivision',headerName: '料金区分',width: 100},
    {field: 'business',headerName: '職種',width: 100},
//    {field: 'group1',headerName: 'グループ１',width: 100},
//    {field: 'group2',headerName: 'グループ２',width: 100},
//    {field: 'jwnetEdipass',headerName: 'EDI版パスワード',hide: true,width: 100},
//    {field: 'rawpasswd',headerName: 'パスワード',hide: true,width: 80},
//    {field: 'exhaustPasswd',headerName: '承認コード',hide: true,width: 80},

//    {field: 'companyKind',headerName: '法人区分',hide: true,width: 50},
//    {field: 'companyKindName',headerName: '法人区分名',hide: true,width: 100},
//    {field: 'companyKbn',headerName: '法人位置',hide: true,width: 50},
//    {field: 'companyKbnName',headerName: '法人位置名',hide: true,width: 50},
  ]

  const qrCodeUrl = "https://www.sdgs12.com/" + dataList2.traderKbn + "/" + dataList2.traderCd + "/" + dataList2.entryCd
  console.log('qrCodeUrl',qrCodeUrl);

  if (currentUser.traderKbn == '1111111111') {
    return (
      <>
      <BrowserView>
     
        <Grid
          container
          spacing={2}
        >
          <Grid item xs style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>　事業場Cd：</Typography>
            <TextField id="traderCd" value={filter.traderCd} onChange={e => setFilter({ ...filter, traderCd: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
            <Typography variant="h7" style={{ fontWeight: 700 }}>　事業場名称：</Typography>
            <TextField id="traderName" value={filter.traderName} onChange={e => setFilter({ ...filter, traderName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
            <Button
              type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
              データ表示
            </Button>
            　　MAX：3000件
          </Grid>
        </Grid>

        <Backdrop className={classes.backdrop} open={open2} >
              <CircularProgress color="inherit" />
        </Backdrop>

        <div className={classes.container}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
//              pageSize={pageSize}
//              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//              rowsPerPageOptions={[10, 20, 50]}
//              pagination
              sx={styles.grid}
              columns={cols1}
              rows={filteredDataList}
              density="compact"
//              autoHeight
              components={{
                Toolbar: CustomToolbar,　// カスタムツールバーを指定する
              }}
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleRowClick}
              getRowClassName={getRowClassName} // ここに関数を設定  2024/12/11
            />
          </Box>
        </div>
        
        <Box component='div' sx={{ p: 1, textAlign: 'right' }}>
          <EntryPdf
            currentUser={currentUser}
            isOpen={detailPdf}
            doClose={() => setDetailPdf(false)}
            dataList2={dataList2}
          />
          <Button variant="outlined" color='primary' style={{ marginRight: 10 }} onClick={handlePdf}
            disabled={!dataList2.id }>
            加入者登録・変更・解約一覧PDF
          </Button>
          </Box>
          <Box component='div' sx={{textAlign: 'left' }}>
{/*}          <EntryListTableForm
            dataList2={dataList2}
            /> */}
      </Box>
        
        <EgsMEntryAdminNew isOpen={adminNewOpen} doClose={() => setAdminNewOpen(false)} dataList2={dataList2} processId={dataList2.id}/>
        <EgsMEntryAdminUpd isOpen={adminUpdOpen} doClose={() => setAdminUpdOpen(false)} dataList2={dataList2} processId={dataList2.id}/>
        <EgsMEntryAdminDel isOpen={adminDelOpen} doClose={() => setAdminDelOpen(false)} dataList2={dataList2} processId={dataList2.id}/>
        <SpaceRow height={20} />
        <Box component='div' sx={{ p: 2, textAlign: 'right' }}></Box>
        <div className={classes.container2}>
          <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
{/*}            <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleAdminNewOpen} disabled={!dataList2.id}> {/*} || dataList2.effectiveflg !== '1'}>
              申込編集
            </Button> */}
            <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleAdminNewOpen} disabled={dataList2.henkouKbn !== '1'} dataList2={dataList2} processId={dataList2.id}>
              加入申込編集
            </Button>
            <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleAdminUpdOpen} disabled={dataList2.henkouKbn !== '2'} dataList2={dataList2} processId={dataList2.id}>
              変更申込編集
            </Button>
            <Button variant="contained" color="primary" style={{ marginRight: 100 }} onClick={handleAdminDelOpen} disabled={dataList2.henkouKbn !== '3'} dataList2={dataList2} processId={dataList2.id}>
              解約申込編集
            </Button>
            <Button variant="contained" color='secondary' onClick={delRows} disabled={!dataList2.id}>
              申込削除
            </Button>
          </Box>
        </div>
        </BrowserView>
      </>
    );
  }else{  
    return (
      <>
      <BrowserView>

        <Backdrop className={classes.backdrop} open={open2} >
              <CircularProgress color="inherit" />
        </Backdrop>

        <div className={classes.container}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
//              pageSize={pageSize}
//              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//              rowsPerPageOptions={[10, 20, 50]}
//              pagination
              sx={styles.grid}
              columns={cols2}
              rows={filteredDataList}
              density="compact"
//              autoHeight
              components={{
                Toolbar: CustomToolbar,　// カスタムツールバーを指定する
              }}
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleRowClick}
              getRowClassName={getRowClassName} // ここに関数を設定  2024/12/11
            />
          </Box>
        </div>

        <EgsMEntryAdminNew isOpen={adminNewOpen} doClose={() => setAdminNewOpen(false)} dataList2={dataList2} processId={dataList2.id}/>
        <EgsMEntryNew isOpen={newOpen} doClose={() => setNewOpen(false)} currentUser={currentUser}/>
        <EgsMEntryUpd isOpen={updOpen} doClose={() => setUpdOpen(false)} currentUser={currentUser}/>
        <EgsMEntryDel isOpen={delOpen} doClose={() => setDelOpen(false)} currentUser={currentUser}/>

        <EgsMEntryNewEdit isOpen={newEditOpen} doClose={() => setNewEditOpen(false)} currentUser={currentUser} dataList2={dataList2} processId={dataList2.id}/>
        <EgsMEntryUpdEdit isOpen={updEditOpen} doClose={() => setUpdEditOpen(false)} currentUser={currentUser} dataList2={dataList2} processId={dataList2.id}/>
        <EgsMEntryDelEdit isOpen={delEditOpen} doClose={() => setDelEditOpen(false)} currentUser={currentUser} dataList2={dataList2} processId={dataList2.id}/>

        <SpaceRow height={20} />
        <Box component='div' sx={{ p: 2, textAlign: 'right' }}></Box>
        <div className={classes.container2}>
          <Box component='div' sx={{ p: 2, textAlign: 'left' }}>

            <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleNewOpen}>
              加入申込
            </Button>
            <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleUpdOpen}>
              変更申込
            </Button>
            <Button variant="contained" color="primary" style={{ marginRight: 100 }} onClick={handleDelOpen}>
              解約申込
            </Button>

            <Button variant="contained" color="secondary" style={{ marginRight: 10 }} onClick={handleNewEditOpen} disabled={dataList2.henkouKbn !== '1'} dataList2={dataList2} processId={dataList2.id}>
              加入申込編集
            </Button>
            <Button variant="contained" color="secondary" style={{ marginRight: 10 }} onClick={handleUpdEditOpen} disabled={dataList2.henkouKbn !== '2'} dataList2={dataList2} processId={dataList2.id}>
              変更申込編集
            </Button>
            <Button variant="contained" color="secondary" style={{ marginRight: 100 }} onClick={handleDelEditOpen} disabled={dataList2.henkouKbn !== '3'} dataList2={dataList2} processId={dataList2.id}>
              解約申込編集
            </Button>
            <Button variant="contained" color='secondary' onClick={delRows} disabled={!dataList2.id}>
              申込削除
            </Button>

          </Box>
        </div>
        </BrowserView>
      </>
    );
  }

}
const styles = {
/*
  grid: {
    // '.MuiDataGrid-toolbarContainer': {
    //   borderBottom: 'solid 1px rgba(224, 224, 224, 1)'
    // },
    // '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
    //   borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
    // },
     // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
 }
*/  
 grid: {
  '.MuiDataGrid-columnHeaders': {
    backgroundColor: '#65b2c6',  // タイトル行　青色　'#add8e6', // 薄青色  '#c71585',
    color: '#fff',
  },
  '& .finish-row': { // 2:登録完了の行に適用するスタイル
    backgroundColor: '#666460', // 薄灰色  // '#f5deb3', 
  },
  '& .apply-row': { // 1:新生済の行に適用するスタイル
    backgroundColor: '#f5ce93', // オレンジ色  '#add8e6', // 薄青色
  }
}
}
export default EgsMEntryListTable;
