import React, { useEffect, useState, useContext } from 'react';
import { getEgsTGarbageEdiInvoiceItem, getEgsTGarbageEdiInvoiceData, getEgsTGarbageEdiInvoiceDetail } from '../../lib/api/egs_t_garbage_edi';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import SpaceRow from '../commons/SpaceRow';
import {
  Button,
  TextField,
  Box,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
  jaJP
} from '@mui/x-data-grid-pro'
import { AuthContext } from '../../App';
import InvoiceDataEdit from './InvoiceDataEdit'; // Import the InvoiceDataEdit component

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "85vw",
    margin: "auto",
  },
  container2: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "1000px",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#EEE",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  printHide: {
    '@media print': {
      display: 'none',
    },
  },
}));

const InvoiceDataList = () => {
  const { currentUser } = useContext(AuthContext);
  const [selectedJwnetNo, setSelectedJwnetNo] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false); // モーダルのオープン状態を管理

  const classes = useStyles();

  // 前月の1日と最終日を計算する関数
  const getLastMonthDates = () => {
    const today = new Date();
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
    
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    return { 
      start: formatDate(lastMonthStart), 
      end: formatDate(lastMonthEnd) 
    };
  };

  const { start: defaultStart, end: defaultEnd } = getLastMonthDates();

  const [dataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFilterChanged, setIsFilterChanged] = useState(false);
  const [filter, setFilter] = useState({
    sect1TransportName: '',
    searchDateStart: defaultStart,
    searchDateEnd: defaultEnd,
    exhaustName: '',
    wasteName: '',
    invoiceDateType: '0' // 請求対象日のデフォルト値を '0' (排出日) に設定
  });

  const [detailData, setDetailData] = useState([]);
  const [isDetailLoading, setIsDetailLoading] = useState(false);
  const [invoiceDetailData, setInvoiceDetailData] = useState([]);
  const [isInvoiceDetailLoading, setIsInvoiceDetailLoading] = useState(false);

  const [detailOpen1, setDetailOpen1] = useState(false);
  const handleOpen1 = () => {
    setDetailOpen1(true);
  };

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async (params) => {
    try {
      setIsLoading(true);
      let traderCd = currentUser.traderCd;
      
      // currentUser.traderKbnが'1111111111'の場合、traderCdをNullに設定
      if (currentUser.traderKbn === '1111111111') {
        traderCd = '';
      }
      
      const defaultParams = `${traderCd}_${currentUser.traderKbn}_${filter.searchDateStart}_${filter.searchDateEnd}_${filter.sect1TransportName}_${filter.exhaustName}_${filter.wasteName}_${filter.wasteName}_${filter.invoiceDateType}`;
      const res = await getEgsTGarbageEdiInvoiceItem(params || defaultParams);
      const dataWithId = res.data.map((item, index) => ({
        id: index,
        ...item
      }));
      setDataList(dataWithId);
      setIsFilterChanged(false);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setIsFilterChanged(true);
  };

  const startFilter = () => {
    // Clear the detail data when the filter is applied
    setDetailData([]); // Initialize the detail data
    setInvoiceDetailData([]);

    let traderCd = currentUser.traderCd;
    // currentUser.traderKbnが'1111111111'の場合、traderCdをNullに設定
    if (currentUser.traderKbn === '1111111111') {
      traderCd = '';
    }
    
    const params = `${traderCd}_${currentUser.traderKbn}_${filter.searchDateStart}_${filter.searchDateEnd}_${filter.sect1TransportName}_${filter.exhaustName}_${filter.wasteName}_${filter.invoiceDateType}`;
    // console.log("params", params);
    handleGetList(params);
  };
  // Function to move the invoice period one month backward
  const moveInvoicePeriodBackward = () => {
    const newStartDate = new Date(filter.searchDateStart);
    const newEndDate = new Date(filter.searchDateEnd);

    newStartDate.setMonth(newStartDate.getMonth() - 1);
    newEndDate.setMonth(newEndDate.getMonth() - 1);

    setFilter({
      ...filter,
      searchDateStart: newStartDate.toISOString().split('T')[0],
      searchDateEnd: newEndDate.toISOString().split('T')[0],
    });
    setIsFilterChanged(true);
  };
  // Function to move the invoice period one month forward
  const moveInvoicePeriodForward = () => {
    const newStartDate = new Date(filter.searchDateStart);
    const newEndDate = new Date(filter.searchDateEnd);

    newStartDate.setMonth(newStartDate.getMonth() + 1);
    newEndDate.setMonth(newEndDate.getMonth() + 1);

    setFilter({
      ...filter,
      searchDateStart: newStartDate.toISOString().split('T')[0],
      searchDateEnd: newEndDate.toISOString().split('T')[0],
    });
    setIsFilterChanged(true);
  };

  // 合計を計算する関数
  const calculateTotals = () => {
    return dataList.reduce((acc, curr) => {
      return {
        kensu: acc.kensu + (curr.kensu || 0),
        torikesi: acc.torikesi + (curr.torikesi || 0),
        touroku: acc.touroku + (curr.touroku || 0),
      };
    }, { kensu: 0, torikesi: 0, touroku: 0 });
  };

  const handleRowClick = async (params) => {
    const invoicedataparams = `${params.row.sect1TransportCd}_${currentUser.traderKbn}_${filter.searchDateStart}_${filter.searchDateEnd}_${filter.sect1TransportName}_${params.row.exhaustName}_${filter.wasteName}_${filter.invoiceDateType}`;
    // console.log("params",params);
    setIsDetailLoading(true);
    // 最下段の DataGridPro を初期化
    setInvoiceDetailData([]);
    // jwnetNoを保存
    setSelectedJwnetNo(params.row.jwnetNo);
    try {
      const res = await getEgsTGarbageEdiInvoiceData(invoicedataparams);
      const dataWithId = res.data.map((item, index) => ({
        id: index,
        ...item
      }));
      setDetailData(dataWithId);
    } catch (error) {
      console.error('Error fetching detail data:', error);
    } finally {
      setIsDetailLoading(false);
    }
  };

  const handleDetailRowClick = async (params) => {
    const invoicedetailparams = `${params.row.sect1TransportCd}_${currentUser.traderKbn}_${filter.searchDateStart}_${filter.searchDateEnd}_${filter.sect1TransportName}_${params.row.exhaustName}_${params.row.wasteName}_${filter.invoiceDateType}_${params.row.packingName}`;
    // console.log("invoicedetailparams",invoicedetailparams);
    setIsInvoiceDetailLoading(true);
    try {
      const res = await getEgsTGarbageEdiInvoiceDetail(invoicedetailparams);
      const dataWithId = res.data.map((item, index) => ({
        id: index,
        ...item
      }));
      setInvoiceDetailData(dataWithId);
    } catch (error) {
      console.error('Error fetching invoice detail data:', error);
    } finally {
      setIsInvoiceDetailLoading(false);
    }
  };

  const handleadminRowClick = async (params) => {
    const selectedSect1TransportCd = params.row.sect1TransportCd; // 選択したレコードのsect1TransportCdを取得
    // 最下段の DataGridPro を初期化
    setDetailData([]);
    // sect1TransportCdが一致するレコードのみをフィルタリング
    const filteredData = dataList.filter(item => item.sect1TransportCd === selectedSect1TransportCd);  
    setDataList(filteredData); // フィルタリングされたデータを設定

  };
    // 日付のみをフォーマットする関数
    const formatDateOnly = (dateString) => {
      if (!dateString) return '';
      const date = new Date(dateString);
      return date.toISOString().split('T')[0]; // 'YYYY-MM-DD' 形式で返す
    };

    // 集計データの状態を更新
    const [summaryData, setSummaryData] = useState([]);

    // 集計データ用のカラム定義
    const summaryColumns = [
      { field: 'sect1TransportCd', headerName: '収集運搬事業者CD', width: 150 },
      { field: 'name', headerName: '事業場名', width: 400 },
      { field: 'total', headerName: '合計発行件数', type: 'number', width: 150 },
      { field: 'A', headerName: '料金区分A', type: 'number', width: 150 },
      { field: 'B', headerName: '料金区分B', type: 'number', width: 150 },
      { field: 'C', headerName: '料金区分C', type: 'number', width: 150 },
    ];
  
    // データリストが更新されたときに集計を行う
    useEffect(() => {
      if (currentUser.traderKbn === '1111111111') {
        const summary = {};
    
        dataList.forEach(item => {
          if (!summary[item.sect1TransportName]) {
            summary[item.sect1TransportName] = { 
              name: item.sect1TransportName, 
              sect1TransportCd: item.sect1TransportCd, // sect1TransportCdを追加
              total: 0, 
              A: 0, 
              B: 0, 
              C: 0 
            };
          }
    
          summary[item.sect1TransportName].total += item.kensu;
    
          switch (item.chargeDivision) {
            case '1':
              summary[item.sect1TransportName].A += item.kensu;
              break;
            case '2':
              summary[item.sect1TransportName].B += item.kensu;
              break;
            case '3':
              summary[item.sect1TransportName].C += item.kensu;
              break;
          }
        });
    
        setSummaryData(Object.values(summary).map((item, index) => ({ id: index, ...item })));
      }
    }, [dataList, currentUser.traderKbn]);

  const cols = [
    { field: 'id', headerName: 'ID', width: 80 },
    { field: 'sect1TransportCd', headerName: '収集運搬事業者CD', width: 140 },
    { field: 'sect1TransportName', headerName: '収集運搬事業者名', width: 300 },
    { field: 'jwnetNo', headerName: 'JWNET加入者番号', width: 140 },
    { field: 'exhaustCd', headerName: '排出事業者CD', width: 140 },
    { field: 'exhaustName', headerName: '排出事業者', width: 400 },
    { 
      field: 'chargeDivision', 
      headerName: 'JWNET料金区分', 
      width: 150,
      valueGetter: (params) => {
        switch (params.value) {
          case '1':
            return 'A';
          case '2':
            return 'B';
          case '3':
            return 'C';
          default:
            return params.value;
        }
      }
    },
    { field: 'kensu', headerName: 'マニフェスト発行件数', width: 200 },
    { field: 'torikesi', headerName: 'マニフェスト取消件数', width: 200 },
    { field: 'touroku', headerName: 'マニフェスト登録件数', width: 200 },
  ];

  const detailColumns = [
    { field: 'id', headerName: 'ID', width: 80 },
    { field: 'sect1TransportCd', headerName: '収集運搬事業者CD', width: 140 },
    { field: 'sect1TransportName', headerName: '収集運搬事業者名', width: 300 },
    { field: 'exhaustName', headerName: '排出事業者', width: 400 },
    { field: 'kensu', headerName: 'マニフェスト登録件数', width: 200 },
    { field: 'wasteName', headerName: '廃棄物名称', width: 200 },
    { field: 'vol', headerName: '数量', width: 100 },
    { field: 'unitName', headerName: '単位', width: 200 },
    { 
      field: 'packingvol', 
      headerName: '荷姿数量', 
      width: 100,
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        // 値を整数に変換して表示
        return Math.round(params.value).toString();
      }
    },
    { field: 'packingName', headerName: '荷姿単位', width: 200 },
  ];

  const invoiceDetailColumns = [
    {
      field: 'recordNo',
      headerName: 'No.',
      width: 40,
      valueGetter: (params) => params.api.getRowIndex(params.id) + 1,
    },
    { field: 'id', headerName: 'ID', width: 80 },
    { field: 'sect1TransportChargeName', headerName: '収集担当者', width: 120 },
    { field: 'exhaustName', headerName: '排出事業者', width: 200 },
    { field: 'exhaustDate', headerName: '排出日', width: 100 },
    { field: 'manifestNo', headerName: 'マニフェスト番号', width: 140 },
    { field: 'wasteName', headerName: '廃棄物名称', width: 200 },
    { field: 'amount1', headerName: '数量', width: 80 },
    { field: 'unitName', headerName: '単位', width: 100 },
    { field: 'packingAmount1', headerName: '荷姿数量', width: 80 },
    { field: 'packingName', headerName: '荷姿単位', width: 140 },
    { field: 'memo1', headerName: '備考１', width: 120 },
    { field: 'memo2', headerName: '備考２', width: 80 },
    { field: 'memo3', headerName: '備考３', width: 60 },
    { field: 'memo4', headerName: '備考４', width: 60 },
    { field: 'memo5', headerName: '備考５', width: 60 },
    { field: 'lastProcessingTraderName', headerName: '最終処分場', width: 200 },
    { field: 'createdAt', 
      headerName: '作成日', 
      width: 100,
      valueFormatter: (params) => formatDateOnly(params.value)
    },
    { field: 'middleEnd', headerName: '中間処理日', width: 100 },
    { field: 'lastProcessingEnd', headerName: '最終処分日', width: 100 },
  ];
  
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: 'Sdgs12_InvoiceData',
          delimiter: ',',
          utf8WithBom: true,
        }}
      />
      </GridToolbarContainer>
    )
  }

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(newSelection);
  };
  
  const handleOpenEditDialog = () => {
    setOpenEditDialog(true); // モーダルを開く
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false); // モーダルを閉じる
  };

  const exportSelectedToCSV = () => {
    const today = new Date();
    const formattedDate = `${today.getFullYear()}${String(today.getMonth() + 1).padStart(2, '0')}${String(today.getDate()).padStart(2, '0')}`; // yyyymmdd形式
  
    const csvData = selectedRows.map((rowId) => {
      const row = summaryData.find((data) => data.id === rowId);
      if (!row) return null; // 選択された行のデータが見つからない場合はnullを返す
  
      // 取引先管理コードを取得し、下4桁を削除
      const traderCode = row.sect1TransportCd ? row.sect1TransportCd.slice(0, -4) : "";
  
      // 請求番号を設定
      const invoiceNumber = `${traderCode}_${formattedDate}-1`;
  
      // 各フィールドに必要な値を設定
      return {
        "請求日": formattedDate, // 当日の日付を設定
        "請求番号": invoiceNumber, // 修正した請求番号
        "件名": "産業廃棄物SDGs推進システムサービス利用料",
        "取引先管理コード": traderCode, // 下4桁を削除した取引先管理コード
        "消費税設定": row.taxSetting || "", // undefinedの場合は空文字列
        "お支払い期限": row.paymentDeadline || "", // undefinedの場合は空文字列
        "品目1": "料金区分Ａ", // 固定値
        "数量1": row.A || "", // undefinedの場合は空文字列
        "単位1": "件", // 固定値
        "単価1": 140, // 固定値
        "消費税率1": row.taxRateA || "", // undefinedの場合は空文字列
        "非課税フラグ1": row.nonTaxFlagA || "", // undefinedの場合は空文字列
        "品目2": "料金区分Ｂ", // 固定値
        "数量2": row.B || "", // undefinedの場合は空文字列
        "単位2": "件", // 固定値
        "単価2": 140, // 固定値
        "消費税率2": row.taxRateB || "", // undefinedの場合は空文字列
        "非課税フラグ2": row.nonTaxFlagB || "", // undefinedの場合は空文字列
        "品目3": "料金区分Ｃ", // 固定値
        "数量3": row.C || "", // undefinedの場合は空文字列
        "単位3": "件", // 固定値
        "単価3": 160, // 固定値
        "消費税率3": row.taxRateC || "", // undefinedの場合は空文字列
        "非課税フラグ3": row.nonTaxFlagC || "", // undefinedの場合は空文字列
        "備考": "いつもありがとうございます。繰越請求金額 円、今回請求金額 円、合計請求金額 円（税込）です。どうぞよろしくお願いします。" // 備考
      };
    }).filter(Boolean); // nullを除外
  
    // CSVのヘッダーを設定
    const csvHeader = [
      "請求日", "請求番号", "件名", "取引先管理コード", "消費税設定", "お支払い期限",
      "品目1", "数量1", "単位1", "単価1", "消費税率1", "非課税フラグ1",
      "品目2", "数量2", "単位2", "単価2", "消費税率2", "非課税フラグ2",
      "品目3", "数量3", "単位3", "単価3", "消費税率3", "非課税フラグ3",
      "備考"
    ];
  
    const csvContent = [
      csvHeader.map(field => `"${field}"`).join(','), // ヘッダーをダブルクォーテーションで囲む
      ...csvData.map(row => Object.values(row).map(value => `"${value}"`).join(',')) // 各行のデータをダブルクォーテーションで囲む
    ].join('\n');
  
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'selected_data.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <h1>請求データ表示・出力（{new Date().toLocaleString()})　　　
        <Button
              onClick={() => window.location.reload()}
              color="primary"
              style={{ marginLeft: '20px' }}  // ここで左側のマージンを設定
            >
              最新（再読み込み）
        </Button>
      </h1>
        <Grid container spacing={2}>
          <Grid item xs style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}>
          {/* 区間１運搬事業者の入力エリア（条件付きレンダリング） */}
          {currentUser.traderKbn === '1111111111' && (
            <>
              <Typography variant="h7" style={{ fontWeight: 700 }}>　区間１運搬事業者：</Typography>
              <TextField
                value={filter.sect1TransportName}
                onChange={e => handleFilterChange({ ...filter, sect1TransportName: e.target.value })}
                onKeyDown={(e) => { if (e.key === 'Enter') startFilter(); }}
              />
            </>
          )}
          <Box p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>請求期間：</Typography>
          </Box>
          <Button onClick={moveInvoicePeriodBackward} style={{ marginLeft: 8 }}>◀</Button>
          <TextField
            value={filter.searchDateStart}
            onChange={e => handleFilterChange({ ...filter, searchDateStart: e.target.value })}
            type="date"
            InputLabelProps={{ shrink: true }}
          />
          <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
          <TextField
            value={filter.searchDateEnd}
            onChange={e => handleFilterChange({ ...filter, searchDateEnd: e.target.value })}
            type="date"
            InputLabelProps={{ shrink: true }}
          />
          <Button onClick={moveInvoicePeriodForward} style={{ marginLeft: 8 }}>▶</Button>

          <Typography variant="h7" style={{ fontWeight: 700, marginLeft: '16px' }}>　請求対象日：</Typography>
          <FormControl className={classes.formControl}>
            <Select
              value={filter.invoiceDateType}
              onChange={e => handleFilterChange({ ...filter, invoiceDateType: e.target.value })}
              displayEmpty
              className={classes.selectEmpty}
            >
              <MenuItem value="0">排出日</MenuItem>
              <MenuItem value="1">作成日</MenuItem>
              <MenuItem value="2">中間処理日</MenuItem>
              <MenuItem value="3">最終処分日</MenuItem>
            </Select>
          </FormControl>

          <Typography variant="h7" style={{ fontWeight: 700 }}>　　　　排出業者：</Typography>
          <TextField
            id="exhaustName"
            value={filter.exhaustName}
            onChange={e => handleFilterChange({ ...filter, exhaustName: e.target.value })}
            label=""
            type="text"
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                startFilter();
              }
            }}
          />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物名称：</Typography>
          <TextField
            id="wasteName"
            value={filter.wasteName}
            onChange={e => handleFilterChange({ ...filter, wasteName: e.target.value })}
            label=""
            type="text"
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                startFilter();
              }
            }}
          />

            <Button
              variant='contained'
              color='primary'
              onClick={startFilter}
              style={{ marginLeft: 24 }}
              disabled={!isFilterChanged}
            >
              データ表示
            </Button>
            　　
          </Grid>
        </Grid>

        <SpaceRow height={10} />
      {currentUser.traderKbn === '1111111111' && (
        <div className={classes.container}>
          <Box sx={{ height: 412, width: '100%' }}>
            <DataGridPro
              sx={styles.grid}
              columns={summaryColumns}
              rows={summaryData}
              loading={isLoading}
              density="compact"
              checkboxSelection // チェックボックスを追加
              onSelectionModelChange={handleSelectionChange} // 選択モデルの変更を処理
              components={{
                Toolbar: CustomToolbar, // カスタムツールバーを指定する
              }}
              showColumnRightBorder
              showCellRightBorder
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleadminRowClick}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={exportSelectedToCSV}
            style={{ marginTop: '10px', float: 'right' }} // 右下に配置
            disabled={selectedRows.length === 0} // 選択されていない場合は非活性
          >
            MISOCA用CSV出力
          </Button>
        </div>
      )}

      {currentUser.traderKbn === '1111111111' && <SpaceRow height={50} />} {/* Conditional SpaceRow */}
        <div className={classes.container}>
          <Box sx={{ height: 412, width: '100%' }}>
            <DataGridPro
              sx={styles.grid}
              columns={cols}
              rows={dataList}
              loading={isLoading}
              density="compact"
              components={{
                Toolbar: CustomToolbar,　// カスタムツールバーを指定する
              }}
              showColumnRightBorder
              showCellRightBorder
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleRowClick}
            />
          </Box>
        </div>
      <Box 
        mt={2} 
        p={2} 
        border={1} 
        borderColor="grey.300" 
        borderRadius={4} 
        maxWidth="80%" 
        margin="auto"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h7" align="center">
          表示中データの合計
          　　
          マニフェスト発行件数: {calculateTotals().kensu}
          　　
          マニフェスト取消件数: {calculateTotals().torikesi}
          　　
          マニフェスト登録件数: {calculateTotals().touroku}
        </Typography>
      </Box>
      <SpaceRow height={10} />
      <div className={classes.container}>
        <Box sx={{ width: '100%' }}>
          <DataGridPro
            sx={{
              ...styles.grid,
              '& .MuiDataGrid-main': {
                height: 'auto !important',
              },
              '& .MuiDataGrid-virtualScroller': {
                height: 'auto !important',
              },
              '& .MuiDataGrid-root': {
                maxHeight: '600px !important',
              }
            }}
            columns={detailColumns}
            rows={detailData}
            loading={isDetailLoading}
            density="compact"
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}
            showColumnRightBorder
            showCellRightBorder
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            autoHeight
            disableExtendRowFullWidth
            hideFooter={detailData.length <= 100}
            pageSize={100}
            onRowClick={handleDetailRowClick}
          />
        </Box>

      </div>
      <Box component='div' sx={{ p: 0.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="subtitle" style={{ fontWeight: 'bold' }}>
          　　　　　　　　　　↑　レコードをクリックすると詳細データを表示します。
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          style={{ marginLeft: 10 }} // 左にマージンを追加
          onClick={handleOpen1}
          disabled={detailData.length === 0} 
        >
          請求書作成
        </Button>
      </Box>
      <InvoiceDataEdit
          isOpen={detailOpen1}
          doClose={() => setDetailOpen1(false)}
          currentUser={currentUser}
          dataList={detailData}  // 0901
          filter={filter}
          jwnetNo={selectedJwnetNo}
      />
      {invoiceDetailData.length > 0 && (
        <>
          <Typography variant="subtitle" style={{ fontWeight: 'bold', textAlign: 'left' }}>
          詳細データ
        </Typography>
          <SpaceRow height={10} /> 
          <div className={classes.container}>
            <Box sx={{ width: '100%' }}>
              <DataGridPro
                sx={{
                  ...styles.grid,
                  '& .MuiDataGrid-main': {
                    height: 'auto !important',
                  },
                  '& .MuiDataGrid-virtualScroller': {
                    height: 'auto !important',
                  },
                  '& .MuiDataGrid-root': {
                    maxHeight: '600px !important',
                  }
                }}
                columns={invoiceDetailColumns}
                rows={invoiceDetailData}
                loading={isInvoiceDetailLoading}
                density="compact"
                components={{
                  Toolbar: CustomToolbar,　// カスタムツールバーを指定する
                }}
                showColumnRightBorder
                showCellRightBorder
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                autoHeight
                disableExtendRowFullWidth
                hideFooter={invoiceDetailData.length <= 100}
                pageSize={100}
              />
            </Box>
          </div>
          {/* <InvoiceDataEdit /> */}
        </>
      )}      
    </>
  );
};

const styles = {
  grid: {
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
};

export default InvoiceDataList;
