import React, { useEffect, useState } from 'react';
import Draggable from "react-draggable";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { Button, TextField, Typography, makeStyles } from '@material-ui/core';
import { getInvoiceSettingUser, updateInvoiceSetting } from '../../lib/api/invoice_setting'; // API関数をインポート

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title2"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function InvoiceMyInfoEdit({ isOpen, doClose, currentUser }) {
  const [companyInfo, setCompanyInfo] = useState({
    id: '',
    trader_cd: '',
    name1: '',
    name2: '',
    name3: '',
    zip: '',
    address1: '',
    address2: '',
    address3: '',
    tel: '',
    fax: '',
    email1: '',
    email2: '',
    email3: '',
    tno: '',
    taxdisp: '',
    taxrate: '',
    taxround: '',
    account1: '',
    account2: '',
    account3: '',
    memo1: '',
    memo2: '',
    memo3: '',
    subject: '',
    logo_image: null,
    stamp_image: null,
  });
  const [error, setError] = useState('');

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    dialogContent: {
      width: '800px', // ダイアログの横幅を広げる
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      try {
        console.log("currentUser.traderCd",currentUser.traderCd);
        const response = await getInvoiceSettingUser(currentUser.traderCd);
        console.log("response",response);
        setCompanyInfo(response.data); // APIから取得したデータをステートに設定
      } catch (error) {
        console.error("Error fetching company info:", error);
      }
    };

    fetchCompanyInfo();
  }, [currentUser.traderCd]);

  const handleChange = (e) => {
    setCompanyInfo({
      ...companyInfo,
      [e.target.name]: e.target.value,
    });
  };

  // Handle file selection for logo and stamp images
  const handleFileChange = (e) => {
    const { name, files } = e.target;

    if (files[0]) {
      // ファイルサイズをチェック（1MB = 1 * 1024 * 1024 bytes）
      if (files[0].size > 1 * 1024 * 1024) {
        setError('ファイルサイズは1MB以下でなければなりません。');
        e.target.value = ''; // 入力をクリア
      } else {
        setError(''); // エラーをクリア
        setCompanyInfo((prevData) => ({ ...prevData, [name]: files[0] }));
      }
    }
  };

  const handleCancel = () => {
    doClose();
  };

  const handleSave = async (e) => {
    const params = generateParams();
    console.log("companyInfo",companyInfo)
    console.log("params",params)
    try {
      e.preventDefault();
      await updateInvoiceSetting(companyInfo.id, params); // APIを呼び出してデータを更新
      doClose(); // 更新が成功したらポップアップを閉じる
    } catch (error) {
      console.error("Error updating company info:", error);
    }
  };

  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    return {
      trader_cd: companyInfo.trader_cd, // 修正: companyInfo.traderCd -> companyInfo.trader_cd
      name1: companyInfo.name1,
      name2: companyInfo.name2,
      name3: companyInfo.name3,
      zip: companyInfo.zip,
      address1: companyInfo.address1,
      address2: companyInfo.address2,
      address3: companyInfo.address3,
      tel: companyInfo.tel,
      fax: companyInfo.fax,
      email1: companyInfo.email1,
      email2: companyInfo.email2,
      email3: companyInfo.email3,
      tno: companyInfo.tno, // 修正: companyInfo.tNo -> companyInfo.tno
      taxdisp: companyInfo.taxdisp, // 修正: companyInfo.taxDisp -> companyInfo.taxdisp
      taxrate: companyInfo.taxrate, // 修正: companyInfo.taxRate -> companyInfo.taxrate
      taxround: companyInfo.taxround, // 修正: companyInfo.taxRound -> companyInfo.taxround
      account1: companyInfo.account1,
      account2: companyInfo.account2,
      account3: companyInfo.account3,
      memo1: companyInfo.memo1,
      memo2: companyInfo.memo2,
      memo3: companyInfo.memo3,
      subject: companyInfo.subject,
      taxrate: companyInfo.taxrate,
      logo_image: companyInfo.logo_image,
      stamp_image: companyInfo.stamp_image,
    };
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title2"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title2">
        <h2>自社情報編集</h2>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <form noValidate autoComplete='off'>
          <table>
            <tbody>
            <tr>
                <th style={{ fontSize: '1rem' }}>件名 *</th>
                <td>
                  <TextField
                    id='subject'
                    label='件名'
                    name="subject"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    value={companyInfo.subject}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ fontSize: '1rem' }}>自社名1 *</th>
                <td>
                  <TextField
                    id='name1'
                    label='自社名1'
                    name="name1"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    value={companyInfo.name1}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ fontSize: '1rem' }}>自社名2</th>
                <td>
                  <TextField
                    id='name2'
                    label='自社名2'
                    name="name2"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    value={companyInfo.name2}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ fontSize: '1rem' }}>郵便番号</th>
                <td>
                  <TextField
                    id='zip'
                    label='郵便番号'
                    name="zip"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    value={companyInfo.zip}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ fontSize: '1rem' }}>住所1 *</th>
                <td>
                  <TextField
                    id='address1'
                    label='住所1'
                    variant="outlined"
                    type='text'
                    name='address1'
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    value={companyInfo.address1}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ fontSize: '1rem' }}>住所2</th>
                <td>
                  <TextField
                    id='address2'
                    label='住所2'
                    variant="outlined"
                    type='text'
                    name='address2'
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    value={companyInfo.address2}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ fontSize: '1rem' }}>ＴＥＬ *</th>
                <td>
                  <TextField
                    id='tel'
                    label='電話番号'
                    variant="outlined"
                    type='text'
                    name='tel'
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    value={companyInfo.tel}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ fontSize: '1rem' }}>ＦＡＸ</th>
                <td>
                  <TextField
                    id='fax'
                    label='FAX番号'
                    variant="outlined"
                    type='text'
                    name='fax'
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    value={companyInfo.fax}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ fontSize: '1rem' }}>メールアドレス</th>
                <td>
                  <TextField
                    id='email1'
                    label='メールアドレス'
                    variant="outlined"
                    type='text'
                    name='email1'
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    value={companyInfo.email1}
                    inputProps={{ maxLength: 50, pattern: "^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$" }}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ fontSize: '1rem' }}>登録番号 *</th>
                <td>
                  <TextField
                    id='tno'
                    label='登録番号'
                    variant="outlined"
                    type='text'
                    name='tno'
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    value={companyInfo.tno}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ fontSize: '1rem' }}>振込先1 *</th>
                <td>
                  <TextField
                    id='account1'
                    label='振込先1'
                    variant="outlined"
                    type='text'
                    name='account1'
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    value={companyInfo.account1}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ fontSize: '1rem' }}>振込先2</th>
                <td>
                  <TextField
                    id='account2'
                    label='振込先2'
                    variant="outlined"
                    type='text'
                    name='account2'
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    value={companyInfo.account2}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ fontSize: '1rem' }}>消費税率（%）</th>
                <td>
                  <TextField
                    id='taxrate'
                    label='消費税率'
                    variant="outlined"
                    type='text'
                    name='taxrate'
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    value={companyInfo.taxrate}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ fontSize: '1rem' }}>ロゴ</th>
                <td>
                  <TextField
                    id='logo_image'
                    label='logo_image'
                    type='file'
                    name='logo_image'
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ fontSize: '1rem' }}>印影</th>
                <td>
                  <TextField
                    id='stamp_image'
                    label='stamp_image'
                    type='file'
                    name='stamp_image'
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </td>
              </tr>
            </tbody>
            {error && (
              <tr>
                <td colSpan="2">
                  <Typography color="error">{error}</Typography>
                </td>
              </tr>
            )}
          </table>
        </form>
        <div style={{ textAlign: 'right' }}>
          <Button
            variant='contained'
            onClick={handleCancel}
            style={{ margin: 10 }}
          >
            戻る
          </Button>          
          <Button
            type='submit'
            variant='contained'
            color='primary'
            onClick={handleSave} // handleSave関数を呼び出す
            style={{ margin: 10 }}
          >
            更新
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default InvoiceMyInfoEdit;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});