import React, { useState, useEffect, useRef } from "react";
import Draggable from 'react-draggable'; // Draggableをインポート
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions 
} from "@mui/material";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { DataGridPro } from '@mui/x-data-grid-pro'; // DataGridProをインポート
import html2canvas from 'html2canvas'; // html2canvasをインポート
import jsPDF from 'jspdf'; // jsPDFをインポート
import { getInvoiceSettingUser } from '../../lib/api/invoice_setting';
import SpaceRow from '../commons/SpaceRow';
import InvoiceMyInfoEdit from './InvoiceMyInfoEdit'; // 自社情報編集画面のコンポーネントをインポート

function InvoiceDataEdit({ isOpen, doClose, currentUser, dataList, filter, jwnetNo }) {
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [price, setPrice] = useState(0);
  const [customerName, setCustomerName] = useState(""); // 顧客名を管理するステート
  const [invoiceInfo, setInvoiceInfo] = useState(""); // 請求元情報を管理するステート
  const [date, setDate] = useState(new Date()); // 日付を管理するステート
  const [paymentDueDate, setPaymentDueDate] = useState(new Date()); // お支払い期限を管理するステート
  const [traderData, setTraderData] = useState(""); // 請求元のdataを管理するステート
  const [isEditable, setIsEditable] = useState(false); // 編集可能かどうかのステート
  const [invoiceNumber, setInvoiceNumber] = useState(''); // 請求番号を管理するステート
  const [registrationNumber, setRegistrationNumber] = useState(''); // 登録番号を管理するステート
  const [remarks, setRemarks] = useState(""); // 備考欄のステートを追加
  const [detailOpen2, setDetailOpen2] = useState(false); // 自社情報編集画面の表示状態を管理
  const pdfRef = useRef(); // PDF出力用のrefを作成

  // 日付をyyyymmdd形式で取得する関数
  const getFormattedDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月は0から始まるため+1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`; // yyyymmdd形式
  };

  useEffect(() => {
    setPaymentDueDate(getNextMonthEndDate()); // コンポーネントがマウントされたときに設定
    // 請求番号を設定
    setInvoiceNumber(`${jwnetNo || '不明'}_${getFormattedDate()}-1`); // 請求番号を設定
  }, [jwnetNo]); // jwnetNoが変更されたときにも再設定

  // 翌月末日を計算する関数
  const getNextMonthEndDate = () => {
    const today = new Date();
    const nextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0); // 翌月の末日を取得
    return nextMonth; // Dateオブジェクトを返す
  };

  // dataListが変更されたときにitemsを初期化
  useEffect(() => {
    if (dataList && dataList.length > 0) {
      const initialItems = dataList.map((item, index) => ({
        id: index, // 一意のIDを追加
        description: `${item.wasteName}　（ ${item.packingName}　${Math.floor(item.packingvol)} ）`, // 品名
        quantity: item.vol, // 数量
        unitName: item.unitName, // 単位
        price: 0, // 初期単価は0
        canDelete: false, // 削除マークの表示制御
      }));
      setItems(initialItems);
      // 合計を計算
      const newTotal = initialItems.reduce((acc, item) => {
        return acc + item.quantity * item.price;
      }, 0);
      setTotal(newTotal);
      // 顧客名を設定
      setCustomerName(dataList[0].exhaustName); // 最初のデータから顧客名を取得
    }
    handleGetList();
  }, [dataList]);

  const handleGetList = async () => {
    const fetchTraderName = async () => {
      try {
        const traderData = await getInvoiceSettingUser(currentUser.traderCd);
        console.log("traderData", traderData);
        setTraderData(traderData.data); // traderNameをステートに設定
      } catch (error) {
        console.error("Error fetching trader name:", error);
      }
    };

    if (currentUser && currentUser.traderCd) {
      fetchTraderName();
    }
  };

  // お支払い期限が変更されたときの処理
  const handlePaymentDueDateChange = (e) => {
    setPaymentDueDate(new Date(e.target.value)); // 選択された日付をDateオブジェクトに変換
  };

  const handleAddItem = () => {
    const newItem = {
      id: items.length, // 一意のIDを追加
      description,
      quantity: parseFloat(quantity),
      price: parseFloat(price),
      canDelete: isEditable, // 編集可能な場合のみ削除マークを表示
    };

    const updatedItems = [...items, newItem];
    setItems(updatedItems);

    const newTotal = updatedItems.reduce((acc, item) => {
      return acc + item.quantity * item.price;
    }, 0);
    setTotal(newTotal);

    // フォームのリセット
    setDescription("");
    setQuantity(0);
    setPrice(0);
  };

  const handleToggleEdit = () => {
    setIsEditable(!isEditable);
    // 編集可能状態に応じて削除マークを表示
    const updatedItems = items.map(item => ({
      ...item,
      canDelete: !isEditable,
    }));
    setItems(updatedItems);
  };
  
  const handleRowReorder = (newRows) => {
    setItems(newRows); // 新しい行の順序を設定
  };

  const handleCellEditCommit = (params) => {
    const updatedRows = items.map(item => {
      if (item.id === params.id) {
        return { ...item, [params.field]: params.value };
      }
      return item;
    });

    setItems(updatedRows);

    // 金額を再計算
    const newTotal = updatedRows.reduce((acc, item) => {
      return acc + (item.quantity * item.price);
    }, 0);
    setTotal(newTotal);
  };

  const handleDeleteItem = (id) => {
    const updatedItems = items.filter(item => item.id !== id);
    setItems(updatedItems);

    const newTotal = updatedItems.reduce((acc, item) => {
      return acc + (item.quantity * item.price);
    }, 0);
    setTotal(newTotal);
  };

  const PaperComponent = ({...props}) => {
    const nodeRef = React.useRef(null);
  
    return (
      <Draggable
        handle="#form-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
        nodeRef={nodeRef}
      >
        <Paper ref={nodeRef} {...props} />
      </Draggable>
    );
  }

  // 自社情報編集画面を開く処理
  const handleOpen2 = () => {
    setDetailOpen2(true);
  };

  // 自社情報編集画面を閉じた後の処理
  const handleCloseCompanyInfo = () => {
    setDetailOpen2(false);
    handleGetList(); // 自社情報を再取得
  };

  const handleCancel = () => {
    doClose();
  };

  // DataGridPro の高さを計算する関数
  const calculateGridHeight = () => {
    const rowHeight = 52;
    const headerHeight = 56;
    const footerHeight = 48;
    const maxRows = 10;
    const totalHeight = Math.min(items.length, maxRows) * rowHeight + headerHeight + footerHeight;
    return totalHeight;
  };

  // PDF出力の関数
  const handlePDFExport = () => {
    html2canvas(pdfRef.current).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgWidth = 190; // PDFの幅
      const pageHeight = pdf.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(`invoice_${invoiceNumber}.pdf`);
    });
  };
  // DataGridProのカラム定義
  const columns = [
    { field: 'description', headerName: '品目・品名', width: 400, editable: isEditable },
    { 
      field: 'quantity', 
      headerName: '数量', 
      type: 'number', 
      editable: isEditable,
      renderCell: (params) => (params.value === 0 ? '' : params.value), // 0の場合は空文字を返す
    },
    { field: 'unitName', headerName: '単位', width: 100, editable: isEditable },
    { field: 'price', headerName: '単価', type: 'number', editable: isEditable,
      renderCell: (params) => (params.value === 0 ? '' : params.value), // 0の場合は空文字を返す
    },
    { 
      field: 'total', 
      headerName: '金額', 
      type: 'number', 
      valueGetter: (params) => {
        const total = Math.floor(params.row.quantity * params.row.price);
        return total === 0 ? '' : `¥${total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`; // 3桁区切り
      }
    },
    {
      field: 'delete',
      headerName: '削除',
      renderCell: (params) => (
        <Button 
          onClick={() => handleDeleteItem(params.id)} 
          disabled={!isEditable} // 編集可能でない場合は無効
        >
          削除
        </Button>
      ),
      hide: !isEditable, // 編集可能でない場合は非表示
    },
  ];

  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"lg"}
      PaperProps={{ style: { width: '1600px' } }}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title"></DialogTitle>
      <DialogContent>
        <Container maxWidth="md" ref={pdfRef}>
          <Grid container spacing={0.5}>
            <Grid item xs={10}></Grid>
            <Grid item xs={2}>
              <TextField
                type="date"
                value={date.toISOString().split('T')[0]}
                onChange={(e) => {
                  setDate(new Date(e.target.value));
                }}
                fullWidth
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  style: { padding: 0 },
                  readOnly: !isEditable,
                }}
                InputLabelProps={{
                  shrink: false,
                }}
              />
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={4}>
              <Typography variant="body1" gutterBottom sx={{ marginTop: 0.5 }}>
                請求番号：
                {isEditable ? (
                  <TextField
                    value={invoiceNumber} // 請求番号のステートを使用
                    onChange={(e) => setInvoiceNumber(e.target.value)} // 請求番号の変更を処理
                    variant="outlined" // variantをoutlinedに変更
                    sx={{ marginLeft: 1 }} // スペースを追加
                    size="small"
                  />
                ) : (
                  <span>{invoiceNumber}</span> // 編集終了時は表示
                )}
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="h4" align="left" gutterBottom sx={{ color: 'blue' }}>
            請　求　書
          </Typography>
          <Typography variant="h5" align="left" gutterBottom sx={{ borderBottom: '2px solid black', display: 'inline-block' }}>
            {customerName} 　御中
          </Typography>
          <Paper elevation={0} sx={{ padding: 3 }}>
            <Grid container spacing={2}>
              <Typography variant="h6">件名: {traderData?.subject || ''}</Typography>
            </Grid>
          </Paper>
          <Grid container spacing={2} columns={16}>
            <Grid item xs={8}>
              <Typography variant="body1" gutterBottom>
                下記のとおりご請求申し上げます。
              </Typography>
              <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
                ご請求金額：
                <Typography variant="h5" component="span" sx={{ fontWeight: 'bold' }}>
                  ¥{(Math.floor(total) + Math.floor(total * (traderData.taxrate / 100))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ‐
                </Typography>
              </Typography>
              <Box sx={{ borderBottom: '2px solid black', marginY: 1 }} />
              {/* お支払い期限の表示と変更 */}
              <Grid container spacing={0.5}>
                <Grid item xs={10}></Grid>
                <Grid item xs={8}>
                  <Typography variant="body1" gutterBottom sx={{ marginTop: 0.5 }}>
                    　　お支払い期限：
                    {isEditable ? (
                      <TextField
                        type="date" // typeをdateに変更
                        value={paymentDueDate.toISOString().split('T')[0]} // ISO形式の日付をYYYY-MM-DD形式に変換
                        onChange={handlePaymentDueDateChange} // お支払い期限変更時の処理
                        variant="outlined" // variantをoutlinedに変更
                        sx={{ marginLeft: 1 }} // スペースを追加
                        size="small"
                      />
                    ) : (
                      <span>{`${paymentDueDate.getFullYear()}/${String(paymentDueDate.getMonth() + 1).padStart(2, '0')}/${String(paymentDueDate.getDate()).padStart(2, '0')}`}</span> // 編集終了時は表示
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8} sx={{ position: 'relative' }}> {/* position: relativeを追加 */}
              <Typography variant="h6" gutterBottom>
                {traderData?.name1 || '不明'}
              </Typography>
              <Typography variant="h6" gutterBottom>
                {traderData?.name2 || ''}
              </Typography>
              <Typography variant="body1" gutterBottom>
                〒{traderData?.zip?.slice(0, 3) || ''}-{traderData?.zip?.slice(3) || ''}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {traderData?.address1 || ''}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {traderData?.address2 || ''}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {traderData?.address3 || ''}
              </Typography>
              <Typography variant="body1" gutterBottom>
                TEL：{traderData?.tel || '不明'}　　FAX：{traderData?.fax || ''}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Email：{traderData?.email1 || ''}
              </Typography>
              <Typography variant="body1" gutterBottom sx={{ marginTop: 0.5 }}>
                登録番号：{traderData?.tno || ''}
              </Typography>
              
              {/* 画像を重ねるためのBox */}
              {traderData?.logoImage && (
                <Box
                  component="img"
                  src={`data:image/png;base64,${traderData?.logoImage}`} // Base64エンコードされた画像データを表示
                  alt="Overlay Image"
                  sx={{
                    position: "absolute",
                    top: "-25%",  // 高さの調整
                    left: "60%",  // 横位置の調整
                    transform: "translate(-50%, -50%)",  // 画像を中央に配置
                    width: "150px",  // 画像サイズの調整
                    opacity: 0.7,  // 透過度の調整
                  }}
                />
              )}

              {traderData?.stampImage && (
                <Box
                  component="img"
                  src={`data:image/png;base64,${traderData?.stampImage}`} // Base64エンコードされた画像データを表示
                  alt="Overlay Image"
                  sx={{
                    position: "absolute",
                    top: "55%",  // 高さの調整
                    left: "90%",  // 横位置の調整
                    transform: "translate(-50%, -50%)",  // 画像を中央に配置
                    width: "100px",  // 画像サイズの調整
                    opacity: 0.7,  // 透過度の調整
                  }}
                />
              )}
            </Grid>
          </Grid>

          {/* DataGridProを追加 */}
          <Box mt={4}>
          <Typography variant="h6">
            請求期間： {filter.searchDateStart} ～ {filter.searchDateEnd}
          </Typography>
          <div style={{ height: calculateGridHeight(), width: '100%' }}>
            <DataGridPro
              sx={{
                ...styles.grid,
                '& .MuiDataGrid-main': { height: 'auto !important' },
                '& .MuiDataGrid-virtualScroller': { height: 'auto !important' },
                '& .MuiDataGrid-root': { maxHeight: '300px !important' }
              }}
              columns={columns}
              rows={items}
              density="compact"
              showColumnRightBorder
              showCellRightBorder
              autoHeight
              pageSize={100}
              disableSelectionOnClick
              hideFooter={items.length <= 100}
              onCellEditCommit={handleCellEditCommit}
              onRowReorder={handleRowReorder}
              // rowReordering={isEditable} // 編集可能な場合のみ行移動を有効にする
              // onRowOrderChange={handleRowOrderChange}
            />
            {/* 行追加ボタンの表示制御 */}
            {isEditable && (
              <Box mt={2} display="flex" justifyContent="space-between">
                <Button variant="contained" onClick={handleAddItem}>
                  行追加
                </Button>
              </Box>
            )}
            </div>
          </Box>
          {/* 小計、消費税、合計エリア */}
          <Box sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white', padding: 1, zIndex: 1 }}>
            {/* 小計を表示 */}
            <Box display="flex" justifyContent="flex-end" sx={{ borderBottom: '2px solid transparent', background: 'linear-gradient(to right, transparent 50%, #cccccc 50%)' }}>
              <Typography variant="h6">小計: ¥{Math.floor(total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
            </Box>
            {/* 消費税を表示 */}
            <Box mt={1} display="flex" justifyContent="flex-end" sx={{ borderBottom: '2px solid transparent', background: 'linear-gradient(to right, transparent 50%, #cccccc 50%)' }}>
              <Typography variant="h6">
                消費税（{traderData.taxrate}％）： ¥{Math.floor(total * (traderData.taxrate / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Typography>
            </Box>
            {/* 合計を表示 */}
            <Box mt={1} display="flex" justifyContent="flex-end" sx={{ borderBottom: '2px solid transparent', background: 'linear-gradient(to right, transparent 50%, #cccccc 50%)' }}>
              <Typography variant="h6">合計: ¥{(Math.floor(total) + Math.floor(total * (traderData.taxrate / 100))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
            </Box>
          </Box>
          <Box mt={2}>
            {/* 備考欄の表示 */}
            <TextField
              label="備考"
              multiline
              rows={3}
              variant="outlined"
              fullWidth
              value={isEditable ? remarks : remarks} // 編集可能なときはステートの値を表示
              onChange={isEditable ? (e) => setRemarks(e.target.value) : undefined} // 編集可能なときのみ変更を処理
              InputProps={{
                readOnly: !isEditable, // 編集可能でないときは読み取り専用
              }}
            />
          </Box>
          {/* 振込先情報の表示エリア */}
          <Box mt={2} sx={{ border: '1px solid black', padding: 1 }}>
            <Typography variant="body1" gutterBottom sx={{ backgroundColor: '#f0f0f0', display: 'inline-block', padding: '4px' }}>
              【お振込先】
            </Typography>
            <Typography variant="body1" gutterBottom>
            　　　{traderData?.account1 || ''}
            </Typography>
            <Typography variant="body1" gutterBottom>
            　　　{traderData?.account2 || ''}
            </Typography>
          </Box>
        </Container>
      </DialogContent>
      <div style={{ textAlign: 'right', marginRight: 20 }}>
        <Button 
          variant='contained'
          style={{ marginRight: 20 }}
          onClick={e => {
          e.preventDefault();
          handleCancel();
        }}
        sx={{ backgroundColor: '#d3d3d3',color: 'black', '&:hover': { backgroundColor: '#c0c0c0' } }}
        >
          戻る
        </Button>
        <Button 
          variant='contained'
          onClick={handleOpen2} // 自社情報ボタンのクリック処理
          style={{ marginRight: 20 }} // ボタンの右側にスペースを追加
          sx={{
              backgroundColor: '#ffa500',
            '&:hover': {
              backgroundColor: '#ffa500', 
            },
          }}
        >
          自社情報
        </Button>
        <Button
          variant="contained"
          style={{ marginRight: 20 }}
          onClick={handleToggleEdit}
          sx={{
              backgroundColor: isEditable ? '#32cd32' : 'primary',
            '&:hover': {
              backgroundColor: isEditable ? '#32cd32' : 'primary',
            },
          }}
        >
          {isEditable ? '編集終了' : '編集開始'}
        </Button>
        <Button 
          variant='outlined'
          color='primary'
          onClick={handlePDFExport} 
          style={{ marginRight: 20 }}
          startIcon={<PictureAsPdfIcon />} 
          disabled={isEditable} // isEditableがfalseのときに非活性
        >
          PDF出力
        </Button>
      </div>
        {/* 自社情報編集画面のポップアップ */}
        <InvoiceMyInfoEdit
          isOpen={detailOpen2}
          doClose={handleCloseCompanyInfo} // 閉じた後の処理を指定
          currentUser={currentUser}
        />
      <SpaceRow height={10} />
    </Dialog>
  );
};

export default InvoiceDataEdit;
const styles = {
  grid: {
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
};