// Form.jsx
import React, { useState, useEffect ,  useContext  } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Typography,
  InputLabel,
} from '@material-ui/core';

import {
  DataGridPro,
  jaJP
} from '@mui/x-data-grid-pro'

import SpaceRow from '../commons/SpaceRow';
import { getChargeUser, getChargeEffective } from '../../lib/api/charge';

//import { getEgsMChargeList } from '../../lib/api/egs_m_charge';
//import { getEgsMTraderList } from '../../lib/api/egs_m_trader';
import { getLinkageUser } from '../../lib/api/linkage';
import { getTraderSelList } from '../../lib/api/trader_sel';
import { getEgsMCharge } from '../../lib/api/egs_m_charge';
import { getChargeSelList } from '../../lib/api/charge_sel';

import { getEgsMWasteclassList } from '../../lib/api/egs_m_wasteclass';
import { getEgsMToxicSubstanceList } from '../../lib/api/egs_m_toxic_substance';
import { getEgsMDisposalMethodList } from '../../lib/api/egs_m_disposal_method';
import { getEgsMTransportMethodList } from '../../lib/api/egs_m_transport_method';
import { getEgsMUnitList } from '../../lib/api/egs_m_unit';
import { getEgsMPackingList } from '../../lib/api/egs_m_packing';
import { getEditDelRequestManiNo } from '../../lib/api/edit_del_request';

import './app.css';
import { AuthContext } from '../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',    // 100ch // 200ch
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 2,
    borderColor: "black",
    borderStyle: "solid",
    marginBottom: 8,
    width: "40vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "rgb(225, 242, 188)",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

const TransportDateForm = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { handleChange, handleSubmit, value, buttonType1,  buttonType2,  currentUser, handleCancel } = props;

  const { loading, isSignedIn, setIsSignedIn } =  // currentUser
    useContext(AuthContext);

  const sectId = currentUser.nickname;

  const [sectChargeList, setSectChargeList] = useState([]);
  const [selectedCharge, setSelectedCharge] = useState([]);

  const [newData, setnewData] = useState([]);

  const pdfRef = React.createRef();

//  const classes = useStyles();
  const [open, setOpen] = React.useState(false);


  useEffect(() => {
    fetchEditDelRequestManiNo(); // getEditDelRequestManiNoを呼び出す処理を追加
}, [value.manifestNo]);

  const fetchEditDelRequestManiNo = async () => {
    try {
//      const response1 = await getChargeUser(value.sect1TransportCd); // API呼び出し 
      const response1 = await getChargeEffective(value.sect1TransportCd); // API呼び出し 
      setSectChargeList(response1.data);
    } catch (error) {
      console.error("Failed to fetch ChargeUser:", error);
    }
    try {
      const response2 = await getEditDelRequestManiNo(value.manifestNo); // API呼び出し 
        setnewData(response2.data);
        if (response2.data.length > 0){
          value.editDelRequestFlg = '1';
        }  
          console.log("EditDelRequestManiNo data:", newData);
        
    } catch (error) {
      console.error("Failed to fetch EditDelRequestManiNo:", error);
    }
  };
  

  const handleSect1ChargeChange = (event) => {
    const newSect1ChargeName = event.target.value;
    setSelectedCharge(newSect1ChargeName);

    if (event.target.name == 'sect1TransportChargeId'){
      value.sect1TransportChargeName = newSect1ChargeName;
      value.sect1TransportChargeId = event.currentTarget.innerText.split(':')[0]  // 20240501
      value.sect1TransportChargeCd = event.currentTarget.innerText.split(':')[1]  // 20240501
      value.sect1TransportChargeName = event.currentTarget.innerText.split(':')[2]  // 20240501
    }
    if (event.target.name == 'sect2TransportChargeId'){
      value.sect2TransportChargeName = newSect1ChargeName;
      value.sect2TransportChargeId = event.currentTarget.innerText.split(':')[0]  // 20240501
      value.sect2TransportChargeCd = event.currentTarget.innerText.split(':')[1]  // 20240501
      value.sect2TransportChargeName = event.currentTarget.innerText.split(':')[2]  // 20240501
    }
    if (event.target.name == 'sect3TransportChargeId'){
      value.sect3TransportChargeName = newSect1ChargeName;
      value.sect3TransportChargeId = event.currentTarget.innerText.split(':')[0]  // 20240501
      value.sect3TransportChargeCd = event.currentTarget.innerText.split(':')[1]  // 20240501
      value.sect3TransportChargeName = event.currentTarget.innerText.split(':')[2]  // 20240501
    }
    if (event.target.name == 'sect4TransportChargeId'){
      value.sect4TransportChargeName = newSect1ChargeName;
      value.sect4TransportChargeId = event.currentTarget.innerText.split(':')[0]  // 20240501
      value.sect4TransportChargeCd = event.currentTarget.innerText.split(':')[1]  // 20240501
      value.sect4TransportChargeName = event.currentTarget.innerText.split(':')[2]  // 20240501
    }
    if (event.target.name == 'sect5TransportChargeId'){
      value.sect5TransportChargeName = newSect1ChargeName;
      value.sect5TransportChargeId = event.currentTarget.innerText.split(':')[0]  // 20240501
      value.sect5TransportChargeCd = event.currentTarget.innerText.split(':')[1]  // 20240501
      value.sect5TransportChargeName = event.currentTarget.innerText.split(':')[2]  // 20240501
    }

  };

  const cols = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
      width: 80
    },
    {
      field: 'reqDate',
      headerName: '依頼日',
      hide: true,
      width: 100
    },
    {
      field: 'reqCharge',
      headerName: '依頼担当者',
      hide: true,
      width: 100
    },
    {
      field: 'status',
      headerName: '処理状況',
      hide: true,
      width: 100
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      hide: true,
      width: 300
    },
    {
      field: 'manifestNo',
      headerName: 'マニフェストＮｏ',
      hide: true,
      width: 140
    },
    {
      field: 'reqKind',
      headerName: '依頼種別',
      width: 160
    },
    {
      field: 'reqComment',
      headerName: '補足事項',
      cellClassName: 'newData-cell',
      width: 200
    },
    {
      field: 'reqItem_1',
      headerName: '依頼項目１',
      cellClassName: 'reqItem-cell',
      width: 120
    },
    {
      field: 'oldData_1',
      headerName: '修正前１',
      width: 100
    },
    {
      field: 'newData_1',
      headerName: '修正後１',
      cellClassName: 'newData-cell',
      width: 100
    },
    {
      field: 'reqItem_2',
      headerName: '依頼項目２',
      cellClassName: 'reqItem-cell',
      width: 120
    },
    {
      field: 'oldData_2',
      headerName: '修正前２',
      width: 100
    },
    {
      field: 'newData_2',
      headerName: '修正後２',
      cellClassName: 'newData-cell',
      width: 100
    },
  ]

  const dataGridSection = (
    <div className={classes.container}>
      <Box sx={{ height: 160, width: '100%' }}>
        <DataGridPro
          sx={styles.grid}
          columns={cols}
          rows={newData}
          density="compact"
          showColumnRightBorder // 列ヘッダセルの右側に線を引く
          showCellRightBorder   // セルの右側に線を引く
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
    </div>
  );

  if (sectId == '1') {  // sectId
    return (
      <>
        <Backdrop className={classes.backdrop} open={open} >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div style={{ textAlign: 'right', margin: '10px' }}>
          <Button variant='contained' onClick={e => {
            e.preventDefault();
            // history.push('/garbage_edi');
            props.handleClose();
          }}>    {/*  egs_m_reserve_sets  */}
            戻る
          </Button>
        </div>
        <div className={classes.container}>
            <Box sx={{ height: 150, width: '100%' }}>
              <DataGridPro
                sx={styles.grid}
                columns={cols}
                rows={newData}
                density="compact"
                showColumnRightBorder // 列ヘッダセルの右側に線を引く
                showCellRightBorder   // セルの右側に線を引く
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              />
            </Box>
          </div>
          <SpaceRow height={10} />
        <div className={classes.container} ref={pdfRef}>
        <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  運搬完了日
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.destination1AtBk}　⇒　修正後：</Typography>
                <TextField id='destination1At' variant="outlined" type='date' name='destination1At' margin="dense" value={value.destination1At} onChange={(e) => handleChange(e)} />
              </Box>
            </Grid>
        </Grid>

        <Grid
            container
            spacing={0}
            className={classes.row}
          >
          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  ゴミタグ番号
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.garbageTag} </Typography>
              </Box>
            </Grid>

          </Grid>
          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  マニフェスト番号
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.manifestNo} </Typography>
              </Box>
            </Grid>

          </Grid>

            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  排出日
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.exhaustDate} </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  排出事業者・担当者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}      justifyContent={"flex-start"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.exhaustName} </Typography>
                　
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.exhaustChargeName} </Typography>
              </Box>
            </Grid>
            <Grid item xs />
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  廃棄物の数量
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}> {value.amount1} </Typography>
                  　
                  <Typography variant="h7" style={{ fontWeight: 700 }}> {value.unitName} </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  荷姿の数量（整数）
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}> {value.packingAmount1} </Typography>
                  　
                  <Typography variant="h7" style={{ fontWeight: 700 }}> {value.packingName} </Typography>
                </Box>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  収集運搬事業者・運搬担当者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.sect1TransportName}　　　{value.sect1TransportChargeNameBk}　⇒　修正後：</Typography>
                <Select
                  value={selectedCharge || ''} // 選択された運搬担当者、未選択時は空文字を設定
                  onChange={handleSect1ChargeChange} // 運搬担当者が変更された時の処理
//                  onChange={handleChange} // 運搬担当者が変更された時の処理
                  style={{ fontSize: "18px", color: "#ff00ff" }} // 選択後の文字の大きさを設定

                  name='sect1TransportChargeId'

                >
                  {sectChargeList ? sectChargeList.map((charge) => (
//                    <MenuItem key={charge.id} value={charge.chargeName}>{charge.chargeName}</MenuItem>
                    <MenuItem key={charge.id} value={charge.chargeName}>{charge.id}:{charge.chargeCd}:{charge.chargeName}</MenuItem>
                  )) : <MenuItem value="">データがありません</MenuItem>}
                </Select>
              </Box>
            </Grid>
          </Grid>

            <Grid item xs>
              <Box bgcolor="primary.gray" color="primary.black" p={1}      justifyContent={"flex-start"} alignItems={"center"}>
              </Box>
            </Grid>
            <Grid item xs />


        {/* </Grid> */}

        </div>
        <div style={{ textAlign: 'right' }}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              onClick={(e) => {
                handleSubmit(e);
              }}
              style={{ marginRight: 10 }}
            >
              {buttonType1}
            </Button>
        </div>
      </>
    );
  }else if (sectId == '2') {
    return (
      <>
        <Backdrop className={classes.backdrop} open={open} >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div style={{ textAlign: 'right', margin: '10px' }}>
          <Button variant='contained' onClick={e => {
            e.preventDefault();
            // history.push('/garbage_edi');
            props.handleClose();
          }}>    {/*  egs_m_reserve_sets  */}
            戻る
          </Button>
        </div>
        <div className={classes.container}>
            <Box sx={{ height: 150, width: '100%' }}>
              <DataGridPro
                sx={styles.grid}
                columns={cols}
                rows={newData}
                density="compact"
                showColumnRightBorder // 列ヘッダセルの右側に線を引く
                showCellRightBorder   // セルの右側に線を引く
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              />
            </Box>
          </div>
          <SpaceRow height={10} />
        <div className={classes.container} ref={pdfRef}>
        <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  運搬完了日
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.destination2AtBk}　⇒　修正後：</Typography>
                <TextField id='destination2At' variant="outlined" type='date' name='destination2At' margin="dense" value={value.destination2At} onChange={(e) => handleChange(e)} />
              </Box>
            </Grid>
        </Grid>

        <Grid
            container
            spacing={0}
            className={classes.row}
          >
          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  ゴミタグ番号
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.garbageTag} </Typography>
              </Box>
            </Grid>

          </Grid>
          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  マニフェスト番号
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.manifestNo} </Typography>
              </Box>
            </Grid>

          </Grid>

            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  排出日
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.exhaustDate} </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  排出事業者・担当者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}      justifyContent={"flex-start"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.exhaustName} </Typography>
                　
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.exhaustChargeName} </Typography>
              </Box>
            </Grid>
            <Grid item xs />
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  廃棄物の数量
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}> {value.amount1} </Typography>
                  　
                  <Typography variant="h7" style={{ fontWeight: 700 }}> {value.unitName} </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  荷姿の数量（整数）
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}> {value.packingAmount1} </Typography>
                  　
                  <Typography variant="h7" style={{ fontWeight: 700 }}> {value.packingName} </Typography>
                </Box>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  収集運搬事業者・運搬担当者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.sect2TransportName}　　　{value.sect2TransportChargeNameBk}　⇒　修正後：</Typography>
                <Select
                  value={selectedCharge || ''} // 選択された運搬担当者、未選択時は空文字を設定
                  onChange={handleSect1ChargeChange} // 運搬担当者が変更された時の処理
//                  onChange={handleChange} // 運搬担当者が変更された時の処理
                  style={{ fontSize: "18px", color: "#ff00ff" }} // 選択後の文字の大きさを設定

                  name='sect2TransportChargeId'

                >
                  {sectChargeList ? sectChargeList.map((charge) => (
                    <MenuItem key={charge.id} value={charge.chargeName}>{charge.id}:{charge.chargeCd}:{charge.chargeName}</MenuItem>
                  )) : <MenuItem value="">データがありません</MenuItem>}
                </Select>
              </Box>
            </Grid>
          </Grid>

            <Grid item xs>
              <Box bgcolor="primary.gray" color="primary.black" p={1}      justifyContent={"flex-start"} alignItems={"center"}>
              </Box>
            </Grid>
            <Grid item xs />


        {/* </Grid> */}

        </div>
        <div style={{ textAlign: 'right' }}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              onClick={(e) => {
                handleSubmit(e);
              }}
              style={{ marginRight: 10 }}
            >
              {buttonType1}
            </Button>
        </div>
      </>
    );
  }else if (sectId == '3') {
    return (
      <>
        <Backdrop className={classes.backdrop} open={open} >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div style={{ textAlign: 'right', margin: '10px' }}>
          <Button variant='contained' onClick={e => {
            e.preventDefault();
            // history.push('/garbage_edi');
            props.handleClose();
          }}>    {/*  egs_m_reserve_sets  */}
            戻る
          </Button>
        </div>
        <div className={classes.container}>
            <Box sx={{ height: 150, width: '100%' }}>
              <DataGridPro
                sx={styles.grid}
                columns={cols}
                rows={newData}
                density="compact"
                showColumnRightBorder // 列ヘッダセルの右側に線を引く
                showCellRightBorder   // セルの右側に線を引く
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              />
            </Box>
          </div>
          <SpaceRow height={10} />
        <div className={classes.container} ref={pdfRef}>
        <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  運搬完了日
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.destination3AtBk}　⇒　修正後：</Typography>
                <TextField id='destination3At' variant="outlined" type='date' name='destination3At' margin="dense" value={value.destination3At} onChange={(e) => handleChange(e)} />
              </Box>
            </Grid>
        </Grid>

        <Grid
            container
            spacing={0}
            className={classes.row}
          >
          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  ゴミタグ番号
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.garbageTag} </Typography>
              </Box>
            </Grid>

          </Grid>
          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  マニフェスト番号
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.manifestNo} </Typography>
              </Box>
            </Grid>

          </Grid>

            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  排出日
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.exhaustDate} </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  排出事業者・担当者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}      justifyContent={"flex-start"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.exhaustName} </Typography>
                　
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.exhaustChargeName} </Typography>
              </Box>
            </Grid>
            <Grid item xs />
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  廃棄物の数量
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}> {value.amount1} </Typography>
                  　
                  <Typography variant="h7" style={{ fontWeight: 700 }}> {value.unitName} </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  荷姿の数量（整数）
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}> {value.packingAmount1} </Typography>
                  　
                  <Typography variant="h7" style={{ fontWeight: 700 }}> {value.packingName} </Typography>
                </Box>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  収集運搬事業者・運搬担当者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.sect3TransportName}　　　{value.sect3TransportChargeNameBk}　⇒　修正後：</Typography>
                <Select
                  value={selectedCharge || ''} // 選択された運搬担当者、未選択時は空文字を設定
                  onChange={handleSect1ChargeChange} // 運搬担当者が変更された時の処理
//                  onChange={handleChange} // 運搬担当者が変更された時の処理
                  style={{ fontSize: "18px", color: "#ff00ff" }} // 選択後の文字の大きさを設定

                  name='sect3TransportChargeId'

                >
                  {sectChargeList ? sectChargeList.map((charge) => (
                    <MenuItem key={charge.id} value={charge.chargeName}>{charge.id}:{charge.chargeCd}:{charge.chargeName}</MenuItem>
                  )) : <MenuItem value="">データがありません</MenuItem>}
                </Select>
              </Box>
            </Grid>
          </Grid>

            <Grid item xs>
              <Box bgcolor="primary.gray" color="primary.black" p={1}      justifyContent={"flex-start"} alignItems={"center"}>
              </Box>
            </Grid>
            <Grid item xs />


        {/* </Grid> */}

        </div>
        <div style={{ textAlign: 'right' }}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              onClick={(e) => {
                handleSubmit(e);
              }}
              style={{ marginRight: 10 }}
            >
              {buttonType1}
            </Button>
        </div>
      </>
    );
  }else if (sectId == '4') {
    return (
      <>
        <Backdrop className={classes.backdrop} open={open} >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div style={{ textAlign: 'right', margin: '10px' }}>
          <Button variant='contained' onClick={e => {
            e.preventDefault();
            // history.push('/garbage_edi');
            props.handleClose();
          }}>    {/*  egs_m_reserve_sets  */}
            戻る
          </Button>
        </div>
        <div className={classes.container}>
            <Box sx={{ height: 150, width: '100%' }}>
              <DataGridPro
                sx={styles.grid}
                columns={cols}
                rows={newData}
                density="compact"
                showColumnRightBorder // 列ヘッダセルの右側に線を引く
                showCellRightBorder   // セルの右側に線を引く
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              />
            </Box>
          </div>
          <SpaceRow height={10} />
        <div className={classes.container} ref={pdfRef}>
        <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  運搬完了日
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.destination4AtBk}　⇒　修正後：</Typography>
                <TextField id='destination4At' variant="outlined" type='date' name='destination4At' margin="dense" value={value.destination4At} onChange={(e) => handleChange(e)} />
              </Box>
            </Grid>
        </Grid>

        <Grid
            container
            spacing={0}
            className={classes.row}
          >
          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  ゴミタグ番号
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.garbageTag} </Typography>
              </Box>
            </Grid>

          </Grid>
          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  マニフェスト番号
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.manifestNo} </Typography>
              </Box>
            </Grid>

          </Grid>

            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  排出日
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.exhaustDate} </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  排出事業者・担当者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}      justifyContent={"flex-start"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.exhaustName} </Typography>
                　
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.exhaustChargeName} </Typography>
              </Box>
            </Grid>
            <Grid item xs />
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  廃棄物の数量
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}> {value.amount1} </Typography>
                  　
                  <Typography variant="h7" style={{ fontWeight: 700 }}> {value.unitName} </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  荷姿の数量（整数）
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}> {value.packingAmount1} </Typography>
                  　
                  <Typography variant="h7" style={{ fontWeight: 700 }}> {value.packingName} </Typography>
                </Box>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  収集運搬事業者・運搬担当者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.sect4TransportName}　　　{value.sect4TransportChargeNameBk}　⇒　修正後：</Typography>
                <Select
                  value={selectedCharge || ''} // 選択された運搬担当者、未選択時は空文字を設定
                  onChange={handleSect1ChargeChange} // 運搬担当者が変更された時の処理
//                  onChange={handleChange} // 運搬担当者が変更された時の処理
                  style={{ fontSize: "18px", color: "#ff00ff" }} // 選択後の文字の大きさを設定

                  name='sect4TransportChargeId'

                >
                  {sectChargeList ? sectChargeList.map((charge) => (
                    <MenuItem key={charge.id} value={charge.chargeName}>{charge.id}:{charge.chargeCd}:{charge.chargeName}</MenuItem>
                  )) : <MenuItem value="">データがありません</MenuItem>}
                </Select>
              </Box>
            </Grid>
          </Grid>

            <Grid item xs>
              <Box bgcolor="primary.gray" color="primary.black" p={1}      justifyContent={"flex-start"} alignItems={"center"}>
              </Box>
            </Grid>
            <Grid item xs />


        {/* </Grid> */}

        </div>
        <div style={{ textAlign: 'right' }}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              onClick={(e) => {
                handleSubmit(e);
              }}
              style={{ marginRight: 10 }}
            >
              {buttonType1}
            </Button>
        </div>
      </>
    );
  }else{
    return (
      <>
        <Backdrop className={classes.backdrop} open={open} >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div style={{ textAlign: 'right', margin: '10px' }}>
          <Button variant='contained' onClick={e => {
            e.preventDefault();
            // history.push('/garbage_edi');
            props.handleClose();
          }}>    {/*  egs_m_reserve_sets  */}
            戻る
          </Button>
        </div>
        <div className={classes.container}>
            <Box sx={{ height: 150, width: '100%' }}>
              <DataGridPro
                sx={styles.grid}
                columns={cols}
                rows={newData}
                density="compact"
                showColumnRightBorder // 列ヘッダセルの右側に線を引く
                showCellRightBorder   // セルの右側に線を引く
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              />
            </Box>
          </div>
          <SpaceRow height={10} />
        <div className={classes.container} ref={pdfRef}>
        <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  運搬完了日
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.destination5AtBk}　⇒　修正後：</Typography>
                <TextField id='destination5At' variant="outlined" type='date' name='destination5At' margin="dense" value={value.destination5At} onChange={(e) => handleChange(e)} />
              </Box>
            </Grid>
        </Grid>

        <Grid
            container
            spacing={0}
            className={classes.row}
          >
          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  ゴミタグ番号
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.garbageTag} </Typography>
              </Box>
            </Grid>

          </Grid>
          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  マニフェスト番号
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.manifestNo} </Typography>
              </Box>
            </Grid>

          </Grid>

            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  排出日
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.exhaustDate} </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  排出事業者・担当者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}      justifyContent={"flex-start"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.exhaustName} </Typography>
                　
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.exhaustChargeName} </Typography>
              </Box>
            </Grid>
            <Grid item xs />
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  廃棄物の数量
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}> {value.amount1} </Typography>
                  　
                  <Typography variant="h7" style={{ fontWeight: 700 }}> {value.unitName} </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  荷姿の数量（整数）
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}> {value.packingAmount1} </Typography>
                  　
                  <Typography variant="h7" style={{ fontWeight: 700 }}> {value.packingName} </Typography>
                </Box>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={3}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  収集運搬事業者・運搬担当者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.sect5TransportName}　　　{value.sect5TransportChargeNameBk}　⇒　修正後：</Typography>
                <Select
                  value={selectedCharge || ''} // 選択された運搬担当者、未選択時は空文字を設定
                  onChange={handleSect1ChargeChange} // 運搬担当者が変更された時の処理
//                  onChange={handleChange} // 運搬担当者が変更された時の処理
                  style={{ fontSize: "18px", color: "#ff00ff" }} // 選択後の文字の大きさを設定

                  name='sect5TransportChargeId'

                >
                  {sectChargeList ? sectChargeList.map((charge) => (
                    <MenuItem key={charge.id} value={charge.chargeName}>{charge.id}:{charge.chargeCd}:{charge.chargeName}</MenuItem>
                  )) : <MenuItem value="">データがありません</MenuItem>}
                </Select>
              </Box>
            </Grid>
          </Grid>

            <Grid item xs>
              <Box bgcolor="primary.gray" color="primary.black" p={1}      justifyContent={"flex-start"} alignItems={"center"}>
              </Box>
            </Grid>
            <Grid item xs />


        {/* </Grid> */}

        </div>
        <div style={{ textAlign: 'right' }}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              onClick={(e) => {
                handleSubmit(e);
              }}
              style={{ marginRight: 10 }}
            >
              {buttonType1}
            </Button>
        </div>
      </>
    );
  }
};
const styles = {
  grid: {
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#c71585',
      color: '#fff',
    }
  }
 }
export default TransportDateForm;
