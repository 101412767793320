import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Draggable from "react-draggable";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { Backdrop, CircularProgress, makeStyles, Button } from '@material-ui/core';

import { updateEgsMEntry, getEgsMEntryDetail } from '../../lib/api/egs_m_entry';
import { createEgsMCompany } from '../../lib/api/egs_m_company';
import { createEgsMTrader } from '../../lib/api/egs_m_trader';
import { createEgsMCharge } from '../../lib/api/egs_m_charge';
import { createEgsMLink } from '../../lib/api/egs_m_link';
import { createUser } from '../../lib/api/user';
//import FormBody from './EgsMEntryForm';
import FormBody from './EgsMEntryNewForm';  // フォーム分割

import { getAddressZip } from '../../lib/api/address';

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function EgsMEntryNewEdit({ isOpen, doClose, dataList2, processId }) {

//  console.log("dataList2",processId,dataList2);

  const [dataListAddress,setDataListAddress] = useState([]);  {/* 郵便番号 */}

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState({
    companyCd: '',
    egsMCompanyId: 0,
    traderCd: '',
    companyKana: '',
    companyName: '',
    companyZip: '',
    companyPref: '',
    companyCity: '',
    companyTown: '',
    companyAddress: '',
    companyBuilding: '',
    companyPhone: '',
    companyFax: '',
    daihyo: '',
    daihyoKana1: '',
    daihyoKana2: '',
    daihyoName1: '',
    daihyoName2: '',
    traderKana: '',
    traderName: '',
    chargeKana1: '',
    chargeKana2: '',
    chargeName1: '',
    chargeName2: '',
    zip: '',
    pref: '',
    city: '',
    town: '',
    address: '',
    building: '',
    phone: '',
    fax: '',
    email1: '',
    memo: '',
    effectiveflg: '0',
    officeDivKana: '',
    officeDivName: '',
    officeChargeKana1: '',
    officeChargeKana2: '',
    officeChargeName1: '',
    officeChargeName2: '',
    email2: '',
    entryDate: '',
    businessType: '83',
    businessCategory: 'P',
    traderKbn: '1000000000',
    chargeDivision: '3',
    demandCd: '',
    demandId: 0,
    prefectureCd: '',
    groupId1: 0,
    groupId2: 0,
    jwnetNo: '',
    jwnetEdipass: '',
    rawpasswd: '',
    exhaustPasswd: '',
    companyKind: '99',
    companyKbn: '1',
    henkouDate: '',
    egsMTraderId: 0,
    henkouKbn: '1',
    email3: '',
  });

  useEffect(() => {
    handleGetData(processId,dataList2);
  }, [processId]);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();
  const history = useHistory();

  const handleGetData = async (processId, dataList2) => {
    try {
      setLoading(true);
      const res = await getEgsMEntryDetail(processId);
      console.log(res.data);
      console.log('dataList2',dataList2);
      setValue({
        companyCd: res.data.companyCd,
        egsMCompanyId: res.data.egsMCompanyId,
        traderCd: res.data.traderCd,
        companyKana: res.data.companyKana,
        companyName: res.data.companyName,
        companyZip: res.data.companyZip,
        companyPref: res.data.companyPref,
        companyCity: res.data.companyCity,
        companyTown: res.data.companyTown,
        companyAddress: res.data.companyAddress,
        companyBuilding: res.data.companyBuilding,
        companyPhone: res.data.companyPhone,
        companyFax: res.data.companyFax,
        daihyo: res.data.daihyo,
        daihyoKana1: res.data.daihyoKana1,
        daihyoKana2: res.data.daihyoKana2,
        daihyoName1: res.data.daihyoName1,
        daihyoName2: res.data.daihyoName2,
        traderKana: res.data.traderKana,
        traderName: res.data.traderName,
        chargeKana1: res.data.chargeKana1,
        chargeKana2: res.data.chargeKana2,
        chargeName1: res.data.chargeName1,
        chargeName2: res.data.chargeName2,
        zip: res.data.zip,
        pref: res.data.pref,
        city: res.data.city,
        town: res.data.town,
        address: res.data.address,
        building: res.data.building,
        phone: res.data.phone,
        fax: res.data.fax,
        email1: res.data.email1,
        memo: res.data.memo,
        effectiveflg: res.data.effectiveflg,
        officeDivKana: res.data.officeDivKana,
        officeDivName: res.data.officeDivName,
        officeChargeKana1: res.data.officeChargeKana1,
        officeChargeKana2: res.data.officeChargeKana2,
        officeChargeName1: res.data.officeChargeName1,
        officeChargeName2: res.data.officeChargeName2,
        email2: res.data.email2,
        entryDate: res.data.entryDate,
        businessType: res.data.businessType,
        businessCategory: res.data.businessCategory,
        traderKbn: res.data.traderKbn,
        chargeDivision: res.data.chargeDivision,
        demandCd: res.data.demandCd,
        demandId: res.data.demandId,
        prefectureCd: res.data.prefectureCd,
        groupId1: res.data.groupId1,
        groupId2: res.data.groupId2,
        jwnetNo: res.data.jwnetNo,
        jwnetEdipass: res.data.jwnetEdipass,
        rawpasswd: res.data.rawpasswd,
        exhaustPasswd: res.data.exhaustPasswd,
        companyKind: res.data.companyKind,
        companyKbn: res.data.companyKbn,
        henkouDate: res.data.henkouDate,
        egsMTraderId: res.data.egsMTraderId,
        henkouKbn: res.data.henkouKbn,
        email3: res.data.email3,
 });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const zipChange = async (e) => {

    if (e.target.value.length > 7){
      alert('郵便番号は数値7桁で入力してください')
    }else{
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });

    if (e.target.value != null && e.target.value.length == 7) {
        try {
          
        const dataListAddress = await getAddressZip(e.target.value);
        setDataListAddress(dataListAddress.data);

        value.prefectureCd = dataListAddress.data[0].prefectureCd

//        value.companyZipAddress = dataListAddress.data[0].address1
//        value.companyZipAddress2 = dataListAddress.data[0].address2
//        value.companyZipAddress3 = dataListAddress.data[0].address3

        value.pref = dataListAddress.data[0].address1
        value.city = dataListAddress.data[0].address2
        value.town = dataListAddress.data[0].address3

        setValue({
          ...value,
          [e.target.name]: e.target.value,
        });
    
        } catch (e) {
        console.log(e);
      }

    }
  }
  };

  const companyZipChange = async (e) => {

    if (e.target.value.length > 7){
      alert('郵便番号は数値7桁で入力してください')
    }else{
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });

    if (e.target.value != null && e.target.value.length == 7) {
        try {
          
        const dataListAddress = await getAddressZip(e.target.value);
        setDataListAddress(dataListAddress.data);

        value.prefectureCd = dataListAddress.data[0].prefectureCd

//        value.companyZipAddress = dataListAddress.data[0].address1
//        value.companyZipAddress2 = dataListAddress.data[0].address2
//        value.companyZipAddress3 = dataListAddress.data[0].address3

        value.companyPref = dataListAddress.data[0].address1
        value.companyCity = dataListAddress.data[0].address2
        value.companyTown = dataListAddress.data[0].address3

        setValue({
          ...value,
          [e.target.name]: e.target.value,
        });
    
        } catch (e) {
        console.log(e);
      }
    }
    }

  };

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
//  console.log('setvalue', value);

  const handleCancel = () => {
    doClose();
  };


  const handleSubmit = async (e) => {
  if (dataList2.effectiveflg == '0'){
    if (window.confirm(' 加入者「' + value.traderCd + ':' + value.traderName + '」を更新します。よろしいですか？')) {
      try {
        e.preventDefault();
        // 追加
        const params = generateParams();
        try {
          const res = await updateEgsMEntry(processId, params);
          handleCancel();
  //        history.push('/permit');  // egs_m_permits
          window.location.reload();
        } catch (e) {
          console.log(e);
          console.log(e.response);
        }
      } catch (e) {
      console.log(e.response);
      }
    }
  }else{
    if (window.confirm(' 照会のみ可能です')) {
    };
  };
}  

  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
      companyCd: value.companyCd,
      egsMCompanyId: value.egsMCompanyId,
      traderCd: value.traderCd,
      companyKana: value.companyKana,
      companyName: value.companyName,
      companyZip: value.companyZip,
      companyPref: value.companyPref,
      companyCity: value.companyCity,
      companyTown: value.companyTown,
      companyAddress: value.companyAddress,
      companyBuilding: value.companyBuilding,
      companyPhone: value.companyPhone,
      companyFax: value.companyFax,
      daihyo: value.daihyo,
      daihyoKana1: value.daihyoKana1,
      daihyoKana2: value.daihyoKana2,
      daihyoName1: value.daihyoName1,
      daihyoName2: value.daihyoName2,
      traderKana: value.traderKana,
      traderName: value.traderName,
      chargeKana1: value.chargeKana1,
      chargeKana2: value.chargeKana2,
      chargeName1: value.chargeName1,
      chargeName2: value.chargeName2,
      zip: value.zip,
      pref: value.pref,
      city: value.city,
      town: value.town,
      address: value.address,
      building: value.building,
      phone: value.phone,
      fax: value.fax,
      email1: value.email1,
      memo: value.memo,
      effectiveflg: value.effectiveflg,
      officeDivKana: value.officeDivKana,
      officeDivName: value.officeDivName,
      officeChargeKana1: value.officeChargeKana1,
      officeChargeKana2: value.officeChargeKana2,
      officeChargeName1: value.officeChargeName1,
      officeChargeName2: value.officeChargeName2,
      email2: value.email2,
      entryDate: value.entryDate,
      businessType: value.businessType,
      businessCategory: value.businessCategory,
      traderKbn: value.traderKbn,
      chargeDivision: value.chargeDivision,
      demandCd: value.demandCd,
      demandId: value.demandId,
      prefectureCd: value.prefectureCd,
      groupId1: value.groupId1,
      groupId2: value.groupId2,
      jwnetNo: value.jwnetNo,
      jwnetEdipass: value.jwnetEdipass,
      rawpasswd: value.rawpasswd,
      exhaustPasswd: value.exhaustPasswd,
      companyKind: value.companyKind,
      companyKbn: value.companyKbn,
      henkouDate: value.henkouDate,
      egsMTraderId: value.egsMTraderId,
      henkouKbn: value.henkouKbn,
      email3: value.email3,
    };
    return params;
  };

  if (dataList2.effectiveflg == '0') {

    return (
      <Dialog
        open={isOpen}
  //      onClose={doClose}
        onClose={() => {}}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title"
        maxWidth={"xl"}
        PaperComponent={PaperComponent}
      >
        <DialogTitle id="form-dialog-title">
          <h2>加入者　新規登録　編集</h2>
          <div style={{ textAlign: 'right' }}>
            <Button variant='contained' 
  //           onClick={() => history.push('/permit')}>
              onClick={e => {
                e.preventDefault();
  //              handleBack();
                handleCancel();
              }}>
              戻る
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
  
          <FormBody
            handleBack={handleCancel}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            zipChange={zipChange}
            companyZipChange={companyZipChange}
            value={value}
            dataList2={dataList2}
            buttonType='更新'
          />
  
          <Backdrop className={classes.backdrop} open={loading} >
            <CircularProgress color="inherit" />
          </Backdrop>
        </DialogContent>
      </Dialog>
    );
  
  }else{

    return (
      <Dialog
        open={isOpen}
  //      onClose={doClose}
        onClose={() => {}}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title"
        maxWidth={"xl"}
        PaperComponent={PaperComponent}
      >
        <DialogTitle id="form-dialog-title">
          <h2>加入者　新規登録　照会</h2>
          <div style={{ textAlign: 'right' }}>
            <Button variant='contained' 
  //           onClick={() => history.push('/permit')}>
              onClick={e => {
                e.preventDefault();
  //              handleBack();
                handleCancel();
              }}>
              戻る
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
  
          <FormBody
            handleBack={handleCancel}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            zipChange={zipChange}
            companyZipChange={companyZipChange}
            value={value}
            dataList2={dataList2}
            buttonType='照会'
          />
  
          <Backdrop className={classes.backdrop} open={loading} >
            <CircularProgress color="inherit" />
          </Backdrop>
        </DialogContent>
      </Dialog>
    );
  
  }  
}
export default EgsMEntryNewEdit;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
