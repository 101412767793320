import React, { useEffect, useState } from 'react';

import EgsMTraderPass from "../egs_m_traders/EgsMTraderPass";
import { makeStyles } from '@material-ui/core/styles';
// style
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import './app.css';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "85vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#EDF",
    borderWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

function EgsMTraderListTableForm(props) {
  const classes = useStyles();
  const { dataList2 } = props;
  const [passOpen, setPassOpen] = useState(false);

  const handlePassOpen = () => {
    setPassOpen(true);
  };

  return (
    <>
      <Box component='div' sx={{ p: 2, textAlign: 'right' }}></Box>
      <Typography variant="h4" style={{ fontWeight: 700 }}>
        事業場情報
        </Typography>
      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
          style={{
            borderTopWidth: 2,
            borderTopColor: "black"
          }}
        >
        </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  JWNET加入者番号・事業場番号
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.jwnetNo}</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.jwnetSubno}</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  JWNET加入者 EDI利用確認キー
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.jwnetEdipass}</Typography>
              </Box>
            </Grid>
            <Grid item xs />
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  事業場CD
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.traderCd}</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  事業者区分
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.traderKbnName}</Typography>
              </Box>
            </Grid>
           <Grid item xs />
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  事業場名称（かな）
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.traderName}</Typography>
                <Typography variant="h7" style={{ fontWeight: 700 }}>（{dataList2.traderKana}）</Typography>
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  事業場表示名・代表者
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.traderDispName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>【{dataList2.daihyo}　{dataList2.daihyoName1}　{dataList2.daihyoName2}】</Typography>
              </Box>
            </Grid>
           <Grid item xs />
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  郵便番号・都道府県
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.zip}</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.prefectureName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  住所（市区町村）
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.address}</Typography>
              </Box>
            </Grid>
            <Grid item xs />
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  住所（町域）
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.address2}</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  住所（丁目・番地以降）
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.address3} {dataList2.address4}</Typography>
              </Box>
            </Grid>
            <Grid item xs />
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                連絡先電話番号
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.phone}</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  ＦＡＸ番号
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.fax}</Typography>
              </Box>
            </Grid>
            <Grid item xs />
          </Grid>


          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  メールアドレス
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={10}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                １．
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.email1}</Typography>
                　 
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.email1FlgName}</Typography>
                　　２．
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.email2}</Typography>
                　
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.email2FlgName}</Typography>
                　　３．
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.email3}</Typography>
                　
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.email3FlgName}</Typography>
              </Box>
            </Grid>
            <Grid item xs />
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                メモ
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.memo}</Typography>
              </Box>
            </Grid>
            <Grid item xs />
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  ログインパスワード
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                {/* <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.rawpasswd}</Typography> */}
              </Box>
            </Grid>
            <Grid item xs={2}>
              <EgsMTraderPass
                isOpen={passOpen}
                doClose={() => setPassOpen(false)}
                processId={dataList2.id}
              />
              <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handlePassOpen} disabled={!dataList2.id}>
                ログインパスワード変更
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  承認コード
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.exhaustPasswd}</Typography>
              </Box>
            </Grid>
            <Grid item xs />
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  所属団体
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.groupCd1}　{dataList2.groupName1}</Typography>
                　　
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.groupCd2}　{dataList2.groupName2}</Typography>
                　　
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.groupCd3}　{dataList2.groupName3}</Typography>
                　　
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.groupCd4}　{dataList2.groupName4}</Typography>
                　　
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.groupCd4}　{dataList2.groupName5}</Typography>
              </Box>
            </Grid>
            <Grid item xs />
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  料金区分
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.chargeDivisionName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  有効／無効
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.effectiveflgName}</Typography>
              </Box>
            </Grid>
            <Grid item xs />
          </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
          style={{
            borderTopWidth: 2,
            borderTopColor: "black"
          }}
        >
        </Grid>
        </div>

    </>
  )
}
const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
}
export default EgsMTraderListTableForm;
