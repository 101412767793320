// Form.jsx
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SpaceRow from '../commons/SpaceRow';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, MenuItem, Select, Checkbox, Typography ,Box, Grid} from '@material-ui/core';
import './app.css';

//import { getEgsMTraderList } from '../../lib/api/egs_m_trader';
import { getTraderList, getTraderUser } from '../../lib/api/trader';
import { getBusinessTypeList } from '../../lib/api/business_type';
import { getEgsMBusinessTypeList } from '../../lib/api/egs_m_business_type';
import { getEgsMBusinessCategoryList } from '../../lib/api/egs_m_business_category';
import { getEgsMGroupList } from '../../lib/api/egs_m_group';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '200 ch',    // 100ch
    },
  },
}));

const EgsMEntryAdminForm = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { handleBack, handleChange, handleSubmit, handleCreate1, handleCreate2 ,handleUpdate1, handleUpdate2, handleDelete1, handleDelete2,
//    handleDelete, 
       value, dataList2, companyZipChange } = props;

  const [open, setOpen] = React.useState(false);
//  const [traderList, setTraderList] = useState([]);
//  const [t02List, setT02List] = useState([]);

  const [dataListBusinessCategory,setDataListBusinessCategory] = useState([]);  {/* 職種カテゴリ */}
  const [dataListBusinessType,setDataListBusinessType] = useState([]);  {/* 職種 */}
  const [dataListGroup,setDataListGroup] = useState([]);  {/* グループ */}

//  const [errorTraderCd, setErrorTraderCd] = useState(false);  // 事業場CD
//  const [errorChargeCd, setErrorChargeCd] = useState(false);  // 加入者CD
  const [errorchargeName1, setErrorchargeName1] = useState(false);  // 加入者名
  const [errorchargeName2, setErrorchargeName2] = useState(false);  // 加入者名
  const [errorChargeKana1, setErrorChargeKana1] = useState(false);  // ふりがな
  const [errorChargeKana2, setErrorChargeKana2] = useState(false);  // ふりがな
  const [errorEmail1, setErrorEmail1] = useState(false);  // メールアドレス
  const [errorEmail1Flg, setErrorEmail1Flg] = useState(false);  // メールアドレス有効フラグ
  const [errorEffectiveflg, setErrorEffectiveflg] = useState(false);  // 有効フラグ
  const [errorEdiKbn, setErrorEdiKbn] = useState(false);  // EDI加入者区分

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
     setOpen(!open);
//     if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
//      const res = await getEgsMTraderList();
//      const res = await getTraderList();
      // console.log(res.data);
//      setTraderList(res.data);
//    }

      const dataListBusinessType = await getEgsMBusinessTypeList();
      setDataListBusinessType(dataListBusinessType.data);

      const dataListBusinessCategory = await getEgsMBusinessCategoryList();
      setDataListBusinessCategory(dataListBusinessCategory.data);

      const dataListGroup = await getEgsMGroupList();
      setDataListGroup(dataListGroup.data);

     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const businessCategorydataList = dataListBusinessCategory.filter(dataListBusinessCategory => { return dataListBusinessCategory.categoryCd != '' });
  const businessTypedataList = dataListBusinessType.filter(dataListBusinessType => { return dataListBusinessType.categoryCd == value.businessCategory });
  const groupdataList = dataListGroup.filter(dataListGroup => { return dataListGroup.groupCd != '' });


{/*}
  const traderdataList = traderList.filter(traderList => { return traderList.traderCd == value.traderCd });
  // console.log('traderdataList',traderdataList)


  const traderGetList = async () => {
    try {
//     setOpen(!open);
      const resT02 = await getTraderUser(value.traderCd);
      // console.log(resT02.data);
      setT02List(resT02.data);
//     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const t02dataList = t02List.filter(t02List => { return t02List.traderCd == value.traderCd });
  // console.log('t02dataList',t02dataList)
*/}

//  const err_msg = ''
  const checkValidation = () => {

//    useEffect(() => {
//      traderGetList();
//    }, []);

//  alert('emailFlg <' + value.emailFlg + '> effectiveflg <' + value.effectiveflg + '>')

    let available = true;
/*
    if (!value.traderCd) {  // || !value.traderName) {    //  || !traderdataList.traderName) {
      // console.log('traderCd',value.traderCd,value.traderName,traderdataList.traderName);
//      err_msg = err_msg + '事業場CD ';
//      alert('事業場CDに誤りがあります')
      available = false;
      setErrorTraderCd(true);
    }else{
      setErrorTraderCd(false);
    }

//                                     符号あり  /^-?[0-9]+$/
    if (!value.chargeCd || value.chargeCd < 0 || !value.chargeCd.match(/^[0-9]+$/) || value.chargeCd.match(/^[0-9]+\.[0-9]+$/)) {
      // console.log('chargeCd',value.chargeCd);
//      err_msg = err_msg + '加入者CD ';
//      alert('加入者CDに誤りがあります')
      available = false;
      setErrorChargeCd(true);
    }else{
      setErrorChargeCd(false);
    }
*/
    if (!value.chargeName1 ) {
//      err_msg = err_msg + '加入者名 ';
//      alert('加入者名に誤りがあります')
      available = false;
      setErrorchargeName1(true);
    }else{
      setErrorchargeName1(false);
    }

    if (!value.chargeKana ) {
//      err_msg = err_msg + 'ふりがな ';
//      alert('ふりがなに誤りがあります')
      available = false;
      setErrorChargeKana1(true);
    }else{
      setErrorChargeKana1(false);
    }

    if (!value.email.match("^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$") ) {
//      alert('メールアドレスに誤りがあります')
      available = false;
      setErrorEmail1(true);
    }else{
      setErrorEmail1(false);
    }

//    if (!value.emailFlg.match("01") ) {
    if (value.emailFlg !== '0' && value.emailFlg !== '1') {
//      alert('メールアドレス有効フラグに誤りがあります')
      available = false;
      setErrorEmail1Flg(true);
    }else{
      setErrorEmail1Flg(false);
    }

//    if (!value.ediKbn.match("01") ) {
    if (value.ediKbn < 0 || value.ediKbn > 1) {
//      alert('EDI区分誤りがあります')
      available = false;
      setErrorEdiKbn(true);
    }else{
      setErrorEdiKbn(false);
    }

//    if (!value.emailFlg.match("01") ) {
    if (value.effectiveflg !== '1' && value.effectiveflg !== '9') {
//      alert('有効フラグに誤りがあります')
      available = false;
      setErrorEffectiveflg(true);
    }else{
      setErrorEffectiveflg(false);
    }

/*
    if (!err_msg) {
    }else{
     alert(err_msg + 'に誤りがあります');
    }
*/
  return available;
  }

  if (dataList2.henkouKbn == '1'){
    return (
      <>
  {/*}      <SpaceRow height={20} />  */}
{/*}        <Typography variant="h5">加入申込</Typography> */}
        <form className={classes.root} noValidate autoComplete='off'>
         <table>

         <tr><th>お申込日、業者・料金区分</th><td>
           <Box> 
            <TextField id='entryDate' label='お申込日' variant="outlined" type='date' name='entryDate' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.entryDate}/>
            <TextField id='henkouDate' label='変更予定日' variant="outlined" type='date' name='henkouDate' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.henkouDate}/>
            <Select
                  id='traderKbn'
                  label='業者区分'
                  variant="outlined"
                  type='text'
                  name='traderKbn'
                  margin="dense"
  
                  style={{ marginTop: 10 ,marginBottom: 10 }}
  
                  onChange={(e) => handleChange(e)}
                  value={value.traderKbn}
                >
                  <MenuItem value={value.traderKbn}>
                    <em>業者区分</em>
                  </MenuItem>
                  <MenuItem value={' '}> :指定しない</MenuItem>
                  <MenuItem value={'1000000000'}>1:排出業者</MenuItem>
                  <MenuItem value={'0001000000'}>2:中間処理</MenuItem>
                  <MenuItem value={'0000100000'}>3:最終処分</MenuItem>
                </Select>
                <Select
                  id='chargeDivision'
                  label='料金区分'
                  variant="outlined"
                  type='text'
                  name='chargeDivision'
                  margin="dense"
  
                  style={{ marginTop: 10 ,marginBottom: 10 }}
  
                  onChange={(e) => handleChange(e)}
                  value={value.chargeDivision}
                >
                  <MenuItem value={value.chargeDivision}>
                    <em>料金区分</em>
                  </MenuItem>
                  <MenuItem value={' '}> :指定しない</MenuItem>
                  <MenuItem value={'1'}>1:料金区分A</MenuItem>
                  <MenuItem value={'2'}>2:料金区分B</MenuItem>
                  <MenuItem value={'3'}>3:料金区分C</MenuItem>
                </Select>
                <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.entryDate}　　{dataList2.henkouDate}　　{dataList2.traderKbn}　{dataList2.chargeDivision}</Typography>
              </Box>
         </td></tr>     

         <tr><th>請求先、企業、事業場</th><td>
          <TextField id='demandId' label='請求先ID' variant="outlined" type='text' name='demandId' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.demandId}/>
          <TextField id='demandCd' label='請求先CD' variant="outlined" type='text' name='demandCd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.demandCd}/>
          <Select
            id='effectiveflg'
            label='申請状況'
            variant="outlined"
            type='text'
            name='effectiveflg'
            margin="dense"
  
            style={{ marginTop: 10 ,marginBottom: 10 }}
  
            onChange={(e) => handleChange(e)}
            value={value.effectiveflg}
          >
            <MenuItem value={value.effectiveflg}>
              <em>申請状況</em>
            </MenuItem>
            <MenuItem value={'0'}>0:入力中</MenuItem>
            <MenuItem value={'1'}>1:申請済</MenuItem>
            <MenuItem value={'2'}>2:登録完了</MenuItem>
            <MenuItem value={'9'}>9:対応完了</MenuItem>
          </Select>
          <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.demandId}　　{dataList2.demandCd}：{dataList2.effectiveflg}</Typography>
         </td></tr>
  
  </table><table>
  
         <tr><th>企業、事業場</th><td>
           <TextField id='egsMCompanyId' label='事業場ID' variant="outlined" type='text' name='egsMCompanyId' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.egsMCompanyId}/>
           <TextField id='companyCd' label='企業CD' variant="outlined" type='text' name='companyCd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.companyCd}/>
           <TextField id='egsMTraderId' label='事業場ID' variant="outlined" type='text' name='egsMTraderId' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.egsMTraderId}/>
           <TextField id='traderCd' label='事業場CD' variant="outlined" type='text' name='traderCd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.traderCd}/>
           <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.egsMCompanyId}　　{dataList2.companyCd}：{dataList2.egsMCompanyId}　　{dataList2.egsMTraderId}：{dataList2.traderCd}</Typography>
         </td></tr>
  
         <tr><th>加入者情報</th><td>
           <TextField id='jwnetNo' label='加入者番号' variant="outlined" type='text' name='jwnetNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.jwnetNo}/>
           <TextField id='jwnetEdipass' label='EDI利用確認キー' variant="outlined" type='text' name='jwnetEdipass' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.jwnetEdipass}/>
           <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.jwnetNo}　　{dataList2.jwnetEdipass}　　{dataList2.traderKbn}　{dataList2.chargeDivision}</Typography>
         </td></tr>
  
         <tr><th>SDGs推進システム</th><td>
           <TextField id='rawpasswd' label='パスワード' variant="outlined" type='text' name='rawpasswd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.rawpasswd}/>
           <TextField id='exhaustPasswd' label='承認コード' variant="outlined" type='text' name='exhaustPasswd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.exhaustPasswd}/>
           <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.rawpasswd}　　{dataList2.exhaustPasswd}　　{dataList2.traderKbn}　{dataList2.chargeDivision}</Typography>
         </td></tr>
  
  </table><table>
  
         <tr><th>業種</th><td>
              <Box> {/*</Box><Box bgcolor="primary.gray" color="primary.black" p={1}> */}
                <Select id='businessCategory' label='業種' variant="outlined" type='text' name='businessCategory' margin="dense"
  //              style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10 }}
                  style={{ marginTop: 2 ,marginBottom: 2 }} 
                  halfWidth 
                  onChange={(e) => handleChange(e)} value={value.businessCategory}
                 >
                  {businessCategorydataList.map((businessCategorydataList) => (
                    <MenuItem key={businessCategorydataList.categoryCd} value={businessCategorydataList.categoryCd} > {businessCategorydataList.categoryCd}:{businessCategorydataList.categoryName} </MenuItem>
                  ))}
                </Select>
  {/*}            </Box>
              <Box> {/*</Box><Box bgcolor="primary.gray" color="primary.black" p={1}> */}
                <Select id='businessType' label='業種' variant="outlined" type='text' name='businessType' margin="dense"
  //              style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10 }}
                  style={{ marginTop: 2 ,marginBottom: 2 }} 
                  halfWidth 
                  onChange={(e) => handleChange(e)} value={value.businessType}
                 >
                  {businessTypedataList.map((businessTypedataList) => (
                    <MenuItem key={businessTypedataList.businessTypeCd} value={businessTypedataList.businessTypeCd} > {businessTypedataList.businessTypeCd}:{businessTypedataList.businessTypeName} </MenuItem>
                  ))}
                </Select>
                <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.businessCategory}　{dataList2.businessType}</Typography>
              </Box>
         </td></tr>    
  
         <tr><th>法人区分</th><td>
            <Select
                  id='companyKind'
                  label='法人区分'
                  variant="outlined"
                  type='text'
                  name='companyKind'
                  margin="dense"
  
                  style={{ marginTop: 10 ,marginBottom: 10 }}
  
                  onChange={(e) => handleChange(e)}
                  value={value.companyKind}
                >
                  <MenuItem value={value.companyKind}>
                    <em>法人区分</em>
                  </MenuItem>
                  <MenuItem value={'00'}>00:個人事業</MenuItem>
                  <MenuItem value={'01'}>01:株式会社</MenuItem>
                  <MenuItem value={'02'}>02:有限会社</MenuItem>
                  <MenuItem value={'03'}>03:合名会社</MenuItem>
                  <MenuItem value={'04'}>04:合資会社</MenuItem>
                  <MenuItem value={'05'}>05:医療法人</MenuItem>
                  <MenuItem value={'06'}>06:財団法人</MenuItem>
                  <MenuItem value={'07'}>07:社団法人</MenuItem>
                  <MenuItem value={'08'}>08:宗教法人</MenuItem>
                  <MenuItem value={'09'}>09:学校法人</MenuItem>
                  <MenuItem value={'10'}>10:社会福祉法人</MenuItem>
                  <MenuItem value={'11'}>11:相互会社</MenuItem>
                  <MenuItem value={'12'}>12:行政書士法人</MenuItem>
                  <MenuItem value={'13'}>13:司法書士法人</MenuItem>
                  <MenuItem value={'14'}>14:税理士法人</MenuItem>
                  <MenuItem value={'15'}>15:国立大学法人</MenuItem>
                  <MenuItem value={'16'}>16:特定非営利活動法人</MenuItem>
                  <MenuItem value={'17'}>17:更生保護法人</MenuItem>
                  <MenuItem value={'18'}>18:独立行政法人</MenuItem>
                  <MenuItem value={'19'}>19:弁護士法人</MenuItem>
                  <MenuItem value={'20'}>20:有限責任中間法人</MenuItem>
                  <MenuItem value={'21'}>21:無限責任中間法人</MenuItem>
                  <MenuItem value={'22'}>22:医療法人社団</MenuItem>
                  <MenuItem value={'23'}>23:公共機関</MenuItem>
                  <MenuItem value={'24'}>24:公益社団法人</MenuItem>
                  <MenuItem value={'25'}>25:公益財団法人</MenuItem>
                  <MenuItem value={'26'}>26:一般社団法人</MenuItem>
                  <MenuItem value={'27'}>27:一般財団法人</MenuItem>
                  <MenuItem value={'28'}>28:医療法人財団</MenuItem>
                  <MenuItem value={'29'}>29:協同組合</MenuItem>
                  <MenuItem value={'99'}>99:その他の法人</MenuItem>
                </Select>
                <Select
                  id='companyKbn'
                  label='前後'
                  variant="outlined"
                  type='text'
                  name='companyKbn'
                  margin="dense"
  
                  style={{ marginTop: 10 ,marginBottom: 10 }}
  
                  onChange={(e) => handleChange(e)}
                  value={value.companyKbn}
                >
                  <MenuItem value={value.companyKbn}>
                    <em>前後</em>
                  </MenuItem>
                  <MenuItem value={' '}> :指定しない</MenuItem>
                  <MenuItem value={'1'}>1:前</MenuItem>
                  <MenuItem value={'2'}>2:後</MenuItem>
                </Select>
                <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.companyKind}　{dataList2.companyKbn}</Typography>
            </td></tr>
  
            <tr><th>グループ</th><td>
              <Box> {/*</Box><Box bgcolor="primary.gray" color="primary.black" p={1}> */}
              <Select id='groupId1' label='グループ１' variant="outlined" type='text' name='groupId1' margin="dense"
                  style={{ marginTop: 2 ,marginBottom: 2 }} halfWidth onChange={(e) => handleChange(e)} value={value.groupId1}
                 >
                  {groupdataList.map((groupdataList) => (
                    <MenuItem key={groupdataList.id} value={groupdataList.id} > {groupdataList.groupCd}:{groupdataList.groupName} </MenuItem>
                  ))}
                </Select>
                <Select id='groupId2' label='グループ２' variant="outlined" type='text' name='groupId2' margin="dense"
                  style={{ marginTop: 2 ,marginBottom: 2 }} halfWidth onChange={(e) => handleChange(e)} value={value.groupId2}
                 >
                  {groupdataList.map((groupdataList) => (
                    <MenuItem key={groupdataList.id} value={groupdataList.id} > {groupdataList.groupCd}:{groupdataList.groupName} </MenuItem>
                  ))}
                </Select>
                <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.groupId1}（{dataList2.groupCd1}）：{dataList2.groupId2}（{dataList2.groupCd2}）</Typography>
              </Box>
         </td></tr>    
  
  </table><table>
  
         <tr><th>企業名</th><td>
            <TextField id='companyKana' label='企業かな名' variant="outlined" type='text' name='companyKana' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.companyKana}/>
            <TextField id='companyName' label='企業名' variant="outlined" type='text' name='companyName' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.companyName}/>
            <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.companyName}　（{dataList2.companyKana}）</Typography>
            </td></tr>
          <tr><th>住所</th><td>
            <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.companyZip}　{dataList2.companyPref}　{dataList2.companyCity}　{dataList2.companyTown}　{dataList2.companyAddress}　{dataList2.companyBuilding}</Typography>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <TextField id='companyZip' label='郵便番号' variant="outlined" type='text' name='companyZip' halfWidth margin="dense" 
  //          onChange={(e) => handleChange(e)}
            onChange={(e) => companyZipChange(e)} value={value.companyZip}
            />
            <TextField id='companyAddress' label='住所' variant="outlined" type='text' name='companyAddress' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.companyAddress}/>
            <TextField id='companyBuilding' label='ビル名' variant="outlined" type='text' name='companyBuilding' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.companyBuilding}/>
              </Box>
            <Typography variant="h7" style={{ marginTop: 10 }}>変更後　{value.companyZip}　{value.companyPref}　{value.companyCity}　{value.companyTown}</Typography>
            </td></tr>
          <tr><th>電話番号</th><td>
            <TextField id='companyPhone' label='電話番号' variant="outlined" type='text' name='companyPhone' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.companyPhone}/>
            <TextField id='companyFax' label='FAX番号' variant="outlined" type='text' name='companyFax' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.companyFax}/>
            <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.companyPhone}　　FAX番号{dataList2.companyFax}</Typography>
            </td></tr>
          <tr><th>代表者かな名</th><td>
            <TextField id='daihyo' label='代表者役職' variant="outlined" type='text' name='daihyo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.daihyo}/>
            <TextField id='daihyoKana1' label='代表者かな姓' variant="outlined" type='text' name='daihyoKana1' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.daihyoKana1}/>
            <TextField id='daihyoKana2' label='代表者かな名' variant="outlined" type='text' name='daihyoKana2' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.daihyoKana2}/>
            <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.daihyoKana1}　{dataList2.daihyoKana2}</Typography>
            </td></tr>
          <tr><th>代表者名</th><td>
            <TextField id='daihyo' label='' variant="outlined" type='text' name='' halfWidth margin="dense" />
            <TextField id='daihyoName1' label='代表者姓' variant="outlined" type='text' name='daihyoName1' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.daihyoName1}/>
            <TextField id='daihyoName2' label='代表者名' variant="outlined" type='text' name='daihyoName2' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.daihyoName2}/>
            <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.daihyo}　{dataList2.daihyoName1}　{dataList2.daihyoName2}</Typography>
            </td></tr>
  
  </table><table>
  
          <tr><th>事業場名</th><td>
            <TextField id='traderKana' label='事業場かな' variant="outlined" type='text' name='traderKana' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.traderKana}/>
            <TextField id='traderName' label='事業場名' variant="outlined" type='text' name='traderName' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.traderName}/>
            <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.traderName} （{dataList2.traderKana}）</Typography>
          </td></tr>
          <tr><th>引渡担当者 *</th><td>
            <TextField id='chargeName1' label='引渡担当者1' variant="outlined" type='text' name='chargeName1' halfWidth margin="dense"
            onChange={(e) => handleChange(e)}
            error={errorchargeName1}
            value={value.chargeName1}/>
            <TextField id='chargeName2' label='引渡担当者2' variant="outlined" type='text' name='chargeName2' halfWidth margin="dense"
            onChange={(e) => handleChange(e)}
            error={errorchargeName2}
            value={value.chargeName2}/>
            <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.chargeName1}　{dataList2.chargeName2}</Typography>
          </td></tr><tr><th>ふりがな *</th><td>
          <TextField id='chargeKana1' label='引渡担当者かな1' variant="outlined" type='text' name='chargeKana1' halfWidth margin="dense"
            onChange={(e) => handleChange(e)}
            error={errorChargeKana1}
            value={value.chargeKana1}/>
          <TextField id='chargeKana2' label='引渡担当者かな2' variant="outlined" type='text' name='chargeKana2' halfWidth margin="dense"
            onChange={(e) => handleChange(e)}
            error={errorChargeKana2}
            value={value.chargeKana2}/>
            <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.chargeKana1}　{dataList2.chargeKana2}</Typography>
  
  </td></tr>
  
          <tr><th>住所</th><td>
            <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.zip}　{dataList2.pref}　{dataList2.city}　{dataList2.town}　{dataList2.address}　{dataList2.building}</Typography>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <TextField id='zip' label='郵便番号' variant="outlined" type='text' name='zip' halfWidth margin="dense" 
  //          onChange={(e) => handleChange(e)}
            onChange={(e) => companyZipChange(e)} value={value.zip}
            />
            <TextField id='address' label='住所' variant="outlined" type='text' name='address' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.address}/>
            <TextField id='building' label='ビル名' variant="outlined" type='text' name='building' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.building}/>
              </Box>
            <Typography variant="h7" style={{ marginTop: 10 }}>変更後　{value.zip}　{value.pref}　{value.city}　{value.town}</Typography>
            </td></tr>
          <tr><th>電話番号</th><td>
            <TextField id='phone' label='電話番号' variant="outlined" type='text' name='phone' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.phone}/>
            <TextField id='fax' label='FAX番号' variant="outlined" type='text' name='fax' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.fax}/>
            <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.phone}　　FAX番号{dataList2.fax}</Typography>
            </td></tr>
  
  </table><table>
  
          <tr><th>事務担当部署</th><td>
            <TextField id='officeDivName' label='所属部署名' variant="outlined" type='text' name='officeDivName' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.officeDivName}/>
            <TextField id='officeDivKana' label='所属部署かな名' variant="outlined" type='text' name='officeDivKana' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.officeDivKana}/>
            <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.officeDivName}　（{dataList2.officeDivKana}）</Typography>
          </td></tr>
          <tr><th>事務担当者かな</th><td>
            <TextField id='officeChargeKana1' label='事務担当者かな姓' variant="outlined" type='text' name='officeChargeKana1' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.officeChargeKana1}/>
            <TextField id='officeChargeKana2' label='事務担当者かな名' variant="outlined" type='text' name='officeChargeKana2' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.officeChargeKana2}/>
            <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.officeChargeKana1}　{dataList2.officeChargeKana2}</Typography>
            </td></tr>
          <tr><th>事務担当者姓名</th><td>
            <TextField id='officeChargeName1' label='事務担当者姓' variant="outlined" type='text' name='officeChargeName1' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.officeChargeName1}/>
            <TextField id='officeChargeName2' label='事務担当者名' variant="outlined" type='text' name='officeChargeName2' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.officeChargeName2}/>
            <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.officeChargeName1}　{dataList2.officeChargeName2}</Typography>
          </td></tr>
          <tr><th>メールアドレス *</th><td>
            <TextField id='email1' label='メールアドレス' variant="outlined" type='text' name='email1' halfWidth margin="dense"
            onChange={(e) => handleChange(e)}
            error={errorEmail1}
            value={value.email1}
            inputProps={{ maxLength: 50, pattern: "^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$" }}  // maxLength: 3, pattern: "0-9"
          />
            <TextField id='email2' label='メールアドレス2' variant="outlined" type='text' name='email2' halfWidth margin="dense"
            onChange={(e) => handleChange(e)}
  //          error={errorEmail2}
            value={value.email2}
            inputProps={{ maxLength: 50, pattern: "^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$" }}  // maxLength: 3, pattern: "0-9"
          />
            <TextField id='email3' label='メールアドレス2' variant="outlined" type='text' name='email3' halfWidth margin="dense"
            onChange={(e) => handleChange(e)}
  //          error={errorEmail3}
            value={value.email3}
            inputProps={{ maxLength: 50, pattern: "^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$" }}  // maxLength: 3, pattern: "0-9"
          />
            <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.email1}　{dataList2.email2}　{dataList2.email3}</Typography>
          </td></tr>
         </table>
        </form>
        <div style={{ textAlign: 'right' }}>
          <Button
            type='submit'
            variant='contained'
            color='secondary'
  //        onClick={(e) => handleCreate2(e)}
            onClick={(e) => {
            handleCreate2(e);
            }}
            style={{ marginRight: 10 }}
          >
            ユーザマスタ登録
          </Button>
  {/*}     
          <Button
            type='submit'
            variant='contained'
            color='secondary'
  //        onClick={(e) => handleCreate3(e)}
            onClick={(e) => {
            handleDelete(e);
            }}
            style={{ marginRight: 10 }}
          >
            連携マスタ登録
          </Button>
  */}     
          <Button
            type='submit'
            variant='contained'
            color='secondary'
  //        onClick={(e) => handleCreate1(e)}
            onClick={(e) => {
            handleCreate1(e);
            }}
            style={{ marginRight: 100 }}
          >
            各マスタ登録
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
  //          onClick={(e) => handleSubmit(e)}
            onClick={(e) => {
            handleSubmit(e);
  //            if (checkValidation()) {
  //                handleSubmit(e);
  //               }
              }}
            style={{ marginRight: 10 }}
          >
            更新
          </Button>
  {/*}
          <Button variant='contained' onClick={e => {
            e.preventDefault();
            handleBack();
          }}>
            戻る
          </Button>
  */}
        </div>
      </>
    );
    }else if (dataList2.henkouKbn == '2' ){
      return (
        <>
    {/*}      <SpaceRow height={20} />  */}
{/*}        <Typography variant="h5">変更申込</Typography>  */}
          <form className={classes.root} noValidate autoComplete='off'>
           <table>
    
           <tr><th>お申込日、業者・料金区分</th><td>
             <Box> 
              <TextField id='entryDate' label='お申込日' variant="outlined" type='date' name='entryDate' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.entryDate}/>
              <TextField id='henkouDate' label='変更予定日' variant="outlined" type='date' name='henkouDate' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.henkouDate}/>
              <Select
                    id='traderKbn'
                    label='業者区分'
                    variant="outlined"
                    type='text'
                    name='traderKbn'
                    margin="dense"
    
                    style={{ marginTop: 10 ,marginBottom: 10 }}
    
                    onChange={(e) => handleChange(e)}
                    value={value.traderKbn}
                  >
                    <MenuItem value={value.traderKbn}>
                      <em>業者区分</em>
                    </MenuItem>
                    <MenuItem value={' '}> :指定しない</MenuItem>
                    <MenuItem value={'1000000000'}>1:排出業者</MenuItem>
                    <MenuItem value={'0001000000'}>2:中間処理</MenuItem>
                    <MenuItem value={'0000100000'}>3:最終処分</MenuItem>
                  </Select>
                  <Select
                    id='chargeDivision'
                    label='料金区分'
                    variant="outlined"
                    type='text'
                    name='chargeDivision'
                    margin="dense"
    
                    style={{ marginTop: 10 ,marginBottom: 10 }}
    
                    onChange={(e) => handleChange(e)}
                    value={value.chargeDivision}
                  >
                    <MenuItem value={value.chargeDivision}>
                      <em>料金区分</em>
                    </MenuItem>
                    <MenuItem value={' '}> :指定しない</MenuItem>
                    <MenuItem value={'1'}>1:料金区分A</MenuItem>
                    <MenuItem value={'2'}>2:料金区分B</MenuItem>
                    <MenuItem value={'3'}>3:料金区分C</MenuItem>
                  </Select>
                  <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.entryDate}　　{dataList2.henkouDate}　　{dataList2.traderKbn}　{dataList2.chargeDivision}</Typography>
                </Box>
           </td></tr>     

           <tr><th>請求先、企業、事業場</th><td>
            <TextField id='demandId' label='請求先ID' variant="outlined" type='text' name='demandId' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.demandId}/>
            <TextField id='demandCd' label='請求先CD' variant="outlined" type='text' name='demandCd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.demandCd}/>
            <Select
              id='effectiveflg'
              label='申請状況'
              variant="outlined"
              type='text'
              name='effectiveflg'
              margin="dense"
    
              style={{ marginTop: 10 ,marginBottom: 10 }}
    
              onChange={(e) => handleChange(e)}
              value={value.effectiveflg}
            >
              <MenuItem value={value.effectiveflg}>
                <em>申請状況</em>
              </MenuItem>
              <MenuItem value={'0'}>0:入力中</MenuItem>
              <MenuItem value={'1'}>1:申請済</MenuItem>
              <MenuItem value={'2'}>2:登録完了</MenuItem>
              <MenuItem value={'9'}>9:対応完了</MenuItem>
            </Select>
            <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.demandId}　　{dataList2.demandCd}：{dataList2.effectiveflg}</Typography>
           </td></tr>
    
    </table><table>
    
           <tr><th>企業、事業場</th><td>
             <TextField id='egsMCompanyId' label='事業場ID' variant="outlined" type='text' name='egsMCompanyId' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.egsMCompanyId}/>
             <TextField id='companyCd' label='企業CD' variant="outlined" type='text' name='companyCd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.companyCd}/>
             <TextField id='egsMTraderId' label='事業場ID' variant="outlined" type='text' name='egsMTraderId' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.egsMTraderId}/>
             <TextField id='traderCd' label='事業場CD' variant="outlined" type='text' name='traderCd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.traderCd}/>
             <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.egsMCompanyId}　　{dataList2.companyCd}：{dataList2.egsMCompanyId}　　{dataList2.egsMTraderId}：{dataList2.traderCd}</Typography>
           </td></tr>
    
           <tr><th>加入者情報</th><td>
             <TextField id='jwnetNo' label='加入者番号' variant="outlined" type='text' name='jwnetNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.jwnetNo}/>
             <TextField id='jwnetEdipass' label='EDI利用確認キー' variant="outlined" type='text' name='jwnetEdipass' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.jwnetEdipass}/>
             <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.jwnetNo}　　{dataList2.jwnetEdipass}　　{dataList2.traderKbn}　{dataList2.chargeDivision}</Typography>
           </td></tr>
    
           <tr><th>SDGs推進システム</th><td>
             <TextField id='rawpasswd' label='パスワード' variant="outlined" type='text' name='rawpasswd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.rawpasswd}/>
             <TextField id='exhaustPasswd' label='承認コード' variant="outlined" type='text' name='exhaustPasswd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.exhaustPasswd}/>
             <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.rawpasswd}　　{dataList2.exhaustPasswd}　　{dataList2.traderKbn}　{dataList2.chargeDivision}</Typography>
           </td></tr>
    
    </table><table>
    
           <tr><th>業種</th><td>
                <Box> {/*</Box><Box bgcolor="primary.gray" color="primary.black" p={1}> */}
                  <Select id='businessCategory' label='業種' variant="outlined" type='text' name='businessCategory' margin="dense"
    //              style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10 }}
                    style={{ marginTop: 2 ,marginBottom: 2 }} 
                    halfWidth 
                    onChange={(e) => handleChange(e)} value={value.businessCategory}
                   >
                    {businessCategorydataList.map((businessCategorydataList) => (
                      <MenuItem key={businessCategorydataList.categoryCd} value={businessCategorydataList.categoryCd} > {businessCategorydataList.categoryCd}:{businessCategorydataList.categoryName} </MenuItem>
                    ))}
                  </Select>
    {/*}            </Box>
                <Box> {/*</Box><Box bgcolor="primary.gray" color="primary.black" p={1}> */}
                  <Select id='businessType' label='業種' variant="outlined" type='text' name='businessType' margin="dense"
    //              style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10 }}
                    style={{ marginTop: 2 ,marginBottom: 2 }} 
                    halfWidth 
                    onChange={(e) => handleChange(e)} value={value.businessType}
                   >
                    {businessTypedataList.map((businessTypedataList) => (
                      <MenuItem key={businessTypedataList.businessTypeCd} value={businessTypedataList.businessTypeCd} > {businessTypedataList.businessTypeCd}:{businessTypedataList.businessTypeName} </MenuItem>
                    ))}
                  </Select>
                  <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.businessCategory}　{dataList2.businessType}</Typography>
                </Box>
           </td></tr>    
    
           <tr><th>法人区分</th><td>
              <Select
                    id='companyKind'
                    label='法人区分'
                    variant="outlined"
                    type='text'
                    name='companyKind'
                    margin="dense"
    
                    style={{ marginTop: 10 ,marginBottom: 10 }}
    
                    onChange={(e) => handleChange(e)}
                    value={value.companyKind}
                  >
                    <MenuItem value={value.companyKind}>
                      <em>法人区分</em>
                    </MenuItem>
                    <MenuItem value={'00'}>00:個人事業</MenuItem>
                    <MenuItem value={'01'}>01:株式会社</MenuItem>
                    <MenuItem value={'02'}>02:有限会社</MenuItem>
                    <MenuItem value={'03'}>03:合名会社</MenuItem>
                    <MenuItem value={'04'}>04:合資会社</MenuItem>
                    <MenuItem value={'05'}>05:医療法人</MenuItem>
                    <MenuItem value={'06'}>06:財団法人</MenuItem>
                    <MenuItem value={'07'}>07:社団法人</MenuItem>
                    <MenuItem value={'08'}>08:宗教法人</MenuItem>
                    <MenuItem value={'09'}>09:学校法人</MenuItem>
                    <MenuItem value={'10'}>10:社会福祉法人</MenuItem>
                    <MenuItem value={'11'}>11:相互会社</MenuItem>
                    <MenuItem value={'12'}>12:行政書士法人</MenuItem>
                    <MenuItem value={'13'}>13:司法書士法人</MenuItem>
                    <MenuItem value={'14'}>14:税理士法人</MenuItem>
                    <MenuItem value={'15'}>15:国立大学法人</MenuItem>
                    <MenuItem value={'16'}>16:特定非営利活動法人</MenuItem>
                    <MenuItem value={'17'}>17:更生保護法人</MenuItem>
                    <MenuItem value={'18'}>18:独立行政法人</MenuItem>
                    <MenuItem value={'19'}>19:弁護士法人</MenuItem>
                    <MenuItem value={'20'}>20:有限責任中間法人</MenuItem>
                    <MenuItem value={'21'}>21:無限責任中間法人</MenuItem>
                    <MenuItem value={'22'}>22:医療法人社団</MenuItem>
                    <MenuItem value={'23'}>23:公共機関</MenuItem>
                    <MenuItem value={'24'}>24:公益社団法人</MenuItem>
                    <MenuItem value={'25'}>25:公益財団法人</MenuItem>
                    <MenuItem value={'26'}>26:一般社団法人</MenuItem>
                    <MenuItem value={'27'}>27:一般財団法人</MenuItem>
                    <MenuItem value={'28'}>28:医療法人財団</MenuItem>
                    <MenuItem value={'29'}>29:協同組合</MenuItem>
                    <MenuItem value={'99'}>99:その他の法人</MenuItem>
                  </Select>
                  <Select
                    id='companyKbn'
                    label='前後'
                    variant="outlined"
                    type='text'
                    name='companyKbn'
                    margin="dense"
    
                    style={{ marginTop: 10 ,marginBottom: 10 }}
    
                    onChange={(e) => handleChange(e)}
                    value={value.companyKbn}
                  >
                    <MenuItem value={value.companyKbn}>
                      <em>前後</em>
                    </MenuItem>
                    <MenuItem value={' '}> :指定しない</MenuItem>
                    <MenuItem value={'1'}>1:前</MenuItem>
                    <MenuItem value={'2'}>2:後</MenuItem>
                  </Select>
                  <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.companyKind}　{dataList2.companyKbn}</Typography>
              </td></tr>
    
              <tr><th>グループ</th><td>
                <Box> {/*</Box><Box bgcolor="primary.gray" color="primary.black" p={1}> */}
                <Select id='groupId1' label='グループ１' variant="outlined" type='text' name='groupId1' margin="dense"
                    style={{ marginTop: 2 ,marginBottom: 2 }} halfWidth onChange={(e) => handleChange(e)} value={value.groupId1}
                   >
                    {groupdataList.map((groupdataList) => (
                      <MenuItem key={groupdataList.id} value={groupdataList.id} > {groupdataList.groupCd}:{groupdataList.groupName} </MenuItem>
                    ))}
                  </Select>
                  <Select id='groupId2' label='グループ２' variant="outlined" type='text' name='groupId2' margin="dense"
                    style={{ marginTop: 2 ,marginBottom: 2 }} halfWidth onChange={(e) => handleChange(e)} value={value.groupId2}
                   >
                    {groupdataList.map((groupdataList) => (
                      <MenuItem key={groupdataList.id} value={groupdataList.id} > {groupdataList.groupCd}:{groupdataList.groupName} </MenuItem>
                    ))}
                  </Select>
                  <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.groupId1}（{dataList2.groupCd1}）：{dataList2.groupId2}（{dataList2.groupCd2}）</Typography>
                </Box>
           </td></tr>    
    
    </table><table>
    
           <tr><th>企業名</th><td>
              <TextField id='companyKana' label='企業かな名' variant="outlined" type='text' name='companyKana' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.companyKana}/>
              <TextField id='companyName' label='企業名' variant="outlined" type='text' name='companyName' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.companyName}/>
              <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.companyNameLink}　（{dataList2.companyKanaLink}）</Typography>
              </td></tr>
            <tr><th>代表者かな名</th><td>
              <TextField id='daihyo' label='代表者役職' variant="outlined" type='text' name='daihyo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.daihyo}/>
              <TextField id='daihyoKana1' label='代表者かな姓' variant="outlined" type='text' name='daihyoKana1' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.daihyoKana1}/>
              <TextField id='daihyoKana2' label='代表者かな名' variant="outlined" type='text' name='daihyoKana2' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.daihyoKana2}/>
              <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.daihyoKana1Link}　{dataList2.daihyoKana2Link}</Typography>
              </td></tr>
            <tr><th>代表者名</th><td>
              <TextField id='daihyo' label='' variant="outlined" type='text' name='' halfWidth margin="dense" />
              <TextField id='daihyoName1' label='代表者姓' variant="outlined" type='text' name='daihyoName1' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.daihyoName1}/>
              <TextField id='daihyoName2' label='代表者名' variant="outlined" type='text' name='daihyoName2' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.daihyoName2}/>
              <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.daihyoLink}　{dataList2.daihyoName1Link}　{dataList2.daihyoName2Link}</Typography>
              </td></tr>
    
    </table><table>
    
            <tr><th>事業場名</th><td>
              <TextField id='traderKana' label='事業場かな' variant="outlined" type='text' name='traderKana' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.traderKana}/>
              <TextField id='traderName' label='事業場名' variant="outlined" type='text' name='traderName' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.traderName}/>
              <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.traderNameLink} （{dataList2.traderKanaLink}）</Typography>
    
    </td></tr>
    
            <tr><th>住所</th><td>
              <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.zipLink}　{dataList2.prefLink}　{dataList2.cityLink}　{dataList2.townLink}　{dataList2.addressLink}　{dataList2.buildingLink}</Typography>
                <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='zip' label='郵便番号' variant="outlined" type='text' name='zip' halfWidth margin="dense" 
    //          onChange={(e) => handleChange(e)}
              onChange={(e) => companyZipChange(e)} value={value.zip}
              />
              <TextField id='address' label='住所' variant="outlined" type='text' name='address' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.address}/>
              <TextField id='building' label='ビル名' variant="outlined" type='text' name='building' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.building}/>
                </Box>
              <Typography variant="h7" style={{ marginTop: 10 }}>変更後　{value.zip}　{value.pref}　{value.city}　{value.town}</Typography>
              </td></tr>
            <tr><th>電話番号</th><td>
              <TextField id='phone' label='電話番号' variant="outlined" type='text' name='phone' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.phone}/>
              <TextField id='fax' label='FAX番号' variant="outlined" type='text' name='fax' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.fax}/>
              <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.phoneLink}　　FAX番号{dataList2.faxLink}</Typography>
              </td></tr>
    
    </table><table>
    
            <tr><th>メールアドレス *</th><td>
              <TextField id='email1' label='メールアドレス' variant="outlined" type='text' name='email1' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              error={errorEmail1}
              value={value.email1}
              inputProps={{ maxLength: 50, pattern: "^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$" }}  // maxLength: 3, pattern: "0-9"
            />
              <TextField id='email2' label='メールアドレス2' variant="outlined" type='text' name='email2' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
    //          error={errorEmail2}
              value={value.email2}
              inputProps={{ maxLength: 50, pattern: "^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$" }}  // maxLength: 3, pattern: "0-9"
            />
              <TextField id='email3' label='メールアドレス2' variant="outlined" type='text' name='email3' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
    //          error={errorEmail3}
              value={value.email3}
              inputProps={{ maxLength: 50, pattern: "^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$" }}  // maxLength: 3, pattern: "0-9"
            />
              <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.email1Link}　{dataList2.email2Link}　{dataList2.email3Link}</Typography>
            </td></tr>
           </table>
          </form>
          <div style={{ textAlign: 'right' }}>
            <Button
              type='submit'
              variant='contained'
              color='secondary'
    //        onClick={(e) => handleUpdate2(e)}
              onClick={(e) => {
              handleUpdate2(e);
              }}
              style={{ marginRight: 10 }}
            >
              各マスタ更新（企業名反映のみ）
            </Button>
    {/*}     
            <Button
              type='submit'
              variant='contained'
              color='secondary'
    //        onClick={(e) => handleUpdate3(e)}
              onClick={(e) => {
              handleUpdate3(e);
              }}
              style={{ marginRight: 10 }}
            >
              連携マスタ登録
            </Button>
    */}     
            <Button
              type='submit'
              variant='contained'
              color='secondary'
    //        onClick={(e) => handleUpdate1(e)}
              onClick={(e) => {
              handleUpdate1(e);
              }}
              style={{ marginRight: 100 }}
            >
              各マスタ更新（企業住所反映あり）
            </Button>
            <Button
              type='submit'
              variant='contained'
              color='primary'
    //          onClick={(e) => handleSubmit(e)}
              onClick={(e) => {
              handleSubmit(e);
    //            if (checkValidation()) {
    //                handleSubmit(e);
    //               }
                }}
              style={{ marginRight: 10 }}
            >
              更新
            </Button>
    {/*}
            <Button variant='contained' onClick={e => {
              e.preventDefault();
              handleBack();
            }}>
              戻る
            </Button>
    */}
          </div>
        </>
      );
        }else{  
      return (
        <>
    {/*}      <SpaceRow height={20} />  */}
{/*}        <Typography variant="h5">解約申込</Typography> */}
          <form className={classes.root} noValidate autoComplete='off'>
           <table>

           <tr><th>お申込日、業者・料金区分</th><td>
             <Box> 
             <Typography variant="h7" style={{ marginTop: 10 }}>お申込日：{dataList2.entryDate}　変更予定日：{dataList2.henkouDate}　　{dataList2.traderKbn}　{dataList2.chargeDivision}</Typography>
            
              <TextField id='entryDate' label='お申込日' variant="outlined" type='date' name='entryDate' halfWidth margin="dense" 
//                onChange={(e) => handleChange(e)} 
                value={value.entryDate}
              />
              <TextField id='henkouDate' label='変更予定日' variant="outlined" type='date' name='henkouDate' halfWidth margin="dense" 
//                onChange={(e) => handleChange(e)} 
                value={value.henkouDate}
              />
              <Select
                    id='traderKbn'
                    label='業者区分'
                    variant="outlined"
                    type='text'
                    name='traderKbn'
                    margin="dense"
    
                    style={{ marginTop: 10 ,marginBottom: 10 }}
    
//                    onChange={(e) => handleChange(e)}
                    value={value.traderKbn}
                  >
                    <MenuItem value={value.traderKbn}>
                      <em>業者区分</em>
                    </MenuItem>
                    <MenuItem value={' '}> :指定しない</MenuItem>
                    <MenuItem value={'1000000000'}>1:排出業者</MenuItem>
                    <MenuItem value={'0001000000'}>2:中間処理</MenuItem>
                    <MenuItem value={'0000100000'}>3:最終処分</MenuItem>
                  </Select>
                  <Select
                    id='chargeDivision'
                    label='料金区分'
                    variant="outlined"
                    type='text'
                    name='chargeDivision'
                    margin="dense"
    
                    style={{ marginTop: 10 ,marginBottom: 10 }}
    
//                    onChange={(e) => handleChange(e)}
                    value={value.chargeDivision}
                  >
                    <MenuItem value={value.chargeDivision}>
                      <em>料金区分</em>
                    </MenuItem>
                    <MenuItem value={' '}> :指定しない</MenuItem>
                    <MenuItem value={'1'}>1:料金区分A</MenuItem>
                    <MenuItem value={'2'}>2:料金区分B</MenuItem>
                    <MenuItem value={'3'}>3:料金区分C</MenuItem>
                  </Select>
                </Box>
           </td></tr>     

           <tr><th>請求先、企業、事業場</th><td>
            <TextField id='demandId' label='請求先ID' variant="outlined" type='text' name='demandId' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.demandId}/>
            <TextField id='demandCd' label='請求先CD' variant="outlined" type='text' name='demandCd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.demandCd}/>
            <Select
              id='effectiveflg'
              label='申請状況'
              variant="outlined"
              type='text'
              name='effectiveflg'
              margin="dense"
    
              style={{ marginTop: 10 ,marginBottom: 10 }}
    
              onChange={(e) => handleChange(e)}
              value={value.effectiveflg}
            >
              <MenuItem value={value.effectiveflg}>
                <em>申請状況</em>
              </MenuItem>
              <MenuItem value={'0'}>0:入力中</MenuItem>
              <MenuItem value={'1'}>1:申請済</MenuItem>
              <MenuItem value={'2'}>2:登録完了</MenuItem>
              <MenuItem value={'9'}>9:対応完了</MenuItem>
            </Select>
            <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.demandId}　　{dataList2.demandCd}：{dataList2.effectiveflg}</Typography>
           </td></tr>
    
    </table><table>
    
           <tr><th>企業、事業場</th><td>
{/*}            
             <TextField id='egsMCompanyId' label='事業場ID' variant="outlined" type='text' name='egsMCompanyId' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.egsMCompanyId}/>
             <TextField id='companyCd' label='企業CD' variant="outlined" type='text' name='companyCd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.companyCd}/>
             <TextField id='egsMTraderId' label='事業場ID' variant="outlined" type='text' name='egsMTraderId' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.egsMTraderId}/>
             <TextField id='traderCd' label='事業場CD' variant="outlined" type='text' name='traderCd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.traderCd}/>
*/}             
             <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.egsMCompanyId}　　{dataList2.companyCd}：{dataList2.egsMCompanyId}　　{dataList2.egsMTraderId}：{dataList2.traderCd}</Typography>
           </td></tr>
    
           <tr><th>加入者情報</th><td>
{/*}            
             <TextField id='jwnetNo' label='加入者番号' variant="outlined" type='text' name='jwnetNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.jwnetNo}/>
             <TextField id='jwnetEdipass' label='EDI利用確認キー' variant="outlined" type='text' name='jwnetEdipass' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.jwnetEdipass}/>
*/}             
             <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.jwnetNo}　　{dataList2.jwnetEdipass}　　{dataList2.traderKbn}　{dataList2.chargeDivision}</Typography>
           </td></tr>
    
           <tr><th>SDGs推進システム</th><td>
{/*}            
             <TextField id='rawpasswd' label='パスワード' variant="outlined" type='text' name='rawpasswd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.rawpasswd}/>
             <TextField id='exhaustPasswd' label='承認コード' variant="outlined" type='text' name='exhaustPasswd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.exhaustPasswd}/>
*/}             
             <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.rawpasswd}　　{dataList2.exhaustPasswd}　　{dataList2.traderKbn}　{dataList2.chargeDivision}</Typography>
           </td></tr>
    
    </table><table>
    
    
           <tr><th>業種</th><td>
                <Box> {/*</Box><Box bgcolor="primary.gray" color="primary.black" p={1}> */}
{/*}                
                  <Select id='businessCategory' label='業種' variant="outlined" type='text' name='businessCategory' margin="dense"
    //              style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10 }}
                    style={{ marginTop: 2 ,marginBottom: 2 }} 
                    halfWidth 
                    onChange={(e) => handleChange(e)} value={value.businessCategory}
                   >
                    {businessCategorydataList.map((businessCategorydataList) => (
                      <MenuItem key={businessCategorydataList.categoryCd} value={businessCategorydataList.categoryCd} > {businessCategorydataList.categoryCd}:{businessCategorydataList.categoryName} </MenuItem>
                    ))}
                  </Select>
                  <Select id='businessType' label='業種' variant="outlined" type='text' name='businessType' margin="dense"
    //              style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10 }}
                    style={{ marginTop: 2 ,marginBottom: 2 }} 
                    halfWidth 
                    onChange={(e) => handleChange(e)} value={value.businessType}
                   >
                    {businessTypedataList.map((businessTypedataList) => (
                      <MenuItem key={businessTypedataList.businessTypeCd} value={businessTypedataList.businessTypeCd} > {businessTypedataList.businessTypeCd}:{businessTypedataList.businessTypeName} </MenuItem>
                    ))}
                  </Select>
*/}                  
                  <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.businessCategory}　{dataList2.businessType}</Typography>
                </Box>
           </td></tr>    
    
           <tr><th>法人区分</th><td>
{/*}            
              <Select
                    id='companyKind'
                    label='法人区分'
                    variant="outlined"
                    type='text'
                    name='companyKind'
                    margin="dense"
    
                    style={{ marginTop: 10 ,marginBottom: 10 }}
    
                    onChange={(e) => handleChange(e)}
                    value={value.companyKind}
                  >
                    <MenuItem value={value.companyKind}>
                      <em>法人区分</em>
                    </MenuItem>
                    <MenuItem value={'00'}>00:個人事業</MenuItem>
                    <MenuItem value={'01'}>01:株式会社</MenuItem>
                    <MenuItem value={'02'}>02:有限会社</MenuItem>
                    <MenuItem value={'03'}>03:合名会社</MenuItem>
                    <MenuItem value={'04'}>04:合資会社</MenuItem>
                    <MenuItem value={'05'}>05:医療法人</MenuItem>
                    <MenuItem value={'06'}>06:財団法人</MenuItem>
                    <MenuItem value={'07'}>07:社団法人</MenuItem>
                    <MenuItem value={'08'}>08:宗教法人</MenuItem>
                    <MenuItem value={'09'}>09:学校法人</MenuItem>
                    <MenuItem value={'10'}>10:社会福祉法人</MenuItem>
                    <MenuItem value={'11'}>11:相互会社</MenuItem>
                    <MenuItem value={'12'}>12:行政書士法人</MenuItem>
                    <MenuItem value={'13'}>13:司法書士法人</MenuItem>
                    <MenuItem value={'14'}>14:税理士法人</MenuItem>
                    <MenuItem value={'15'}>15:国立大学法人</MenuItem>
                    <MenuItem value={'16'}>16:特定非営利活動法人</MenuItem>
                    <MenuItem value={'17'}>17:更生保護法人</MenuItem>
                    <MenuItem value={'18'}>18:独立行政法人</MenuItem>
                    <MenuItem value={'19'}>19:弁護士法人</MenuItem>
                    <MenuItem value={'20'}>20:有限責任中間法人</MenuItem>
                    <MenuItem value={'21'}>21:無限責任中間法人</MenuItem>
                    <MenuItem value={'22'}>22:医療法人社団</MenuItem>
                    <MenuItem value={'23'}>23:公共機関</MenuItem>
                    <MenuItem value={'24'}>24:公益社団法人</MenuItem>
                    <MenuItem value={'25'}>25:公益財団法人</MenuItem>
                    <MenuItem value={'26'}>26:一般社団法人</MenuItem>
                    <MenuItem value={'27'}>27:一般財団法人</MenuItem>
                    <MenuItem value={'28'}>28:医療法人財団</MenuItem>
                    <MenuItem value={'29'}>29:協同組合</MenuItem>
                    <MenuItem value={'99'}>99:その他の法人</MenuItem>
                  </Select>
                  <Select
                    id='companyKbn'
                    label='前後'
                    variant="outlined"
                    type='text'
                    name='companyKbn'
                    margin="dense"
    
                    style={{ marginTop: 10 ,marginBottom: 10 }}
    
                    onChange={(e) => handleChange(e)}
                    value={value.companyKbn}
                  >
                    <MenuItem value={value.companyKbn}>
                      <em>前後</em>
                    </MenuItem>
                    <MenuItem value={' '}> :指定しない</MenuItem>
                    <MenuItem value={'1'}>1:前</MenuItem>
                    <MenuItem value={'2'}>2:後</MenuItem>
                  </Select>
*/}                  
                  <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.companyKind}　{dataList2.companyKbn}</Typography>
              </td></tr>
    
              <tr><th>グループ</th><td>
                <Box> {/*</Box><Box bgcolor="primary.gray" color="primary.black" p={1}> */}
{/*}                
                <Select id='groupId1' label='グループ１' variant="outlined" type='text' name='groupId1' margin="dense"
                    style={{ marginTop: 2 ,marginBottom: 2 }} halfWidth onChange={(e) => handleChange(e)} value={value.groupId1}
                   >
                    {groupdataList.map((groupdataList) => (
                      <MenuItem key={groupdataList.id} value={groupdataList.id} > {groupdataList.groupCd}:{groupdataList.groupName} </MenuItem>
                    ))}
                  </Select>
                  <Select id='groupId2' label='グループ２' variant="outlined" type='text' name='groupId2' margin="dense"
                    style={{ marginTop: 2 ,marginBottom: 2 }} halfWidth onChange={(e) => handleChange(e)} value={value.groupId2}
                   >
                    {groupdataList.map((groupdataList) => (
                      <MenuItem key={groupdataList.id} value={groupdataList.id} > {groupdataList.groupCd}:{groupdataList.groupName} </MenuItem>
                    ))}
                  </Select>
*/}                  
                  <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.groupId1}　{dataList2.groupId2}</Typography>
                </Box>
           </td></tr>    
    
    </table><table>
    
           <tr><th>企業名</th><td>
{/*}            
              <TextField id='companyKana' label='企業かな名' variant="outlined" type='text' name='companyKana' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.companyKana}/>
              <TextField id='companyName' label='企業名' variant="outlined" type='text' name='companyName' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.companyName}/>
*/}              
              <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.companyName}　（{dataList2.companyKana}）</Typography>
              </td></tr>
            <tr><th>代表者かな名</th><td>
{/*}            
              <TextField id='daihyo' label='代表者役職' variant="outlined" type='text' name='daihyo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.daihyo}/>
              <TextField id='daihyoKana1' label='代表者かな姓' variant="outlined" type='text' name='daihyoKana1' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.daihyoKana1}/>
              <TextField id='daihyoKana2' label='代表者かな名' variant="outlined" type='text' name='daihyoKana2' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.daihyoKana2}/>
*/}              
              <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.daihyoKana1}　{dataList2.daihyoKana2}</Typography>
              </td></tr>
            <tr><th>代表者名</th><td>
{/*}            
              <TextField id='daihyo' label='' variant="outlined" type='text' name='' halfWidth margin="dense" />
              <TextField id='daihyoName1' label='代表者姓' variant="outlined" type='text' name='daihyoName1' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.daihyoName1}/>
              <TextField id='daihyoName2' label='代表者名' variant="outlined" type='text' name='daihyoName2' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.daihyoName2}/>
*/}              
              <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.daihyo}　{dataList2.daihyoName1}　{dataList2.daihyoName2}</Typography>
              </td></tr>
    
    </table><table>
    
            <tr><th>事業場名</th><td>
{/*}            
              <TextField id='traderKana' label='事業場かな' variant="outlined" type='text' name='traderKana' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.traderKana}/>
              <TextField id='traderName' label='事業場名' variant="outlined" type='text' name='traderName' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.traderName}/>
*/}              
              <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.traderName} （{dataList2.traderKana}）</Typography>
    
    </td></tr>
    
            <tr><th>住所</th><td>
              <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.zip}　{dataList2.pref}　{dataList2.city}　{dataList2.town}　{dataList2.address}　{dataList2.building}</Typography>
{/*}            
                <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='zip' label='郵便番号' variant="outlined" type='text' name='zip' halfWidth margin="dense" 
    //          onChange={(e) => handleChange(e)}
              onChange={(e) => companyZipChange(e)} value={value.zip}
              />
              <TextField id='address' label='住所' variant="outlined" type='text' name='address' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.address}/>
              <TextField id='building' label='ビル名' variant="outlined" type='text' name='building' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.building}/>
                </Box>
              <Typography variant="h7" style={{ marginTop: 10 }}>変更後　{value.zip}　{value.pref}　{value.city}　{value.town}</Typography>
*/}              
              </td></tr>
            <tr><th>電話番号</th><td>
{/*}            
              <TextField id='phone' label='電話番号' variant="outlined" type='text' name='phone' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.phone}/>
              <TextField id='fax' label='FAX番号' variant="outlined" type='text' name='fax' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.fax}/>
*/}              
              <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.phone}　　FAX番号{dataList2.fax}</Typography>
              </td></tr>
    
    </table><table>
    
            <tr><th>メールアドレス *</th><td>
{/*}            
              <TextField id='email1' label='メールアドレス' variant="outlined" type='text' name='email1' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              error={errorEmail1}
              value={value.email1}
              inputProps={{ maxLength: 50, pattern: "^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$" }}  // maxLength: 3, pattern: "0-9"
            />
              <TextField id='email2' label='メールアドレス2' variant="outlined" type='text' name='email2' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
    //          error={errorEmail2}
              value={value.email2}
              inputProps={{ maxLength: 50, pattern: "^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$" }}  // maxLength: 3, pattern: "0-9"
            />
              <TextField id='email3' label='メールアドレス2' variant="outlined" type='text' name='email3' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
    //          error={errorEmail3}
              value={value.email3}
              inputProps={{ maxLength: 50, pattern: "^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$" }}  // maxLength: 3, pattern: "0-9"
            />
*/}              
              <Typography variant="h7" style={{ marginTop: 10 }}>変更前　{dataList2.email1}　{dataList2.email2}　{dataList2.email3}</Typography>
            </td></tr>
           </table>
          </form>
          <div style={{ textAlign: 'right' }}>
            <Button
              type='submit'
              variant='contained'
              color='secondary'
    //        onClick={(e) => handleDelete2(e)}
              onClick={(e) => {
              handleDelete2(e);
              }}
              style={{ marginRight: 10 }}
            >
              ユーザマスタ解約
            </Button>
    {/*}     
            <Button
              type='submit'
              variant='contained'
              color='secondary'
    //        onClick={(e) => handleDelete3(e)}
              onClick={(e) => {
              handleDelete3(e);
              }}
              style={{ marginRight: 10 }}
            >
              連携マスタ登録
            </Button>
    */}     
            <Button
              type='submit'
              variant='contained'
              color='secondary'
    //        onClick={(e) => handleDelete1(e)}
              onClick={(e) => {
              handleDelete1(e);
              }}
              style={{ marginRight: 100 }}
            >
              各マスタ解約
            </Button>
            <Button
              type='submit'
              variant='contained'
              color='primary'
    //          onClick={(e) => handleSubmit(e)}
              onClick={(e) => {
              handleSubmit(e);
    //            if (checkValidation()) {
    //                handleSubmit(e);
    //               }
                }}
              style={{ marginRight: 10 }}
            >
              更新
            </Button>
    {/*}
            <Button variant='contained' onClick={e => {
              e.preventDefault();
              handleBack();
            }}>
              戻る
            </Button>
    */}
          </div>
        </>
      );
      }
  
};
export default EgsMEntryAdminForm;
